import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { BackendCallService } from '../../services/backend-call.service';
type AOA = any[][];

@Component({
  selector: 'app-record-management',
  templateUrl: './record-management.component.html',
  styleUrls: ['./record-management.component.scss']
})
export class RecordManagementComponent implements OnInit {
  @ViewChild('history', { static: true }) activities: { show: () => void; };
  @ViewChild('verify', { static: false }) verifyy;
  @ViewChild('reject', { static: false }) rejectt;

  modifyForm: FormGroup;

  uploadFile: FormGroup;
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  data: AOA;
  buttonDisable: boolean = true;
  btnLoader: boolean = false
  chooseFile: string = 'Choose File';
  submitted: boolean = false;
  masterLeadcount: any;
  isVerified = false;
  isRejected = false;
  excelFileName: any;
  submitStatus: boolean;
  policyRecivedArray: any[];
  quoteUpload: string;
  masterleadtotalPremium: any;
  fromDate: any;
  toDate: any;
  pretotal = 0;
  masleaduplArray = [];
  username: any;
  masterLeadUploadHistory: any;
  duplicateCount: any;
  duplicateData: any;
  registerCarNo: any;
  duplicateMasterLead: any;
  currentmasterFileleads: any;
  currentmasterFileleadspremium: any;
  recordCreatedOn: any;
  histableid: any;
  dupId: any;
  dupYear: any;
  dupRegno: any;
  uploadedfileid: any;
  duplicateMasterLeadcount: any;
  totalfilshistorycount: any;
  leadid: any;
  isChecked: boolean;
  public constructor(private formBuilder: FormBuilder, private backendCall: BackendCallService, private router: Router, private changeDetector: ChangeDetectorRef) {
    this.username = localStorage.getItem('name');

    this.modifyForm = this.formBuilder.group({
      modifyArray: this.formBuilder.array([]),
    })
  }

  ngOnInit() {
    // this.getCountRoleWise();
    // this.masterLeadHistory();
    this.uploadFile = this.formBuilder.group({
      excelFile: ['', Validators.required]
    });
  }

  onFileSelected(event) {
    if (event.target.files.length > 0) {
      this.excelFileName = event.target.files[0].name;
      //  console.log(this.excelFileName);
      console.log(event.target.files[0].name);
      console.log("ghjrtydfg");


    }
  }

  public getCountRoleWise(): any {
    this.backendCall.getmasterLeadCount().subscribe(Response => {
      if (Response) {
        // this.masterLeadcount = Response.data[0].getmasterLeadCount[0].leadcount;
        // this.masterleadtotalPremium = Response.data[0].getmasterLeadCount[0].premiumcount;
        this.duplicateCount = Response.data[0].getduplicatecount.length;
        this.duplicateData = Response.data[0].getduplicatecount;
      }
    },
      error => {
        alert(error.error.error_msg);
      });
  }

  get f() { return this.uploadFile.controls; }



  onSubmit(evt: any): void {
    // Process checkout data here
    /* wire up file reader */
    this.btnLoader = true;
    this.chooseFile = evt.target.files[0].name;
    console.log(this.chooseFile);
    //console.log(evt);
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {
        type: 'binary', cellDates: true,
        cellNF: false,
        cellText: false
      });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, {
        header: 1, dateNF: "yyyy/mm/dd"
      }));
      console.log(this.data);
      for (let i = 1; i < this.data.length; i++) {
        this.pretotal = this.pretotal + parseInt(this.data[i][58]);
      }
      console.log(this.pretotal)
      // this.masleaduplArray.push({
      //   filename: this.chooseFile,
      //   username: this.username,
      //   noofcase: this.data.length - 1,
      //   premium: this.pretotal,
      //   delete: "No"
      // })
      this.masleaduplArray.push([
        this.chooseFile,
        this.username,
        this.data.length - 1,
        this.pretotal,
        "No"
      ])
      console.log(this.username);
      console.log(this.masleaduplArray);
      this.btnLoader = false;
      this.buttonDisable = false;
    };
    reader.readAsBinaryString(target.files[0]);
  }

  formValidation(formElement) {
    let _element = this.uploadFile.controls[formElement];

    //console.log(_element)
    if (_element.invalid && this.submitted) {
      return 'custom-file-input form-control is-invalid';

    } else if (_element.touched && _element.valid) {
      return 'form-control is-valid';

    } else {
      return 'custom-file-input form-control';
    }
  }

  uplaodData(): void {
    //console.log("upload this data", this.data)
    this.buttonDisable = true;
    this.submitted = true;
    this.btnLoader = true;
    if (!this.uploadFile.invalid) {
      // console.log(this.data);
      this.backendCall.masluploadFile({ uploadContent: this.data, historyContent: this.masleaduplArray }).subscribe(Response => {
        //console.log(Response, 'return data')
        this.btnLoader = false;
        if (Response) {
          alert("File uploaded successfully")
          window.location.reload();
          this.router.navigate(['/record-management']);
        }
      },
        error => {
          //console.log(error);
          alert(error.error.error_msg);
          window.location.reload();
          this.router.navigate(['/record-management']);
        });
    }
  }

  downloadMotorFormat() {
    const arr = [
      [
        {
          "Inward Date": "",
          "Inward Branch": "",
          "CUSTOMER NAME": "",
          "MOBILE NO": "",
          "EMAIL ID": "",
          "ADDRESS": "",
          "BQP": "",
          "REFFERED BY": "",
          "INSURER NAME": "",
          "BRANCH": "",
          "CODE": "",
          "DEPARTMENT": "",
          "POLICY ISSUANCE": "",
          "BUSINESS TYPE": "",
          "POLICY TYPE": "",
          "PRODUCT": "",
          "VEHICLE TYPE": "",
          "POLICY NUMBER": "",
          "ENDROSEMENT NUMBER": "",
          "ISSUED DATE": "",
          "RISK START DATE": "",
          "RISK END DATE": "",
          "GVW": "",
          "REGISTARION NO": "",
          "ENGINE NO": "",
          "CHASSIS NO": "",
          "MAKE": "",
          "MODEL": "",
          "FULE TYPE": "",
          "MFG YEAR": "",
          "SEATING CAPACITY": "",
          "ZERO DEP": "",
          "PA COVER": "",
          "NCB": "",
          "SUM INSURED": "",
          "OD PREMIUM": "",
          "TP PREMIUM": "",
          "NET PREMIUM": "",
          "GST": "",
          "Total Premium": "",
          "Mode of Payment": "",
          "Payment details": "",
          "staff": ""
        }
      ],
    ];
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(arr[0]);
    XLSX.utils.book_append_sheet(wb, ws, 'Motor');
    XLSX.writeFile(wb, "VIAANSH_MOTOR_FORMAT.xlsx");
  }

  downloadNonMotorFormat() {
    const array = [
      [
        {
          "INWARD DATE": "",
          "INWARD BRANCH": "",
          "CUSTMOR NAME": "",
          "EMIAL ID": "",
          "ADDRESS": "",
          "BQP": "",
          "REFFERED BY": "",
          "INSURER NAME": "",
          "BRANCH": "",
          "CODE": "",
          "DEPARTMENT": "",
          "PRODUCT": "",
          "POLICY ISSUANCE": "",
          "BUSINESS TYPE": "",
          "POLICY NUMBER": "",
          "ENDROSEMENT NUMBER": "",
          "ENDROSEMENT TYPE": "",
          "ISSUED DATE": "",
          "RISK START DATE": "",
          "RISK END DATE": "",
          "SUM INSURED": "",
          "OD PREMIUM": "",
          "TP PREMIUM": "",
          "TERRORISM PREMIUM": "",
          "NET PREMIUM": "",
          "GST": "",
          "TOTAL PREMIUM": "",
          "Mode of Payment": "",
          "Payment details": "",
          "Co Sharing": "",
          "Leader Name": "",
          "Leader Share%": "",
          "Follower Name 1": "",
          "Follower Share 1 %": "",
          "Follower Name 2": "",
          "Follower Share 2 %": "",
          "Follower Name 3": "",
          "Follower Share 3 %": "",
          "Follower Name 4": "",
          "Follower Share 4 %": "",
          "staff": ""
        }
      ],
    ];
    let wb1 = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(array[0]);
    XLSX.utils.book_append_sheet(wb1, ws1, 'NON_MOTOR');
    XLSX.writeFile(wb1, "VIAANSH_NONMOTOR_FORMAT.xlsx");
    // alert("Working.............")
    // console.log("bvbvbvbvbvvb");
  }

  showDelete(item) {
    this.activities.show();
    console.log(item);
    // this.recordCreatedOn = item
    // console.log(this.recordCreatedOn);
    this.histableid = item
    console.log(this.histableid);
  }

  deleteselectedFile() {
    this.backendCall.masldeleteFile({ deletemasleadhistableid: this.histableid, username: this.username }).subscribe(Response => {
      if (Response) {
        // this.router.navigate(['/record-management']);
        location.reload();
        alert("File delete successfully")
      }
    },
      error => {
        alert(error.error.error_msg);
        this.router.navigate(['/dashboard/analytics']);
      });
  }



  showHistory() {
    document.getElementById("uploadOverview").style.display = "none";
    document.getElementById("goBackTo").style.display = "none";
    document.getElementById("history_table").style.display = "block";
    document.getElementById("goBackToHistory").style.display = "flex";
    this.masterLeadHistory();
  }

  public masterLeadHistory(): any {
    this.backendCall.getmasterLeadhistory().subscribe(Response => {
      if (Response) {
        this.masterLeadUploadHistory = Response.data[0].getmasterLeadhistory;
        this.totalfilshistorycount = this.masterLeadUploadHistory.length
        this.currentmasterFileleads = Response.data[0].getmasterLeadhistory[0].no_of_case;
        this.currentmasterFileleadspremium = Response.data[0].getmasterLeadhistory[0].premium;
        this.uploadedfileid =  Response.data[0].getmasterLeadhistory[0].id;
        console.log(this.masterLeadUploadHistory);

      }
    },
      error => {
        alert(error.error.error_msg);
      });
  }

  showDuplicate() {
    document.getElementById("duplicate_div").style.display = "flex";
    document.getElementById("goBackToUpload").style.display = "flex";
    document.getElementById("uploadOverview").style.display = "none";
    document.getElementById("goBackTo").style.display = "none";
  }

  seeCase(item) {
    this.uploadedfileid = item
    console.log(this.uploadedfileid);
    this.router.navigate(['/record-list'],{queryParams :{uplopedFileid: this.uploadedfileid}})
    // this.router.navigate(['/record-list']);
    // alert('this will route to the 100 cases')
  }

  route() {
    // this.router.navigate(['/record-list']);
    console.log(this.uploadedfileid);
    this.router.navigate(['/record-list'],{queryParams :{uplopedFileid: this.uploadedfileid}})
  }

  goBack(stage: any) {
    if (stage === 'upload') {
      document.getElementById("upload_dpt").style.display = "inline-flex";
      document.getElementById("motor").style.display = "none";
      document.getElementById("nonMotor").style.display = "none";
      document.getElementById("goBackTo").style.display = "none";
      document.getElementById("box").style.display = "none";
    } else if (stage === 'duplicate') {
      document.getElementById("uploadOverview").style.display = "block";
      document.getElementById("duplicate_div").style.display = "none";
      document.getElementById("goBackTo").style.display = "flex";
      document.getElementById("goBackToUpload").style.display = "none";
    } else if (stage === 'history') {
      document.getElementById("uploadOverview").style.display = "flex";
      document.getElementById("goBackTo").style.display = "flex";
      document.getElementById("history_table").style.display = "none";
      document.getElementById("goBackToHistory").style.display = "none";
    } else if (stage === 'duplicateTable') {
      document.getElementById("duplicate_div").style.display = "flex";
      document.getElementById("goBackToUpload").style.display = "block";
      document.getElementById("duplicate_table").style.display = "none";
      document.getElementById("goBackToDuplicateLeads").style.display = "none";
    }

  }

  showDuplicateTable(regno,year) {
    console.log(regno,year);
    this.registerCarNo = regno
    this.dupYear = year
    console.log(this.registerCarNo,this.dupYear);
    document.getElementById("duplicate_div").style.display = "none";
    document.getElementById("goBackToUpload").style.display = "none";
    document.getElementById("duplicate_table").style.display = "block";
    document.getElementById("goBackToDuplicateLeads").style.display = "block";
    this.duplicateLeadInfo();
  }

  duplicateLeadInfo() {
    this.backendCall.getduplicateLeadInfo(this.registerCarNo,this.dupYear).subscribe(Response => {
      if (Response) {
        console.log(Response);
        this.duplicateMasterLead = Response.data.masterleadduplicateDetails;
        this.duplicateMasterLeadcount = this.duplicateMasterLead.length
      }
    },
      error => {
        alert(error.error.error_msg);
      });
  }

  updateMotor() {
    this.getCountRoleWise();
    this.masterLeadHistory();
    document.getElementById("motor").style.display = "block";
    document.getElementById("box").style.display = "flex";
    document.getElementById("nonMotor").style.display = "none";
    document.getElementById("upload_dpt").style.display = "none";
    document.getElementById("goBackTo").style.display = "flex";
  }

  updateNonMotor() {
    document.getElementById("nonMotor").style.display = "block";
    document.getElementById("box").style.display = "block";
    document.getElementById("motor").style.display = "none";
    document.getElementById("upload_dpt").style.display = "none";
    document.getElementById("goBackTo").style.display = "block";
  }

  verify1() {

    this.isVerified = true;

  }

  getLeadId(Event: any, leadId: any, regNo, year) {

    const modifyArray: FormArray = this.modifyForm.get('modifyArray') as FormArray

    if (Event.target.checked) {
      modifyArray.push(new FormControl(Event.target.value))
    } else {
      var i = 0;
      modifyArray.controls.forEach((item: any) => {
        if (item.value == Event.target.value) {
          modifyArray.removeAt(i);
          return;
        }
        i++;
      })
    }

    if (modifyArray.length == 0) {
      this.isChecked = false;
    } else {
      this.isChecked = true;
    }

    this.dupId = leadId
    this.dupYear = year
    this.dupRegno = regNo
    console.log(leadId, year, regNo);

    console.log((this.isChecked));

  }

  submitLeads() {
    console.log(this.modifyForm.value);
    let arr = this.modifyForm.value.modifyArray
    this.leadid = arr.map((i: any) => Number(i));

    this.backendCall.updateduplicatemasterleads({ duplicateid: this.dupId, duplicateyear: this.dupYear, duplicateregno: this.dupRegno, username: this.username }).subscribe(Response => {
      if (Response) {
        alert("updated duplicate leads successfully")
        window.location.reload();
        this.router.navigate(['/record-management']);
      }
    },
      error => {
        //console.log(error);
        alert(error.error.error_msg);
        this.router.navigate(['/record-management']);
      });
  }


  reverseVerify() {
    //  alert('123')
    this.isVerified = false;
    // console.log("akshay");
    
    // console.log("akshay");

  }

  policyRecivedFileHandler(files: FileList) {
    // let fileData = files;
    // console.log(fileData);
    // let uploadedFile = fileData.name;
    // alert(fileData)
    this.submitStatus = true;
    this.policyRecivedArray = [];
    for (var key in files) {
      if (files.hasOwnProperty(key)) {
        let fileData = files[key]
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = () => {
          console.log(reader.result)
          console.log(this.quoteUpload = fileData.name);
          this.policyRecivedArray.push({
            name: fileData.name,
            size: fileData.size,
            type: fileData.type,
            codec: reader.result
          })
        };
      }
    }
  }

}

