import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BackendCallService } from 'src/app/services/backend-call.service';

@Component({
  selector: 'app-insurer-tracking',
  templateUrl: './insurer-tracking.component.html',
  styleUrls: ['./insurer-tracking.component.scss']
})
export class InsurerTrackingComponent implements OnInit {
  getinsurancecompanyArray: any;
  failedAlert: boolean;
  message: string;
  stage: any;
  myQueryParams = [
    { id: 1, stage: 'insurer' },
    { id: 2, location: 'insurerYear' },
  ];
  getYearInsurancecompanyArray: any;
  getYearMonthInsurancecompanyArray: any;
  getYearMonthDaysInsurancecompanyArray: any;

  totalInsurercount: any;

  yearTab: boolean = false;
  insurerTab = true;
  dateTab: boolean = false;

  insurerCompanyName: any;
  currentYear: any;
  yearsMonth: any;
  monthDay: any;

  currentRole: any;
  loggedInAgentName: any;
  myArray: any;

  constructor(private router: Router, private route: ActivatedRoute, private backendCall: BackendCallService) {
    this.currentRole = localStorage.getItem('role');
    this.loggedInAgentName = localStorage.getItem('name');
    this.myArray = this.loggedInAgentName.split(' ')
   }

  ngOnInit() {
    this.YearInsurancecompany();
    this.route.queryParams.subscribe((params: any) => {
      this.stage = params.stage
      console.log(this.stage)
    })
  }

  backBtnTwo() {
    if (this.stage == "insurerHome") {
      this.router.navigate(['tracking/agent/hourly'])
    } else if (this.stage == "insurer") {
      // this.router.navigate(['track/date'], {
      //   queryParams: {
      //     filter: JSON.stringify(this.myQueryParams)
      //   }
      // });
      this.router.navigate(['track/date'], { queryParams: { stage: 'insurer', 'location': 'insurerYear' } });
      // this.router.navigate(['track/date'], { queryParams: { stage: ['insurer','insurerYear']}});
    }
  }

  backBtnMonth() {
    this.yearTab = false;
    this.insurerTab = true;
    this.dateTab = false;
  }

  backBtnDate() {
    this.yearTab = true;
    this.insurerTab = false;
    this.dateTab = false;
  }

  closeNav() {
    document.getElementById("mySidebar").style.display = "none"
  }

  openNav() {
    document.getElementById("mySidebar").style.display = "block"
  }

  showInsurertoYears(item) {
    this.yearTab = true;
    this.insurerTab = false;
    this.dateTab = false;
    console.log("year tab");
    console.log(this.yearTab);
    console.log(this.insurerTab);
    console.log(this.dateTab);


    let role = this.currentRole
    console.log(role);
    let name = []
    name = this.myArray
    console.log(name[0]);
    let firstName = name[0]
    let lastName = name[1]
    console.log(name[1]);

    console.log(item);
    this.insurerCompanyName = item.item_insurer
    this.currentYear = item.item_Year
    console.log(this.currentYear);

    let insurer = item.item_insurer
    this.backendCall.getYearMonthInsurancecompany(insurer,role, firstName, lastName)
      .subscribe(success => {

        if (success) {

          if (success.data.length) {
            let getYearMonthInsurancecompanyArrayData = success.data[0].getYearMonthInsurancecompanycount;
            let tempadd = [];

            getYearMonthInsurancecompanyArrayData.forEach(ele => {
              tempadd.push({ item_count: Number(ele.count), item_month: ele.month})
            });
            this.getYearMonthInsurancecompanyArray = tempadd
            console.log(this.getYearMonthInsurancecompanyArray);


          }
        }
      },
        error => {
          //alert(error.error.error_msg);
          this.failedAlert = true;
          this.message = 'Something went wrong while Fetching entry for update';
        });



    // let insurerdisposition = this.insurerCompanyName
    // let insurer  = 'yearInsurer'
    // let stage = 'tracking'
    // console.log(insurer);
    // this.router.navigate([`/lead/list?insurer=${insurerdisposition}&type=${insurer}`])
    // this.router.navigate([`/lead/list`],{queryParams :{insurer: insurerdisposition,type: insurer, stage : stage}})

  }

  showDays(item) {
    this.yearTab = false;
    this.insurerTab = false;
    this.dateTab = true;
    console.log("month tab");
    console.log(item);
    this.yearsMonth = item.item_month
    
    let role = this.currentRole
    console.log(role);
    let name = []
    name = this.myArray
    console.log(name[0]);
    let firstName = name[0]
    let lastName = name[1]
    console.log(name[1]);

    let month = this.yearsMonth
    let insurer = this.insurerCompanyName
    console.log(month,insurer);
    this.backendCall.getYearMonthDaysInsurancecompany(month,insurer,role, firstName, lastName)
      .subscribe(success => {

        if (success) {

          if (success.data.length) {
            let getYearMonthDaysInsurancecompanyArrayData = success.data[0].getYearMonthDaysInsurancecompanycount;
            let tempadd = [];

            getYearMonthDaysInsurancecompanyArrayData.forEach(ele => {
              tempadd.push({ item_Count: Number(ele.Count), item_Month: ele.Month,item_Day: Number(ele.Day)})
            });
            this.getYearMonthDaysInsurancecompanyArray = tempadd
            console.log(this.getYearMonthDaysInsurancecompanyArray);


          }
        }
      },
        error => {
          //alert(error.error.error_msg);
          this.failedAlert = true;
          this.message = 'Something went wrong while Fetching entry for update';
        });
    

  }

  showDateToLeads(item){
    console.log(item);
    this.monthDay = item.item_Day
    let insurerdisposition = this.insurerCompanyName
    let month = this.yearsMonth
    let day =this.monthDay
    console.log(insurerdisposition,month,day);
    let insurer  = 'insurerMonthDay'
    let stage = 'tracking'
    
    this.router.navigate([`/lead/list`],{queryParams :{insurer: insurerdisposition,day: day,month: month,type: insurer, stage : stage}})

  }

  clickToViewYearInsurer() {

    let insurerdisposition = this.insurerCompanyName
    let insurer = 'yearInsurer'
    let stage = 'tracking'
    console.log(insurer);
    // this.router.navigate([`/lead/list?insurer=${insurerdisposition}&type=${insurer}`])
    this.router.navigate([`/lead/list`], { queryParams: { insurer: insurerdisposition, type: insurer, stage: stage } })
  }

  clickToViewMonthInsurer(){
    let insurerdisposition = this.insurerCompanyName
    let month = this.yearsMonth
    console.log(insurerdisposition,month);
    let insurer  = 'insurerMonth'
    let stage = 'tracking'
    this.router.navigate([`/lead/list`],{queryParams :{insurer: insurerdisposition,month: month,type: insurer, stage : stage}})
  }

  YearInsurancecompany() {

    let role = this.currentRole
    console.log(role);
    let name = []
    name = this.myArray
    console.log(name[0]);
    let firstName = name[0]
    let lastName = name[1]
    console.log(name[1]);

    this.backendCall.getYearInsurancecompany(role, firstName, lastName)
      .subscribe(success => {

        if (success) {

          if (success.data.length) {
            let getYearInsurancecompanyArrayData = success.data[0].getYearInsurancecompanycount;
            this.totalInsurercount = success.data[0].getTotalYearInsurancecompany[0].count
            console.log(this.totalInsurercount);
            
            
            let tempadd = [];
            // let temptotal = [];
         
            getYearInsurancecompanyArrayData.forEach(ele => {
              tempadd.push({ item_count: Number(ele.count), item_insurer: ele.insurer, item_Year: Number(ele.Year) })
            });
            this.getYearInsurancecompanyArray = tempadd
            console.log(this.getYearInsurancecompanyArray);

            // totalInsurercountData.forEach(ele => {
            //   temptotal.push({ item_count: Number(ele.count)})
            // });
            // this.totalInsurercount = temptotal
            // console.log(this.totalInsurercount.item_count);


          }
        }
      },
        error => {
          //alert(error.error.error_msg);
          this.failedAlert = true;
          this.message = 'Something went wrong while Fetching entry for update';
        });
  }

}
