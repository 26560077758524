import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BackendCallService } from 'src/app/services/backend-call.service';
import { NumberofdaysservicesService } from 'src/app/tracknumberofdays/numberofdaysservices.service';

@Component({
  selector: 'app-month-tracking',
  templateUrl: './month-tracking.component.html',
  styleUrls: ['./month-tracking.component.scss']
})
export class MonthTrackingComponent implements OnInit {
  successAlert: boolean;
  message: any;
  failedAlert: boolean;
  getmonthArray: any;
  myMonth: any;
  monthNames: any;
  dayNames: any;
  onClickDate: any;
  monthNamesArray:Array<{id: number, month: string}>;
  getyearcountArray: any[];

  totalcountInyear: [];

  stage: any;
  yearTab: boolean = true;
  dateTab: boolean = false;
  insurerTab: boolean = false;
  getEachMonthdaysArray: any[];
  getEachMonthdaysinsurenceArray: any;

  public constructor(private router: Router, private route: ActivatedRoute, private backendCall: BackendCallService, private NumberofdaysservicesService: NumberofdaysservicesService) {  }

  ngOnInit() {
    this.getyear();
    // this.NumberofdaysservicesService.setData(this.onClickDate);
    // console.log(this.onClickDate);
    //     const options = { month: "long" };
    // console.log(new Date().getMonth());
    // console.log(new Date().getFullYear());
    // function daysInMonth (month, year) { // Use 1 for January, 2 for February, etc.
    //   return new Date(year, month, 0).getDate();
    // }
    // console.log(daysInMonth(new Date().getMonth(), new Date().getFullYear()));
    // const monthNames = [
    //   "January", "February", "March", "April", "May", "June",
    // "July", "August", "September", "October", "November", "December"
    // ];
    // this.monthNames = [
    //   { id: 1, month: 'January'},
    //   { id: 2, month: 'February'},
    //   { id: 3, month: 'March'},
    //   { id: 4, month: 'April'},
    //   { id: 5, month: 'May'},
    //   { id: 6, month: 'June'},
    //   { id: 7, month: 'July'},
    //   { id: 8, month: 'August'},
    //   { id: 9, month: 'September'},
    //   { id: 10, month: 'October'},
    //   { id: 11, month: 'November'},
    //   { id: 12, month: 'December'}
    // ]
    // console.log(typeof(this.monthNames));

    // this.myMonth = this.monthNames
    // console.log(daysInMonth(1,new Date().getFullYear()));


  }



  showDays(item)  {
    this.yearTab = false;
    this.insurerTab = false;
    this.dateTab = true;
    console.log(this.dateTab);
    let Month = item.item_text 
    this.backendCall.getEachMonthdays(Month)
      .subscribe(success => {

        if (success) {

          if (success.data.length) {
            let getinsurancecompanyArrayData = success.data[0].getEachMonthdayscount;
            let tempadd = [];

            getinsurancecompanyArrayData.forEach(ele => {
              tempadd.push({ item_id: Number(ele.Count), item_text: ele.Month,item_day: Number(ele.Day) })
            });
            this.monthNames = item.item_text
            this.getEachMonthdaysArray = tempadd
            console.log(this.getEachMonthdaysArray);


          }
        }
      },
        error => {
          //alert(error.error.error_msg);
          this.failedAlert = true;
          this.message = 'Something went wrong while Fetching entry for update';
        });
    // let monthday = item.index
    // const options = { month: "long" };
    //   function daysInMonth (month, year) { // Use 1 for January, 2 for February, etc.
    //     return new Date(year, month, 0).getDate();
    //   }
    //   console.log(daysInMonth(monthday,new Date().getFullYear()))
    //     let days  = (daysInMonth(monthday,new Date().getFullYear()))
    //    console.log(days);
    // console.log(item);
    //  this.monthNamesArray = [{ id: days, month: item.item_text}]
    //  console.log(this.monthNamesArray);
  //   var arr = [];
  // for (var i = 0; i < days; i++) {
  //   arr.push(item);
  // }
// console.log(arr);

     
    // this.onClickDate = arr
    // this.NumberofdaysservicesService.send_data.next(this.onClickDate)
    // this.onClickDate=item.item_id+","+item.item_text;
    // console.log(this.onClickDate);
    // this.NumberofdaysservicesService.setData(this.onClickDate);
    // this.NumberofdaysservicesService.currentMessage.subscribe(message => this.message = arr)
    // this.NumberofdaysservicesService.setData(arr);

  }

  showCase(){
  alert("2222")
  }

  backBtnMonth()  {
    this.router.navigate(['tracking/agent/hourly']);
  }

  backBtnDate() {
    this.yearTab = true;
    this.insurerTab = false;
    this.dateTab = false;
  }

  backBtnTwo() {
    this.yearTab = false;
    this.insurerTab = false;
    this.dateTab = true;
  }

  showDateToIns(item) {
    this.yearTab = false;
    this.insurerTab = true;
    this.dateTab = false;
    console.log(item);
    this.dayNames = item.item_day
    let Month = item.item_text
    let Day = item.item_day
    this.backendCall.getEachMonthdaysinsurence(Month,Day)
      .subscribe(success => {

        if (success) {

          if (success.data.length) {
            let getinsurancecompanyArrayData = success.data[0].getEachMonthdaysinsurencecount;
            let tempadd = [];

            getinsurancecompanyArrayData.forEach(ele => {
              tempadd.push({ item_id: Number(ele.count), item_text: ele.insurer})
            });
            this.getEachMonthdaysinsurenceArray = tempadd
            console.log(this.getEachMonthdaysinsurenceArray);


          }
        }
      },
        error => {
          //alert(error.error.error_msg);
          this.failedAlert = true;
          this.message = 'Something went wrong while Fetching entry for update';
        });
    
  }

  closeNav() {
    document.getElementById("mySidebar").style.display = "none"
  }

  openNav() {
    document.getElementById("mySidebar").style.display = "block"
  }
  getyear() {
    this.backendCall.getmonth()
      .subscribe(success => {

        if (success) {

          if (success.data.length) {
            let getmonthData = success.data[0].getmonthcount[0].count;
            this.totalcountInyear = getmonthData
            console.log(this.totalcountInyear);
            

            // let tempadd = [];

            // getmonthData.forEach(ele => {
            //   tempadd.push({ item_id: Number(ele.id), item_text: ele.month })
            // });
            // this.getmonthArray = tempadd
            // console.log(this.getmonthArray);

            let getyearcountData = success.data[0].getyearcount;
            let tempadd2 = [];

            getyearcountData.forEach(ele => {
              tempadd2.push({ item_text: ele.month, item_id: Number(ele.count) })
            });
            this.getyearcountArray = tempadd2
            console.log(this.getyearcountArray);


          }
        }
      },
        error => {
          //alert(error.error.error_msg);
          this.failedAlert = true;
          this.message = 'Something went wrong while Fetching entry for update';
        });
  }

  showYearMonthDayInsurertoDisposition(item){
    console.log(item);
    let month = this.monthNames
    let day = this.dayNames
    let insurerdisposition = item.item_text
    let insurer  = 'daysInsurer'
    let stage = 'tracking'
    console.log(month,day,insurerdisposition);
    

    this.router.navigate([`/lead/list`],{queryParams :{insurer: insurerdisposition,day: day,month: month,type: insurer, stage : stage}})
  }

  // getYearMonth(){
  //   const monthNames = [
  //     "January", "February", "March", "April", "May", "June",
  //   "July", "August", "September", "October", "November", "December"
  // ];
  // console.log(monthNames);

  // }

}


