import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { BackendCallService } from 'src/app/services/backend-call.service';
import { Router } from '@angular/router';
type AOA = any[][];

@Component({
  selector: 'app-data-recon',
  templateUrl: './data-recon.component.html',
  styleUrls: ['./data-recon.component.scss']
})
export class DataReconComponent implements OnInit {
  submitStatus: boolean;
  policyRecivedArray: any[];
  quoteUpload: string;
  buttonDisable: boolean = true;
  btnLoader: boolean = false
  chooseFile: string = 'Choose File';
  submitted: boolean = false;
  uploadFile: FormGroup;
  pretotal = 0;
  data: AOA;
  masleaduplArray = [];
  username: any;
  // ngIf variables
  stepOne: boolean = true;
  foundTable: boolean = false;
  isNotFoundTable: boolean = false;
  isFound: boolean = true;
  isNotFound: boolean = true;


  constructor(private backendCall: BackendCallService, private router: Router, private formBuilder: FormBuilder,) { }

  ngOnInit() {
    this.uploadFile = this.formBuilder.group({
      excelFile: ['', Validators.required]
    });
  }

  policyRecivedFileHandler(files: FileList) {
    // let fileData = files;
    // console.log(fileData);
    // let uploadedFile = fileData.name;
    // alert(fileData)
    this.submitStatus = true;
    this.policyRecivedArray = [];
    for (var key in files) {
      if (files.hasOwnProperty(key)) {
        let fileData = files[key]
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = () => {
          console.log(reader.result)
          console.log(this.quoteUpload = fileData.name);
          this.policyRecivedArray.push({
            name: fileData.name,
            size: fileData.size,
            type: fileData.type,
            codec: reader.result
          })
        };
      }
    }
  }

  onSubmit(evt: any): void {
    // Process checkout data here
    /* wire up file reader */
    this.btnLoader = true;
    this.chooseFile = evt.target.files[0].name;
    console.log(this.chooseFile);
    //console.log(evt);
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {
        type: 'binary', cellDates: true,
        cellNF: false,
        cellText: false
      });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, {
        header: 1, dateNF: "yyyy/mm/dd"
      }));
      console.log(this.data);
      for (let i = 1; i < this.data.length; i++) {
        this.pretotal = this.pretotal + parseInt(this.data[i][58]);
      }
      console.log(this.pretotal)
      // this.masleaduplArray.push({
      //   filename: this.chooseFile,
      //   username: this.username,
      //   noofcase: this.data.length - 1,
      //   premium: this.pretotal,
      //   delete: "No"
      // })
      this.masleaduplArray.push([
        this.chooseFile,
        this.username,
        this.data.length - 1,
        this.pretotal,
        "No"
      ])
      console.log(this.username);
      console.log(this.masleaduplArray);
      this.btnLoader = false;
      this.buttonDisable = false;
    };
    reader.readAsBinaryString(target.files[0]);
  }

  formValidation(formElement) {
    let _element = this.uploadFile.controls[formElement];

    //console.log(_element)
    if (_element.invalid && this.submitted) {
      return 'custom-file-input form-control is-invalid';

    } else if (_element.touched && _element.valid) {
      return 'form-control is-valid';

    } else {
      return 'custom-file-input form-control';
    }
  }

  uplaodData(): void {
    //console.log("upload this data", this.data)
    this.buttonDisable = true;
    this.submitted = true;
    this.btnLoader = true;
    if (!this.uploadFile.invalid) {
      // console.log(this.data);
      this.backendCall.masluploadFile({ uploadContent: this.data, historyContent: this.masleaduplArray }).subscribe(Response => {
        //console.log(Response, 'return data')
        this.btnLoader = false;
        if (Response) {
          location.reload();
          this.router.navigate(['/record-management']);
          alert("File uploaded successfully")
        }
      },
        error => {
          //console.log(error);
          alert(error.error.error_msg);
          window.location.reload();
          this.router.navigate(['/record-management']);
        });
    }
  }

  showTable(stage: any) {

    if(stage == 'found'){
      this.stepOne = false;
      this.foundTable = true;
    } else{
      this.stepOne = false;
      this.isNotFoundTable = true;
    }

 
  }

  goBack(stage: any) {
    if(stage == 'foundTable'){
      this.stepOne = true;
      this.foundTable = false;
    }else{
      this.stepOne = true;
      this.isNotFoundTable = false;
    }

  }

  downloadIt(){
    alert('download it!')
  }

}
