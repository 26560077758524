let pasteCount = 0

async function pasteFun(type) {
    if (type == "link") {

        const data = await navigator.clipboard.read();
        const clipboardContent = data[0];
        console.log(clipboardContent);
        const type = clipboardContent.types[0];
        pasteCount = pasteCount + 1
        if (type == 'text/plain') {
            const blob = await clipboardContent.getType('text/plain')
            var text = await blob.text();
            document.getElementById("paymentLink").value = text
           let ele =  document.getElementById("paymentLink");

           ele.dispatchEvent(new KeyboardEvent('keypress',{'key':'a'}));
        }

    } else {
        const pasteBtn = document.getElementById('btn_paste');
        // copy Function-----------------------------------------------------
        // const content = document.getElementById('copy_text');
        // navigator.clipboard.writeText(content);
        // pasteBtn.addEventListener(async(event)=>{
        const data = await navigator.clipboard.read();
        const clipboardContent = data[0];
        //console.loh(clipboardContent, "clipboardcontent")
        const type = clipboardContent.types[0];
        pasteCount = pasteCount + 1
        if (type == 'text/plain') {
            const blob = await clipboardContent.getType('text/plain')
            var text = await blob.text();
            var p = document.createElement('p');
            var html = `
                ${text}
                `
            p.setAttribute('id', "item" + pasteCount);
            p.innerHTML = html;
            document.getElementById('paste_here').appendChild(p)
        } else {
            const blob = await clipboardContent.getType('image/png');
            const url = window.URL.createObjectURL(blob);
            const img = document.createElement('img');
            img.setAttribute('id', "item" + pasteCount);

            img.src = url;
            img.style.maxHeight = '300px';
            img.style.maxWidth = '500px';
            img.style.margin = '2% 2%';

            // itemdiv.appendChild(img);    
            document.getElementById('paste_here').appendChild(img);
        }

    }

}

async function pasteLink() {
    alert("JS working");
    // alert("JS working");
    //const pasteBtn = document.getElementById('btn_paste');
    // copy Function-----------------------------------------------------
    // const content = document.getElementById('copy_text');
    // navigator.clipboard.writeText(content);
    // pasteBtn.addEventListener(async(event)=>{
    const data = await navigator.clipboard.read();
    const clipboardContent = data[0];
    console.log(clipboardContent);
    const type = clipboardContent.types[0];
    pasteCount = pasteCount + 1
    if (type == 'text/plain') {
        const blob = await clipboardContent.getType('text/plain')
        var text = await blob.text();
        document.getElementById("paymentLink").value = text
    } else {
        const blob = await clipboardContent.getType('image/png');
        const url = window.URL.createObjectURL(blob);
        const img = document.createElement('img');
        img.setAttribute('id', "item" + pasteCount);

        img.src = url;
        img.style.maxHeight = '300px';
        img.style.maxWidth = '500px';
        img.style.margin = '2% 2%';

        // itemdiv.appendChild(img);    
        document.getElementById('paste_here').appendChild(img);
    }
}

// function deleteItem(params) {
//     console.log(params);
//     document.getElementById(params).remove();
// }

function deleteItem() {
    console.log("vdddddddddddddd");
    const list = document.getElementById("paste_here");
    list.removeChild(list.firstElementChild);
}

async function pastePdf() {

    const data = await navigator.clipboard.read();
    const clipboardContent = data[0];
    const type = clipboardContent.types[0];

    const blob = await clipboardContent.getType('image/pdf');
    const url = window.URL.createObjectURL(blob);
    const img = document.createElement('img');
    img.setAttribute('id', "item" + pasteCount);

    img.src = url;
    img.style.maxHeight = '300px';
    img.style.maxWidth = '500px';
    img.style.margin = '2% 2%';

    const itemdiv = document.createElement('div', 'item' + pasteCount);
    var html = `
    <div>
    <p onclick="deleteItem('item'+pasteCount)">cancel</p>
    </div>
    `
    itemdiv.innerHTML = html
    console.log(`item${pasteCount}`);
    itemdiv.appendChild(img);
    document.getElementById('pdfHouse').appendChild(itemdiv);
}







    