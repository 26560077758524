import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NumberofdaysservicesService {

  // send_data = new Subject<any>()
  // public messageSubject = new Subject();
  // private messageSource = new BehaviorSubject("");
  // currentMessage = this.messageSource.asObservable();
 private articledata=new BehaviorSubject<Object>({});

  constructor() { }

  getData() : Observable<Object> {
    return this.articledata.asObservable();
 }

 setData(data) : void { 
  this.articledata.next(data); } 
  // public setData(message: any) {
  //   this.messageSubject.next(message);
  //  }
 
  //  public getData(): Observable<any> {
  //   return this.messageSubject.asObservable();
  // }

  // changeMessage(message: any) {
  //   this.messageSource.next(message)
  // }
  
}
