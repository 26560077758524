import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BackendCallService } from '../../services/backend-call.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { stringify } from 'querystring';
import Swal from 'sweetalert2';
import { ChangeDetectionStrategy } from "@angular/core";
import { DatePipe, formatDate } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sale-entry',
  templateUrl: './sale-entry.component.html',
  styleUrls: ['./sale-entry.component.scss']
})
export class SaleEntryComponent implements OnInit {

  @ViewChild('itemForm', { static: false }) form!: NgForm;

  createLeadForm: FormGroup;
  successAlert: boolean = false;
  submitted: boolean;
  failedAlert: boolean = false;
  message: string;
  dropdownSettingsForAgent: IDropdownSettings = {};
  dropdownSettings: IDropdownSettings = {};
  leadId = null;
  agentData: any;
  userList: any;
  currentRole: any = localStorage.getItem('role');
  productCode = ""
  dropdownsObj = {
    products: [],
    branchName: [],
    insuranceCompany: [],
    bqpName: [],
    paymentname: [],
    businessname: [],
    policytypename: [],
    fueltypename: [],
    NCBname: [],
    addOns: [],
    vehicleType: [],
    seatingCapacity: []
  }
  nonMotorDropDownObj = {
    health: [],
    life: [],
    liability: [],
    marine: [],
    engineering: [],
    fire: []
  }
  customVehicleType = []
  defaultFuelType = {
    "diesel": "diesel",
    "petrol": "petrol"
  }
  defaultBranch = {
    "mumbai": "Mumbai"
  }
  bqpNumber: any
  datePipe: DatePipe = new DatePipe('en-US');
  dropDownsSelectedValue = {}
  carNamesDropdown = []
  carModelNamesDropdown = []
  manufactureYearDropdown = []
  addOnDataArray: any
  openedTrance = "VehicleDetails"
  tempData = "HelloWorld"
  formEntryDatas = [
    { "saleBranch": "", "saleDate": "", "saleBqp": "", "saleRefered": '' },
    {
      "ownerName": "", "ownerMobileNo": "", "ownerEmailId": "", "ownerAddress": "", "PanNumber": "", "GstNumber": "",
      "DateOfBirth": "", "Gender": "", "NomineeDetails": ""
    },
    {
      "vehicleRegistrationNumber": "", "vehicleManufactureYear": "", "vehicleMake": "",
      "vehicleModel": "", "vehicleEngineNumber": "", "vehicleChasisNumber": "", "vehicleFuelType": "",
      "vehicleType": "", "vehicleSeatingCapacity": "", "vehicleGCV": "", "vehicleCubicCapacity": "",
      "vehicleVariant": "", "vehicleCategory": "", "vehicleLoanProvider": "", "vehicleRankYear": "",
      "vehicleRTOCode": "", "vehicleRTOCity": "", "vehicleRTOState": "", "vehicleEngineCC": ""
    },
    {
      "addOns": "", "businesstype": "", "insuranceCompany": "", "issueDateon": "", "policyNumber": "", "policyType": "",
      "riskEnddate": "", "riskStartdate": "", "CoverageType": "", "PreviouseInsurerName": "", "PreviouseInsuranceNumber": "",
      "InsuranceIDV": "", "InsuranceNCB": "", "PreviousePolicyDocumentUrl": "", "CurrentPolicyDocumentUrl": "", "Status": ""
    },
    { "ownDamagePremium": '', "thirdPartyPremium": '', "netPremium": '', "grossPremium": '', "ServiceTax": "" },
    { "paymentMode": '', "paymentDetails": '', "CashBack": "", "Commission": '' }
  ]
  selectedDpt = ""
  ProductName = ""
  noteAplicable = "NA"
  selectedPolicyType = ""
  premiumZero = "0"
  editSlotsObj = {
    "isOwnerDetailsEdit": 0,
    "isVehicleDetailsEdit": 0,
    "isInsuranceDetailsEdit": 0,
    "isPremiumDetailsEdit": 0,
    "isSaleDetailsEdit": 0,
    "isTransactionDetails": 0
  }
  fb: any = [];
  selectedAddOnsArry = [];
  selectedAddOnsStr: any;
  transformDate: string;

  constructor(private backendCall: BackendCallService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute) {

    this.route.queryParams.subscribe(params => {
      console.log(params);
      if (params['hash'] != undefined) {
        console.log("Create Leads Params:");
        console.log(params['hash'])
        this.leadId = atob(params['hash']);
        // window.location.reload();
        // this.leadId = 104761
        console.log("lead id", this.leadId)
      }
    });
  }

  get f() {
    return this.createLeadForm.controls;
  }

  title = "CodeSandbox";
  ddForm: FormGroup;
  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  cities: any = [];
  paymentMode: any = [];
  selectedItems: any;
  branchType: any;
  inscomName: any;
  leadsDetails: any;
  succ: any;
  vd = 1;
  insd = 1;
  pd = 1;
  sd = 1;
  td = 1;
  od = 1;
  icn = [];
  dptProductName: any;

  ngOnInit() {
    const d = new Date();
    let year = d.getFullYear();
    let tempYearArray = []
    for (let i = 1991; i < year + 1; i++) {
      tempYearArray.push(JSON.stringify(i))
    }
    this.manufactureYearDropdown = tempYearArray
    console.log(this.manufactureYearDropdown);

    this.createLeadForm = this.formBuilder.group({
      saleBranch: ['', Validators.required],
      saleDate: ['', Validators.required],
      saleBqp: ['', Validators.required],
      saleRefered: ['', Validators.required],
      ownerName: ['', Validators.required],
      ownerMobileNo: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
      ownerEmailId: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      ownerAddress: ['', Validators.required],
      PanNumber: ['', Validators.required],
      GstNumber: ['', Validators.required],
      DateOfBirth: ['', Validators.required],
      Gender: ['', Validators.required],
      NomineeDetails: ['', Validators.required],
      vehicleRegistrationNumber: ['', Validators.required],
      vehicleManufactureYear: ['', Validators.required],
      vehicleMake: ['', Validators.required],
      vehicleModel: ['', Validators.required],
      vehicleVariant: ['', Validators.required],
      vehicleCategory: ['', Validators.required],
      vehicleLoanProvider: ['', Validators.required],
      vehicleRankYear: ['', Validators.required],
      vehicleRTOCode: ['', Validators.required],
      vehicleRTOCity: ['', Validators.required],
      vehicleRTOState: ['', Validators.required],
      vehicleEngineNumber: ['', Validators.required],
      vehicleChasisNumber: ['', Validators.required],
      vehicleEngineCC: ['', Validators.required],
      vehicleType: ['', Validators.required],
      vehicleFuelType: ['', Validators.required],
      vehicleSeatingCapacity: ['', Validators.required],
      vehicleGCV: ['', Validators.required],
      vehicleCubicCapacity: ['', Validators.required],
      insuranceCompany: ['', Validators.required],
      businesstype: ['', Validators.required],
      policyType: ['', Validators.required],
      issueDateon: ['', Validators.required],
      riskStartdate: ['', Validators.required],
      riskEnddate: ['', Validators.required],
      policyNumber: ['', Validators.required],
      addOns: ['', Validators.required],
      CoverageType: ['', Validators.required],
      PreviouseInsurerName: ['', Validators.required],
      PreviouseInsuranceNumber: ['', Validators.required],
      PreviousePolicyDocumentUrl: ['', Validators.required],
      CurrentPolicyDocumentUrl: ['', Validators.required],
      Status: ['', Validators.required],
      InsuranceIDV: ['', Validators.required],
      InsuranceNCB: ['', Validators.required],
      ownDamagePremium: ['', Validators.required],
      thirdPartyPremium: ['', Validators.required],
      netPremium: ['', Validators.required],
      ServiceTax: ['', Validators.required],
      grossPremium: ['', Validators.required],
      paymentMode: ['', Validators.required],
      paymentDetails: ['', Validators.required],
      CashBack: ['', Validators.required],
      Commission: ['', Validators.required]
    });
    this.getformattedDate()

    this.dropdownSettingsForAgent = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    // this.fetchMotorDropdowns()
    // this.fetchNonMotorDropdowns()
    // this.fetchCarName()

    this.cities = [
      { item_id: 1, item_text: 'New Delhi' },
      { item_id: 2, item_text: 'Mumbai' },
      { item_id: 3, item_text: 'Bangalore' },
      { item_id: 4, item_text: 'Pune' },
      { item_id: 5, item_text: 'Chennai' },
      { item_id: 6, item_text: 'Navsari' }
    ];

    this.paymentMode = [
      { item_id: 1, item_text: 'Online' },
      { item_id: 2, item_text: 'Offline' },
      { item_id: 3, item_text: 'Cash' },
      { item_id: 4, item_text: 'Cheque' }
    ];

    this.branchType = [
      { item_id: 1, item_text: 'New Customer' },
      { item_id: 2, item_text: 'Existing Customer' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
    // this.ddForm = this.fb.group({
    // 	city: [this.selectedItems]
    // });
  }

  public fetchMotorDropdowns(): any {
    this.backendCall.FetchMotorDropdowns().subscribe(Response => {
      if (Response) {
        let dropdownsObj = Response.data[0]
        let resDropdownKeys = Object.keys(dropdownsObj)
        let dropDownKeys = Object.keys(this.dropdownsObj)
        for (let i = 0; i < resDropdownKeys.length; i++) {
          for (let j = 0; j < dropDownKeys.length; j++) {
            let dropdownArry = []
            if (resDropdownKeys[i] == dropDownKeys[j]) {
              dropdownArry.push(dropdownsObj[resDropdownKeys[i]])
              this.dropdownsObj[dropDownKeys[j]] = dropdownArry[0]
            }
          }
        }
        console.log(this.dropdownsObj)
      }
    },
      error => {
        alert(error.error.error_msg);
      });
  }

  public fetchNonMotorDropdowns(): any {
    this.backendCall.FetchNonMotorDropdowns().subscribe(Response => {
      if (Response) {
        let dropdownsObj = Response.data[0]
        let resDropdownKeys = Object.keys(dropdownsObj)
        let dropDownKeys = Object.keys(this.nonMotorDropDownObj)
        for (let i = 0; i < resDropdownKeys.length; i++) {
          for (let j = 0; j < dropDownKeys.length; j++) {
            let dropdownArry = []
            if (resDropdownKeys[i] == dropDownKeys[j]) {
              dropdownArry.push(dropdownsObj[resDropdownKeys[i]])
              this.nonMotorDropDownObj[dropDownKeys[j]] = dropdownArry[0]
            }
          }
        }
        console.log(this.nonMotorDropDownObj)
      }
    },
      error => {
        alert(error.error.error_msg);
      });
  }

  public submitMotorEntry(formEntryData, productName, selectedDpt): any {
    this.backendCall.submiteMotorEntryData(formEntryData, productName, selectedDpt).subscribe(Response => {
      if (Response) {
        Swal.fire(
          'Good job!',
          'Offline lead Submitted Successfully!',
          'success'
        )
      }
    },
      error => {
        alert(error.error.error_msg);
      });
  }

  public fetchCarName(): any {
    this.backendCall.fetchCarNames().subscribe(Response => {
      if (Response) {
        this.carNamesDropdown = Response.data
      }
    },
      error => {
        alert(error.error.error_msg);
      });
  }

  public fetchBikeName(): any {
    this.backendCall.fetchBikeNames().subscribe(Response => {
      if (Response) {
        this.carNamesDropdown = Response.data
      }
    },
      error => {
        alert(error.error.error_msg);
      });
  }

  public fetchModelName(brandID): any {
    this.backendCall.fetchBrandNames(brandID).subscribe(Response => {
      if (Response) {
        this.carModelNamesDropdown = Response.data
      }
    },
      error => {
        alert(error.error.error_msg);
      });
  }

  public getInsPolAddDropDowns(): any {
    this.backendCall.getInsPolAddDropDowns().subscribe(Response => {
      if (Response) {
        // this.carNamesDropdown = Response.data
        this.dropdownsObj.policytypename = Response.data[0].policyType
        this.dropdownsObj.insuranceCompany = Response.data[0].insurance
        this.dropdownsObj.addOns = Response.data[0].addons
        // this.addOnDataArray = Response.data[0].addons
        this.dropdownsObj.bqpName = Response.data[0].usersData
        console.log(this.dropdownsObj.policytypename, this.dropdownsObj.insuranceCompany, this.dropdownsObj.addOns);
        console.log(Response.data[0].policyType, Response.data[0].insurance, Response.data[0].addons);


      }
    },
      error => {
        alert(error.error.error_msg);
      });
  }


  formValidation(formElement) {
    let _element = this.createLeadForm.controls[formElement];
    if (_element.touched && _element.errors) {
      return 'form-control is-invalid';

    } else if (_element.touched && _element.valid) {
      return 'form-control is-valid';

    } else if (_element.untouched && _element.invalid && this.submitted) {
      return 'form-control is-invalid';

    } else {
      return 'form-control';
    }
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.createLeadForm.invalid) {
      const invalid = [];
      const controls = this.createLeadForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
          console.log(invalid);
          alert("Please check these columns:" + invalid)
        }
      }
    }
    console.log(this.createLeadForm)
    if (!this.createLeadForm.invalid && this.leadId == null) {
      //console.log("innnn", this.f.dob.value)
      this.backendCall.createLead(
        [
          this.dptProductName,
          this.f.vehicleCategory.value,
          this.f.ownerName.value,
          this.f.ownerMobileNo.value,
          this.f.ownerEmailId.value,
          this.f.DateOfBirth.value,
          this.f.Gender.value,
          this.f.NomineeDetails.value,
          this.f.ownerAddress.value,
          this.f.PanNumber.value,
          this.f.GstNumber.value,
          this.f.vehicleLoanProvider.value,
          this.f.paymentDetails.value,
          this.f.policyNumber.value,
          this.f.insuranceCompany.value,
          this.f.PreviousePolicyDocumentUrl.value,
          this.f.CurrentPolicyDocumentUrl.value,
          this.f.Status.value,
          this.f.riskStartdate.value,
          this.f.riskEnddate.value,
          this.f.CoverageType.value,
          this.f.policyType.value,
          this.f.InsuranceIDV.value,
          this.f.InsuranceNCB.value,
          this.f.vehicleRegistrationNumber.value,
          this.f.issueDateon.value,
          this.f.vehicleRankYear.value,
          this.f.vehicleRTOCode.value,
          this.f.vehicleRTOCity.value,
          this.f.vehicleRTOState.value,
          this.f.vehicleMake.value,
          this.f.vehicleModel.value,
          this.f.vehicleVariant.value,
          this.f.vehicleFuelType.value,
          this.f.vehicleEngineCC.value,
          this.f.vehicleChasisNumber.value,
          this.f.vehicleEngineNumber.value,
          this.f.vehicleSeatingCapacity.value,
          this.f.ownDamagePremium.value,
          this.f.thirdPartyPremium.value,
          this.f.netPremium.value,
          this.f.ServiceTax.value,
          this.f.grossPremium.value
        ]
      )
        .subscribe(success => {
          if (success) {
            if (this.leadId != null) {
              this.router.navigate(['/lead/list']);
              return;
            }
            this.successAlert = true;
            this.failedAlert = false;
            this.submitted = false;
            // this.message = 'Lead created successfully!'
            Swal.fire(
              'Good Job!',
              'Successfully created the leads',
              'success')
            this.createLeadForm.reset();
            // this.createLeadForm.get('gender').setValue('default');
            window.location.reload();
          }
        },
          error => {
            //alert(error.error.error_msg);
            this.successAlert = false;
            this.failedAlert = true;
            this.message = 'Something went wrong while creating entry';
          });
    } else
      if (!this.createLeadForm.invalid && this.leadId != null) {
        let updateLeadData = {
          product: this.dptProductName,
          vehicle_category: this.f.vehicleCategory.value,
          policy_holder_name: this.f.ownerName.value,
          mobile: this.f.ownerMobileNo.value,
          email: this.f.ownerEmailId.value,
          dob: this.f.DateOfBirth.value,
          gender: this.f.Gender.value,
          nomiee_details: this.f.NomineeDetails.value,
          address: this.f.ownerAddress.value,
          pan_number: this.f.PanNumber.value,
          gst_number: this.f.GstNumber.value,
          loan_provider: this.f.vehicleLoanProvider.value,
          transaction_id: this.f.paymentDetails.value,
          policy_number: this.f.policyNumber.value,
          insurance_company_name: this.f.insuranceCompany.value,
          policy_document_url: this.f.PreviousePolicyDocumentUrl.value,
          updated_policy_document: this.f.CurrentPolicyDocumentUrl.value,
          status: this.f.Status.value,
          start_date: this.f.riskStartdate.value,
          end_date: this.f.riskEnddate.value,
          coverage_type: this.f.CoverageType.value,
          policy_type: this.f.policyType.value,
          idv: this.f.InsuranceIDV.value,
          ncb: this.f.InsuranceNCB.value,
          reg_number: this.f.vehicleRegistrationNumber.value,
          reg_date: this.f.issueDateon.value,
          rank_year: this.f.vehicleRankYear.value,
          rto_code: this.f.vehicleRTOCode.value,
          rto_city: this.f.vehicleRTOCity.value,
          rto_state: this.f.vehicleRTOState.value,
          make: this.f.vehicleMake.value,
          model: this.f.vehicleModel.value,
          variant: this.f.vehicleVariant.value,
          fuel_type: this.f.vehicleFuelType.value,
          engine_cc: this.f.vehicleEngineCC.value,
          chassis_number: this.f.vehicleChasisNumber.value,
          engine_number: this.f.vehicleEngineNumber.value,
          seating_capacity: this.f.vehicleSeatingCapacity.value,
          od_premium: this.f.ownDamagePremium.value,
          tp_premium: this.f.thirdPartyPremium.value,
          net_premium: this.f.netPremium.value,
          service_tax: this.f.ServiceTax.value,
          gross_premium: this.f.grossPremium.value
        }

        if (this.currentRole == 1) {
          let currentUser = this.userList.filter(ele => ele.mobile == this.bqpNumber);
          if (currentUser[0].role == 3) {
            updateLeadData["quotation_agent"] = this.bqpNumber;
          } else {
            updateLeadData["agent"] = this.bqpNumber;
            updateLeadData["quotation_agent"] = "";
          }
        }
        this.backendCall.updateLead(
          {
            updateLeadData,
            id: this.leadId

          }
        )
          .subscribe(success => {
            if (success) {
              if (this.leadId != null) {
                Swal.fire(
                  'Good Job!',
                  'Updated the entry Successfully',
                  'success')
                this.router.navigate(['/lead/list']);
                return;
              }
            }
          },
            error => {
              //alert(error.error.error_msg);
              this.successAlert = false;
              this.failedAlert = true;
              this.message = 'Something went wrong while Updating entry';
            });
      }
  }

  getLeadDetailsvd() {
    this.backendCall.getLead(
      {
        id: this.leadId
      }
    )
      .subscribe(success => {
        if (success) {
          if (success.data.leadDetails.length) {
            // Vehicel Data
            this.f.vehicleCategory.setValue(success.data.leadDetails[0].vehicle_category);
            this.f.vehicleLoanProvider.setValue(success.data.leadDetails[0].loan_provider);
            this.f.vehicleRegistrationNumber.setValue(success.data.leadDetails[0].reg_number);
            this.f.issueDateon.setValue(success.data.leadDetails[0].reg_date);
            this.f.vehicleRankYear.setValue(success.data.leadDetails[0].rank_year);
            this.f.vehicleRTOCode.setValue(success.data.leadDetails[0].rto_code);
            this.f.vehicleRTOCity.setValue(success.data.leadDetails[0].rto_city);
            this.f.vehicleRTOState.setValue(success.data.leadDetails[0].rto_state);
            this.f.vehicleMake.setValue(success.data.leadDetails[0].make);
            console.log(this.f.vehicleMake);
            this.f.vehicleModel.setValue(success.data.leadDetails[0].model);
            this.f.vehicleVariant.setValue(success.data.leadDetails[0].variant);
            this.f.vehicleFuelType.setValue(success.data.leadDetails[0].fuel_type);
            this.f.vehicleEngineCC.setValue(success.data.leadDetails[0].engine_cc);
            this.f.vehicleChasisNumber.setValue(success.data.leadDetails[0].chassis_number);
            this.f.vehicleEngineNumber.setValue(success.data.leadDetails[0].engine_number);
            this.f.vehicleSeatingCapacity.setValue(success.data.leadDetails[0].seating_capacity);
            this.dptProductName = success.data.leadDetails[0].product;
          }
          console.log(this.createLeadForm);
        }
      },
        error => {
          //alert(error.error.error_msg);
          this.successAlert = false;
          this.failedAlert = true;
          this.message = 'Something went wrong while Fetching entry for update';
        });
  }

  getLeadDetailsinsd() {
    this.backendCall.getLead(
      {
        id: this.leadId
      }
    )
      .subscribe(success => {
        if (success) {
          if (success.data.leadDetails.length) {
            // Insurance Data
            this.f.CoverageType.setValue(success.data.leadDetails[0].coverage_type);
            this.f.policyType.setValue(success.data.leadDetails[0].policy_type);
            this.f.InsuranceIDV.setValue(success.data.leadDetails[0].idv);
            this.f.InsuranceNCB.setValue(success.data.leadDetails[0].ncb);
            this.f.policyNumber.setValue(success.data.leadDetai3ls[0].policy_number);
            this.f.insuranceCompany.setValue(success.data.leadDetails[0].insurance_company_name);
            this.f.riskStartdate.setValue(success.data.leadDetails[0].start_date);
            this.f.riskEnddate.setValue(success.data.leadDetails[0].end_date);
            this.f.PreviousePolicyDocumentUrl.setValue(success.data.leadDetails[0].policy_document_url);
            this.f.CurrentPolicyDocumentUrl.setValue(success.data.leadDetails[0].updated_policy_document);
            this.f.Status.setValue(success.data.leadDetails[0].status);

            // Premium Details
            this.f.ownDamagePremium.setValue(success.data.leadDetails[0].od_premium);
            this.f.thirdPartyPremium.setValue(success.data.leadDetails[0].tp_premium);
            this.f.netPremium.setValue(success.data.leadDetails[0].net_premium);
            this.f.ServiceTax.setValue(success.data.leadDetails[0].service_tax);
            this.f.grossPremium.setValue(success.data.leadDetails[0].gross_premium);

            // Transaction Details
            this.f.paymentDetails.setValue(success.data.leadDetails[0].transaction_id)
          }
          console.log(this.createLeadForm);
        }
      },
        error => {
          this.successAlert = false;
          this.failedAlert = true;
          this.message = 'Something went wrong while Fetching entry for update';
        });
  }


  getLeadDetailsd() {
    this.backendCall.getLead(
      {
        id: this.leadId
      }
    )
      .subscribe(success => {
        if (success) {

          if (success.data.leadDetails.length) {
            // Owner Details
            this.f.ownerName.setValue(success.data.leadDetails[0].policy_holder_name);
            this.f.ownerMobileNo.setValue(success.data.leadDetails[0].mobile);
            this.f.ownerEmailId.setValue(success.data.leadDetails[0].email);
            this.f.DateOfBirth.setValue(success.data.leadDetails[0].dob);
            this.f.Gender.setValue(success.data.leadDetails[0].gender);
            this.f.NomineeDetails.setValue(success.data.leadDetails[0].nomiee_details);
            this.f.ownerAddress.setValue(success.data.leadDetails[0].address);
            this.f.PanNumber.setValue(success.data.leadDetails[0].pan_number);
            this.f.GstNumber.setValue(success.data.leadDetails[0].gst_number);
          }

          if (success.data.userList.length) {
            let finalArr = [];
            this.userList = success.data.userList;
            success.data.userList.forEach(ele => {
              finalArr.push({ item_id: Number(ele.mobile), item_text: ele.first_name + " " + ele.last_name })
            });
            console.log(success.data.leadDetails[0])
            this.f.saleBqp.setValue(success.data.leadDetails[0].current_agent_name);
            this.bqpNumber = success.data.leadDetails[0].current_agent_id


            this.agentData = finalArr;
          }
          console.log(this.createLeadForm);
        }
      },
        error => {
          //alert(error.error.error_msg);
          this.successAlert = false;
          this.failedAlert = true;
          this.message = 'Something went wrong while Fetching entry for update';
        });
  }

  showList(idName, code) {

    if (idName == "vehicleTypeList") {
      console.log(code);
      let tempArry = []
      for (let i = 0; i < this.dropdownsObj["vehicleType"].length; i++) {
        if (this.dropdownsObj["vehicleType"][i].productCode == code) {
          tempArry.push(this.dropdownsObj["vehicleType"][i])
        }
      }
      this.customVehicleType = tempArry
      console.log(this.dropdownsObj.vehicleType);

    }


    if (document.getElementById(idName).style.display == "none") {
      document.getElementById(idName).style.display = "block";
    }
    else if (document.getElementById(idName).style.display == "block") {
      document.getElementById(idName).style.display = "none";
    }
    else {
      document.getElementById(idName).style.display = "block";
    }


  }

  show(anything: string, dataID, idName, textId) {
    console.log('calling show here', idName)
    console.log(anything);
    console.log(dataID);
    console.log(textId);



    switch (idName) {

      case "bqplist":
        this.dropDownsSelectedValue[idName] = anything
        this.bqpNumber = dataID
        break;
      case "makelist":
        this.dropDownsSelectedValue[idName] = anything
        this.dropDownsSelectedValue["modellist"] = ""
        this.fetchModelName(dataID)
        break;
      case "modellist":
        this.dropDownsSelectedValue[idName] = anything
        break;
      case "vehicleTypeList":
        this.dropDownsSelectedValue[idName] = anything
        break;
      case "fueltypelist":
        this.dropDownsSelectedValue[idName] = anything
        break;
      case "seatingCapacitylist":
        this.dropDownsSelectedValue[idName] = anything
        break;
      case "GVWlist":
        this.dropDownsSelectedValue[idName] = anything
        break;
      case "manufacturYearlist":
        this.dropDownsSelectedValue[idName] = anything
        break;
      case "insurancelist":
        this.dropDownsSelectedValue[idName] = anything
        break;
      case "buisnesslist":
        this.dropDownsSelectedValue[idName] = anything
        break;
      case "policylist":
        this.selectedPolicyType = anything
        this.dropDownsSelectedValue[idName] = anything
        break;
      case "paymentlist":
        this.dropDownsSelectedValue[idName] = anything
        break;

      default:
        break;
    }
    document.getElementById(idName).style.display = "none";
  }

  // Manul Entry Forms Submissons

  // Sales Entry
  SaleDetails(form: NgForm) {
    if (this.ProductName == "Car") {
      this.dropdownsObj.paymentname = this.paymentMode
    }
    this.formEntryDatas = form.value
    if (this.formEntryDatas["saleBranch"] == "" || this.formEntryDatas["saleDate"] == "" || this.formEntryDatas["saleBqp"] == "" ||
      this.formEntryDatas["saleRefered"] == "" ||
      this.formEntryDatas["saleBranch"] == undefined || this.formEntryDatas["saleDate"] == undefined || this.formEntryDatas["saleBqp"] == undefined ||
      this.formEntryDatas["saleRefered"] == undefined
    ) {
      Swal.fire(
        'Sorry!',
        'Please fill all the fields in sale details!',
        'error'
      )

    } else {

      this.openedTrance = "TransactionDetails"
      this.editSlotsObj.isTransactionDetails = 1

    }
    console.log(this.formEntryDatas);
    console.log(this.openedTrance);

  }

  // Owner Entry
  ownerDetails(form: NgForm) {
    this.formEntryDatas = form.value
    // console.log(this.formEntryDatas);
    if (this.formEntryDatas["ownerName"] == "" || this.formEntryDatas["ownerMobileNo"] == "" || this.formEntryDatas["ownerEmailId"] == "" ||
      this.formEntryDatas["ownerAddress"] == "" || this.formEntryDatas["PanNumber"] == "" || this.formEntryDatas["GstNumber"] == "" ||
      this.formEntryDatas["DateOfBirth"] == "" || this.formEntryDatas["Gender"] == "" || this.formEntryDatas["NomineeDetails"] == "" ||
      this.formEntryDatas["ownerName"] == undefined || this.formEntryDatas["ownerMobileNo"] == undefined || this.formEntryDatas["ownerEmailId"] == undefined ||
      this.formEntryDatas["ownerAddress"] == undefined || this.formEntryDatas["PanNumber"] == undefined || this.formEntryDatas["GstNumber"] == undefined ||
      this.formEntryDatas["DateOfBirth"] == undefined || this.formEntryDatas["Gender"] == undefined || this.formEntryDatas["NomineeDetails"] == undefined
    ) {
      Swal.fire(
        'Sorry!',
        'Please fill all the fields in Owner details!',
        'error'
      )


    } else {
      this.editSlotsObj.isVehicleDetailsEdit = 1
      this.submit()
    }
    console.log(this.bqpNumber);
    console.log(this.createLeadForm);
    console.log(this.formEntryDatas);
  }

  // Vehicle Entry
  vehicleDetails(form: NgForm) {
    this.formEntryDatas = form.value;
    if (this.formEntryDatas["vehicleRegistrationNumber"] == "" || this.formEntryDatas["vehicleManufactureYear"] == "" || this.formEntryDatas["vehicleMake"] == "" ||
      this.formEntryDatas["vehicleModel"] == "" || this.formEntryDatas["vehicleEngineNumber"] == "" || this.formEntryDatas["vehicleChasisNumber"] == "" ||
      this.formEntryDatas["vehicleFuelType"] == "" || this.formEntryDatas["vehicleType"] == "" || this.formEntryDatas["vehicleSeatingCapacity"] == "" ||
      this.formEntryDatas["vehicleGCV"] == "" || this.formEntryDatas["vehicleCubicCapacity"] == "" || this.formEntryDatas["vehicleVariant"] == "" ||
      this.formEntryDatas["vehicleCategory"] == "" || this.formEntryDatas["vehicleLoanProvider"] == "" || this.formEntryDatas["vehicleRankYear"] == "" ||
      this.formEntryDatas["vehicleRTOCode"] == "" || this.formEntryDatas["vehicleRTOCity"] == "" || this.formEntryDatas["vehicleRTOState"] == "" ||
      this.formEntryDatas["vehicleEngineCC"] == "" || this.formEntryDatas["issueDateon"] == "" ||
      this.formEntryDatas["vehicleRegistrationNumber"] == undefined || this.formEntryDatas["vehicleManufactureYear"] == undefined || this.formEntryDatas["vehicleMake"] == undefined ||
      this.formEntryDatas["vehicleModel"] == undefined || this.formEntryDatas["vehicleEngineNumber"] == undefined || this.formEntryDatas["vehicleEngineCC"] == undefined ||
      this.formEntryDatas["vehicleChasisNumber"] == undefined || this.formEntryDatas["vehicleFuelType"] == undefined || this.formEntryDatas["vehicleType"] == undefined ||
      this.formEntryDatas["vehicleSeatingCapacity"] == undefined || this.formEntryDatas["vehicleGCV"] == undefined || this.formEntryDatas["vehicleCubicCapacity"] == undefined ||
      this.formEntryDatas["vehicleVariant"] == undefined || this.formEntryDatas["vehicleCategory"] == undefined || this.formEntryDatas["vehicleLoanProvider"] == undefined ||
      this.formEntryDatas["vehicleRankYear"] == undefined || this.formEntryDatas["vehicleRTOCode"] == undefined || this.formEntryDatas["vehicleRTOCity"] == undefined ||
      this.formEntryDatas["vehicleRTOState"] == undefined || this.formEntryDatas["issueDateon"] == undefined
    ) {
      Swal.fire(
        'Sorry!',
        'Please fill all the fields in Vehicle details!',
        'error'
      )

    } else {
      if (this.ProductName == "Car" && this.leadId != null && this.vd == 1) {
        this.getLeadDetailsinsd()
        console.log("api");

      }
      this.vd = 2

      this.openedTrance = "InsuranceDetails"
      this.formEntryDatas["ownDamagePremium"] = ""
      this.formEntryDatas["thirdPartyPremium"] = ""
      this.editSlotsObj.isInsuranceDetailsEdit = 1
    }
    console.log(this.formEntryDatas);
    console.log(this.createLeadForm);
  }

  // Insurance Entry
  insuranceDetails(form: NgForm) {
    this.formEntryDatas = form.value
    if (this.formEntryDatas["addOns"] == "" || this.formEntryDatas["businesstype"] == "" || this.formEntryDatas["insuranceCompany"] == "" ||
      this.formEntryDatas["issueDateon"] == "" || this.formEntryDatas["policyNumber"] == "" || this.formEntryDatas["policyType"] == "" ||
      this.formEntryDatas["riskEnddate"] == "" || this.formEntryDatas["riskStartdate"] == "" || this.formEntryDatas["InsuranceIDV"] == "" ||
      this.formEntryDatas["InsuranceNCB"] == "" || this.formEntryDatas["CoverageType"] == "" || this.formEntryDatas["PreviouseInsurerName"] == "" ||
      this.formEntryDatas["PreviouseInsuranceNumber"] == "" ||
      this.formEntryDatas["addOns"] == undefined || this.formEntryDatas["businesstype"] == undefined || this.formEntryDatas["insuranceCompany"] == undefined ||
      this.formEntryDatas["issueDateon"] == undefined || this.formEntryDatas["policyNumber"] == undefined || this.formEntryDatas["policyType"] == undefined ||
      this.formEntryDatas["riskEnddate"] == undefined || this.formEntryDatas["riskStartdate"] == undefined || this.formEntryDatas["InsuranceIDV"] == undefined ||
      this.formEntryDatas["InsuranceNCB"] == undefined || this.formEntryDatas["CoverageType"] == undefined || this.formEntryDatas["PreviouseInsurerName"] == undefined ||
      this.formEntryDatas["PreviouseInsuranceNumber"] == undefined
    ) {

      Swal.fire(
        'Sorry!',
        'Please fill all the fields in Insurance details!',
        'error'
      )

    } else {
      this.openedTrance = "PremiumDetails"
      this.editSlotsObj.isPremiumDetailsEdit = 1
    }
    console.log(this.formEntryDatas);
  }

  // Premium Entry
  premiumDetails(form: NgForm) {
    this.formEntryDatas = form.value
    if (this.formEntryDatas["ownDamagePremium"] == "" || this.formEntryDatas["thirdPartyPremium"] == "" || this.formEntryDatas["netPremium"] == "" ||
      this.formEntryDatas["grossPremium"] == "" || this.formEntryDatas["ServiceTax"] == "" ||
      this.formEntryDatas["ownDamagePremium"] == undefined || this.formEntryDatas["thirdPartyPremium"] == undefined || this.formEntryDatas["netPremium"] == undefined ||
      this.formEntryDatas["grossPremium"] == undefined || this.formEntryDatas["ServiceTax"] == undefined
    ) {

      Swal.fire(
        'Sorry!',
        'Please fill all the fields in Premium details!',
        'error'
      )

    } else {
      if (this.ProductName == "Car" && this.leadId != null && this.pd == 1) {
        this.dropdownsObj.branchName = this.cities
        this.getLeadDetailsd()
      }
      this.pd = 2
      this.openedTrance = "SaleDetails"
      this.editSlotsObj.isSaleDetailsEdit = 1
    }
    console.log(this.formEntryDatas);
  }

  // Transaction Entry
  transactionDetails(form: NgForm) {
    this.formEntryDatas = form.value
    if (this.formEntryDatas["paymentMode"] == "" || this.formEntryDatas["paymentDetails"] == "" || this.formEntryDatas["CashBack"] == "" ||
      this.formEntryDatas["Commission"] == "" ||
      this.formEntryDatas["paymentMode"] == undefined || this.formEntryDatas["paymentDetails"] == undefined || this.formEntryDatas["CashBack"] == undefined ||
      this.formEntryDatas["Commission"] == undefined
    ) {

      Swal.fire(
        'Sorry!',
        'Please fill all the fields in Transaction details!',
        'error'
      )

    } else {
      this.openedTrance = "OwnerDetails"
      this.editSlotsObj.isOwnerDetailsEdit = 1
    }
    console.log(this.formEntryDatas);
  }

  onSubmitForm(idName) {
    this.openedTrance = idName
    // this.getLeadDetails()
    console.log(this.openedTrance);

  }

  showMotorProducts(dptName) {
    this.selectedDpt = dptName;
    console.log(this.selectedDpt);
    document.getElementById("motor_entry").style.display = "none";
    document.getElementById("manual_dpt").style.display = "none";
    document.getElementById("goBackToDpt").style.display = "block";
    document.getElementById("motor_products").style.display = "flex";
    console.log(this.createLeadForm);
  }

  showNonMotorProducts() {
    document.getElementById("non_motor_entry").style.display = "none";
    document.getElementById("non_motor_products").style.display = "flex";
    document.getElementById("manual_dpt").style.display = "none";
    document.getElementById("goBackToNMProd").style.display = "none";
    document.getElementById("goBackToDepartment").style.display = "block";
    document.getElementById("motor_products").style.display = "none";
    console.log("motor");
  }

  showNonMotorEntry(nonMoterProductname, nonMoterCode) {
    document.getElementById("motor_entry").style.display = "block";
    document.getElementById("non_motor_products").style.display = "none";
    document.getElementById("goBackToDepartment").style.display = "none";
    document.getElementById("goBackToNMProd").style.display = "block";
    document.getElementById("dontShowInNm").style.display = "none"
    document.getElementById("nmInsurance3").innerHTML = "3"
    document.getElementById("nmPremium4").innerHTML = "4"
    document.getElementById("nmTransaction5").innerHTML = "5"

    // document.getElementById("non_motor_entry").style.display = "block";
    this.ProductName = nonMoterProductname
    this.productCode = nonMoterCode
    console.log(this.createLeadForm);
  }

  goBackToNMProd() {
    document.getElementById("goBackToDepartment").style.display = "block";
    document.getElementById("goBackToNMProd").style.display = "none";
    document.getElementById("non_motor_products").style.display = "flex";
    document.getElementById("non_motor_entry").style.display = "none";
    document.getElementById("motor_entry").style.display = "none";
    this.formEntryDatas = [
      { "saleBranch": "", "saleDate": "", "saleBqp": "", "saleRefered": '' },
      {
        "ownerName": "", "ownerMobileNo": "", "ownerEmailId": "", "ownerAddress": "", "PanNumber": "",
        "GstNumber": "", "DateOfBirth": "", "Gender": "", "NomineeDetails": ""
      },
      {
        "vehicleRegistrationNumber": "", "vehicleManufactureYear": "", "vehicleMake": "", "vehicleModel": "",
        "vehicleEngineNumber": "", "vehicleChasisNumber": "", "vehicleFuelType": "", "vehicleType": "",
        "vehicleSeatingCapacity": "", "vehicleGCV": "", "vehicleCubicCapacity": "",
        "vehicleVariant": "", "vehicleCategory": "", "vehicleLoanProvider": "", "vehicleRankYear": "",
        "vehicleRTOCode": "", "vehicleRTOCity": "", "vehicleRTOState": "", "vehicleEngineCC": ""
      },
      {
        "addOns": "", "businesstype": "", "insuranceCompany": "", "issueDateon": "", "policyNumber": "", "policyType": "",
        "riskEnddate": "", "riskStartdate": "", "CoverageType": "", "PreviouseInsurerName": "", "PreviouseInsuranceNumber": "",
        "InsuranceIDV": "", "InsuranceNCB": "", "PreviousePolicyDocumentUrl": "", "CurrentPolicyDocumentUrl": "", "Status": ""
      },
      { "ownDamagePremium": '', "thirdPartyPremium": '', "netPremium": '', "grossPremium": '', "ServiceTax": "" },
      { "paymentMode": '', "paymentDetails": '', "CashBack": "", "Commission": '' }
    ]
    if (this.selectedDpt == "MOTOR") {
      this.openedTrance = "VehicleDetails"
    } else {
      this.openedTrance = "InsuranceDetails"
    }
  }

  backToDpt() {
    document.getElementById("manual_dpt").style.display = "block";
    document.getElementById("non_motor_products").style.display = "none";
    document.getElementById("goBackToDepartment").style.display = "none";
    document.getElementById("goBackToNMProd").style.display = "none";
  }

  goBackToProd() {
    document.getElementById("manual_dpt").style.display = "none";
    document.getElementById("motor_entry").style.display = "none";
    document.getElementById("non_motor_entry").style.display = "none";
    document.getElementById("goBackToDpt").style.display = "block";
    document.getElementById("goBackToProd").style.display = "none";
    document.getElementById("motor_products").style.display = "flex";
    this.formEntryDatas = [
      { "saleBranch": "", "saleDate": "", "saleBqp": "", "saleRefered": '' },
      {
        "ownerName": "", "ownerMobileNo": "", "ownerEmailId": "", "ownerAddress": "", "PanNumber": "",
        "GstNumber": "", "DateOfBirth": "", "Gender": "", "NomineeDetails": ""
      },
      {
        "vehicleRegistrationNumber": "", "vehicleManufactureYear": "", "vehicleMake": "", "vehicleModel": "",
        "vehicleEngineNumber": "", "vehicleChasisNumber": "", "vehicleFuelType": "", "vehicleType": "",
        "vehicleSeatingCapacity": "", "vehicleGCV": "", "vehicleCubicCapacity": "",
        "vehicleVariant": "", "vehicleCategory": "", "vehicleLoanProvider": "", "vehicleRankYear": "",
        "vehicleRTOCode": "", "vehicleRTOCity": "", "vehicleRTOState": "", "vehicleEngineCC": ""
      },
      {
        "addOns": "", "businesstype": "", "insuranceCompany": "", "issueDateon": "", "policyNumber": "", "policyType": "",
        "riskEnddate": "", "riskStartdate": "", "CoverageType": "", "PreviouseInsurerName": "", "PreviouseInsuranceNumber": "",
        "InsuranceIDV": "", "InsuranceNCB": "", "PreviousePolicyDocumentUrl": "", "CurrentPolicyDocumentUrl": "", "Status": ""
      },
      { "ownDamagePremium": '', "thirdPartyPremium": '', "netPremium": '', "grossPremium": '', "ServiceTax": "" },
      { "paymentMode": '', "paymentDetails": '', "CashBack": "", "Commission": '' }
    ]
    // this.dropDownsSelectedValue = {}
    // this.openedTrance = "SaleDetails"
    if (this.selectedDpt == "MOTOR") {
      this.openedTrance = "VehicleDetails"
    } else {
      this.openedTrance = "InsuranceDetails"
    }
  }

  back() {
    document.getElementById("motor_products").style.display = "none";
    document.getElementById("goBackToDpt").style.display = "none";
    document.getElementById("manual_dpt").style.display = "block";
  }

  showMotorEntry(productname, code) {
    // this.getLeadDetails();
    document.getElementById("motor_entry").style.display = "block";
    document.getElementById("goBackToProd").style.display = "block";
    document.getElementById("motor_products").style.display = "none";
    document.getElementById("goBackToDpt").style.display = "none";
    this.ProductName = productname
    this.productCode = code
    this.dptProductName = this.selectedDpt + " " + this.ProductName
    console.log(this.dptProductName);

    if (this.ProductName == "Car") {
      this.fetchCarName()
      this.getInsPolAddDropDowns()
      this.dropdownsObj.businessname = this.branchType
      console.log(this.createLeadForm);
      if (this.leadId != null) {
        this.getLeadDetailsvd()
      }
    }

  }

  changeBtnVal() {

  }

  scrollWin(formStage: any) {

    if (formStage === 'vehicle') {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else if (formStage === 'ins_details') {
      window.scrollTo({ top: 300, behavior: 'smooth' })
    } else if (formStage === 'premium') {
      window.scrollTo({ top: 600, behavior: 'smooth' })
    } else if (formStage === 'sale') {
      window.scrollTo({ top: 900, behavior: 'smooth' })
    } else if (formStage === 'transaction') {
      window.scrollTo({ top: 1200, behavior: 'smooth' })
    } else if (formStage === 'owner') {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

  }

  addOnSelection(item) {
    this.selectedAddOnsArry.push(item.name)
    console.log(this.selectedAddOnsArry);
  }

  deSelectAddOns(item) {
    for (let i = 0; i < this.selectedAddOnsArry.length; i++) {
      if (this.selectedAddOnsArry[i] == item.name) {
        this.selectedAddOnsArry.splice(i, 1)
      }
    }
    console.log(this.selectedAddOnsArry);
  }

  selectAllAddOns(item) {

    this.selectedAddOnsArry = []
    for (let i = 0; i < item.length; i++) {
      this.selectedAddOnsArry.push(item[i].name)
    }
    console.log(this.selectedAddOnsArry);
  }

  deSelectAllAddOns() {
    // this.quoteReqObj.addOns = ""
    this.selectedAddOnsArry = []
    console.log(this.selectedAddOnsArry, "zero");
  }

  getformattedDate() {

    this.transformDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');

  }

  fetchEvent(value) {
    alert(value);

  }
}