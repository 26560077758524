import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartDB } from '../../fack-db/chart-data';
import { ApexChartService } from '../../theme/shared/components/chart/apex-chart/apex-chart.service';
import { BackendCallService } from '../../services/backend-call.service'
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-ticket-overview',
  templateUrl: './ticket-overview.component.html',
  styleUrls: ['./ticket-overview.component.scss']
})
export class TicketOverviewComponent implements OnInit {

  @ViewChild('dateForm', { static: false }) form: NgForm;



  public chartDB: any;
  public dailyVisitorStatus: string;
  public dailyVisitorAxis: any;
  public deviceProgressBar: any;
  public role: any;
  public count: any;
  showFilter = false
  isSearched = false;
  fromDate: any;
  todate: any;
  fsDate: string;
  tldate: string;

  constructor(public apexEvent: ApexChartService, private backendCall: BackendCallService) {
    this.chartDB = ChartDB;
    this.dailyVisitorStatus = '1y';

    this.deviceProgressBar = [
      {
        type: 'success',
        value: 66
      }, {
        type: 'primary',
        value: 26
      }, {
        type: 'danger',
        value: 8
      }
    ];

    this.role = localStorage.getItem('role');
    this.fsDate = localStorage.getItem('fromDate');
    this.tldate = localStorage.getItem('todate');
    // console.log(localStorage.getItem('role'), "jjjjjj");
    // console.log(localStorage.getItem('fromDate'), "kkkkkk");
    // console.log(localStorage.getItem('fromDate'), "llllll");
  }



  public ngOnInit(): void {
    localStorage.removeItem("searchData");
    localStorage.removeItem("filterData");
    if (this.fsDate && this.tldate != null) {
      this.fromDate = this.fsDate
      this.todate = this.tldate
      this.isSearched = true
    }
    this.getCountRoleWise();


  }

  public getCountRoleWise(): any {
    this.backendCall.getLeadCount(this.fromDate, this.todate).subscribe(Response => {
      if (Response) {
        this.count = Response.data[0];

      }
    },
      error => {
        alert(error.error.error_msg);
      });
  }

  openNav() {
    this.showFilter = !this.showFilter;
  }

  closeNav() {
    this.showFilter = !this.showFilter;

  }

  dateSubmit(form: NgForm) {

    if (this.fromDate && !this.todate) {
      alert('Add Date Range Properly')
    } else {
      this.isSearched = true
      this.showFilter = !this.showFilter;
      // console.log("aarreeeeeeee",form.value);
      // this.fromDate = form.value.InwardFromDate;
      // this.todate = form.value.InwardToDate;
      // localStorage.setItem('searchData', JSON.stringify(this.formValue));
      localStorage.setItem('fromDate', form.value.InwardFromDate);
      localStorage.setItem('todate', form.value.InwardToDate);
      // console.log(this.fromDate,this.todate);
      this.getCountRoleWise();
    }
  }

  setFromDate(date: any) {
    this.fromDate = date.target.value;
    // console.log(this.fromDate);

  }

  setToDate(date: any) {
    this.todate = date.target.value;
    // console.log(this.todate);
  }

  resetBtn() {
    this.isSearched = false
    localStorage.removeItem("fromDate");
    localStorage.removeItem("todate");
    this.fromDate = ""
    this.todate = ""
    // this.form.resetForm();
    this.getCountRoleWise();
  }
}

