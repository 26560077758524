import { Component, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-track-dispositon',
  templateUrl: './track-dispositon.component.html',
  styleUrls: ['./track-dispositon.component.scss']
})
export class TrackDispositonComponent implements OnInit {

  date = [];
  dropdownSettings: IDropdownSettings = {};
  selectedDate: any;
  myDate = Date.now();
  searchBar = false
  approved = true
  reject = false
  pending = false
  @ViewChild('history', { static: true }) activities: { show: () => void; };



  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.date = [
      "11/10/1000",
      "12/10/1000",
      "13/10/1000",
      "14/10/1000",
    ]

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  onDateSelect(item:any){
    this.selectedDate = item
  }

  backBtnTwo(){
    this.router.navigate(['tracking/agent/hourly']);
  }

  openNav() {
    document.getElementById("mySidebar").style.display = "block"
  }

  openSearchBox() {
    let table = document.getElementById('trackDispositionContainer')
    table.style.position = 'absolute';
    table.style.top = '11.34rem'
    this.searchBar = true
  }

  closeSearchBox(){
    let table = document.getElementById('trackDispositionContainer')
    table.style.position = 'absolute';
    table.style.top = '60px'
    this.searchBar = false;
  }

  closeFilter(){
    document.getElementById("mySidebar").style.display = "none"
  }

  viewHistory() {
		this.activities.show();
	}

}
