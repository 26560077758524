import { map } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Location, formatDate } from '@angular/common';
import { BackendCallService } from 'src/app/services/backend-call.service';
import * as angular from 'angular';
import { Pipe, PipeTransform } from "@angular/core";
import { orderBy } from 'lodash';
import { NgForm } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-offline-quote',
  templateUrl: './offline-quote.component.html',
  styleUrls: ['./offline-quote.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateY(0)',
        opacity: 1
      })),
      transition(':enter', [
        style({
          transform: 'translateY(100%)',
          opacity: 0
        }),
        animate('600ms ease-in')
      ]),
      transition(':leave', [
        animate('600ms ease-in', style({
          transform: 'translateY(-100%)',
          opacity: 0
        }))
      ])
    ])
  ]
})
export class OfflineQuoteComponent implements OnInit {

  dynamicHeaders = [
    'INSURER',
    'PLAN TYPE',
    'PREMIUM',
    'QUOTE',
    'ACTION'
  ];

  [x: string]: any;

  // MODAL DECLARATIONS

  @ViewChild('response', { static: true }) apiResponse: { show: () => void; };
  @ViewChild('social', { static: true }) sendSocial: { show: () => void; };
  @ViewChild('reqInsurer', { static: false }) form: NgForm;

  // VARIABLES FOR ERROR

  nationalError = false;
  unitedError = false;
  futureError = false;
  royalError = false;
  sompoError = false;
  isDigitError = false;
  isTataError = false;
  isBajajError = false;
  isRelianceError = false;
  isHdfcError = false;
  iciciError = false;

  time: number = 0;
  display: string | number;
  interval: NodeJS.Timer;

  responseArray = []
  sortedArray: any[] = [];

  totalResponse: any;
  selectedCompany: number = 0;
  product: any;
  productSection = true;
  businessTypeSection = false;
  RegNumberSection = false;
  formSection = false;
  formSectionOne = false;
  formSectionTwo = false;
  responseTable = false;
  revealCheckbox = false;
  selectedBusinessType: any;
  getClaimStatus: any;
  dropdownListForNcb = [];
  dropdownListForAddons = [];
  dropdownListForPYP = [];
  dropdownListForReqIns = [];
  dropdownListForReqInsBIke = [];
  dropdownListForPolType = [];
  dropdownListForFuelType = [];
  selectedAddons = [];
  dropdownSettingsNcb = {};
  dropdownSettingsAddons = {};
  dropdownSettingsPYP = {};
  dropdownSettingsPolType = {};
  component: any;
  selectedFuelType: any;
  selectedNcb: any;
  ncbNumber: any;
  selectedPYP: any;
  getCngSource: any;
  regNumber: any;
  getcarDtails: any;
  getBikeDetails: any;
  clientNameTitle: any;
  getmakemodel: any;
  make: any;
  model: any;
  variant: any;
  cc: any;
  fueltype: any;
  seatingcapacity: any;
  rto: any;
  registerdate: any;
  mftyear: any;
  expirydate: any;
  idv: any;
  addonsArray = [];
  reqInsurerArray = [];
  getquotation: any;
  pincode: any;
  clientName: any;

  // loader for retry

  nationalRetryLoader = false
  royalRetryLoader = false
  sompoRetryLoader = false
  tataRetryLoader = false
  digitRetryLoader = false
  iciciRetryLoader = false
  hdfcRetryLoader = false
  relianceRetryLoader = false
  bajajRetryLoader = false
  unitedRetryLoader = false
  futureRetryLoader = false

  // requested isnurer variables
  isTata = false
  qouteofnational: any;
  totalpremiumnational: any;
  qouteofunited: any;
  totalpremiumunited: any;
  qouteoffuture: any;
  totalpremiumfuture: any;
  qouteoficici: any;
  totalpremiumicici: any;
  qouteofroyal: any;
  totalpremiumroyal: any;
  qouteofrahejaqbe: any;
  totalpremiumrahejaqbe: any;
  qouteofsompo: any;
  totalpremiumsompo: any;
  qouteofdigit: any;
  totalpremiumdigit: any;
  qouteoftataaig: any;
  totalpremiumtataaig: any;
  qouteofbajaj: any;
  totalpremiumbajaj: any;
  qouteofreliance: any;
  totalpremiumreliance: any;
  qouteofhdfc: any;
  totalpremiumhdfc: any;

  // FINAL TIME FOR COMPANIES SHOWING

  fetchedTimeNational: any
  fetchedTimeFuture: any
  fetchedTimeUnited: any
  pausedTimeNational: any;

  //coremmv
  coremake: any;
  coremodel: any;
  corevariant: any;

  //coremmv array
  dropdownSettingscoremmv: IDropdownSettings = {};
  coremakearray = [];
  coremodelarray = [];
  corevariantarray = [];
  portalcoremmv = [];
  bajajcoremmv: any;
  digitcoremmv: any;
  hdfccoremmv: any;
  icicicoremmv: any;
  nationalcoremmv: any;
  reliancecoremmv: any;
  royalcoremmv: any;
  tatacoremmv: any;
  sompocoremmv: any;

  constructor(private router: Router, private route: ActivatedRoute, private _location: Location, private backendCall: BackendCallService, private currencyPipe: CurrencyPipe) { }

  ngOnInit() {

    this.dropdownListForNcb = [
      { item_id: 1, item_text: '0' },
      { item_id: 2, item_text: '20' },
      { item_id: 3, item_text: '25' },
      { item_id: 4, item_text: '35' },
      { item_id: 5, item_text: '45' },
      { item_id: 6, item_text: '50' }
    ];

    this.dropdownListForAddons = [
      { item_id: 1, item_text: 'Zero Depreciation' },
      { item_id: 2, item_text: 'PA Cover' },
      { item_id: 3, item_text: '24x7 Roadside Assistance' },
      { item_id: 4, item_text: 'Consumable' },
      { item_id: 5, item_text: 'Engine Protector' },
      { item_id: 6, item_text: 'Invoice Return' },
      { item_id: 7, item_text: 'NCB Protector' },
      { item_id: 8, item_text: 'Key and Lock Replacement' },
      { item_id: 9, item_text: 'Driver Cover' },
      { item_id: 10, item_text: 'Loss of Personal Belongings cover' },
      { item_id: 11, item_text: 'Accessories' },
      { item_id: 12, item_text: 'Tyre Protector' },
      { item_id: 13, item_text: 'Passenger Cover' },
      { item_id: 14, item_text: 'Electrical Accessories' },
      { item_id: 15, item_text: 'Non Electrical Accessories' },
      { item_id: 16, item_text: 'Bifuel kit(CNG/LPG)' },
    ];

    this.dropdownListForReqIns = [
      { item_id: 1, item_text: 'Tata AIG' },
      // { item_id: 2, item_text: 'Ifco Tokio' },
      // { item_id: 3, item_text: 'New India' },
      { item_id: 4, item_text: 'National' },
      // { item_id: 5, item_text: 'United India' },
      { item_id: 6, item_text: 'HDFC Ergo' },
      // { item_id: 7, item_text: 'Kotak' },
      { item_id: 8, item_text: 'ICICI' },
      // { item_id: 9, item_text: 'SBI' },
      { item_id: 10, item_text: 'Royal Sundaram' },
      { item_id: 11, item_text: 'Bajaj' },
      // { item_id: 12, item_text: 'Raheja QBE' },
      { item_id: 13, item_text: 'Sompo' },
      // { item_id: 14, item_text: 'Oriental' },
      { item_id: 15, item_text: 'Reliance' },
      // { item_id: 16, item_text: 'Shriram' },
      // { item_id: 17, item_text: 'Magma HDI' },
      // { item_id: 18, item_text: 'Navi' },
      // { item_id: 19, item_text: 'Liberty' },
      // { item_id: 20, item_text: 'Future' },
      // { item_id: 21, item_text: 'Edelweiss' },
      // { item_id: 22, item_text: 'Chola MS' },
      // { item_id: 23, item_text: 'Acko' },
      { item_id: 24, item_text: 'Digit' }
    ];

    this.dropdownListForReqInsBike = [
      // { item_id: 1, item_text: 'Tata AIG' },
      // { item_id: 2, item_text: 'Ifco Tokio' },
      // { item_id: 3, item_text: 'New India' },
      // { item_id: 4, item_text: 'National' },
      // { item_id: 5, item_text: 'United India' },
      // { item_id: 6, item_text: 'HDFC Ergo' },
      // { item_id: 7, item_text: 'Kotak' },
      { item_id: 8, item_text: 'ICICI' },
      // { item_id: 9, item_text: 'SBI' },
      // { item_id: 10, item_text: 'Royal Sundaram' },
      { item_id: 11, item_text: 'Bajaj' },
      // { item_id: 12, item_text: 'Raheja QBE' },
      // { item_id: 13, item_text: 'Sompo' },
      // { item_id: 14, item_text: 'Oriental' },
      // { item_id: 15, item_text: 'Reliance' },
      // { item_id: 16, item_text: 'Shriram' },
      // { item_id: 17, item_text: 'Magma HDI' },
      // { item_id: 18, item_text: 'Navi' },
      // { item_id: 19, item_text: 'Liberty' },
      // { item_id: 20, item_text: 'Future' },
      // { item_id: 21, item_text: 'Edelweiss' },
      // { item_id: 22, item_text: 'Chola MS' },
      // { item_id: 23, item_text: 'Acko' },
      // { item_id: 24, item_text: 'Digit' }
    ];

    this.dropdownListForPYP = [
      { item_id: 1, item_text: 'Comprehensive' },
      { item_id: 1, item_text: 'Comprehensive With Zero Dep' },
      { item_id: 2, item_text: 'Third Party' },
      { item_id: 3, item_text: 'Bundle' },
    ];

    this.dropdownListForPolType = [
      { item_id: 1, item_text: 'Package' },
      { item_id: 2, item_text: 'Package With Addons' },
      { item_id: 3, item_text: 'Only Third Party' },
      { item_id: 4, item_text: 'Only Own Damage' },
    ];

    this.dropdownListForFuelType = [
      { item_id: 1, item_text: 'Petrol' },
      { item_id: 2, item_text: 'Diesel' },
      { item_id: 3, item_text: 'CNG' },
      { item_id: 4, item_text: '35%' },
      { item_id: 5, item_text: '45%' },
      { item_id: 6, item_text: '50%' }
    ];

    this.dropdownSettingsNcb = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      // allowSearchFilter: true
    };

    this.dropdownSettingsPYP = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      // allowSearchFilter: true
    };

    this.dropdownSettingsPolType = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      // allowSearchFilter: true
    };

    this.dropdownSettingsAddons = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.dropdownSettingsReqIns = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.dropdownSettingscoremmv = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      allowSearchFilter: true
    };

  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.time === 0) {
        this.time++;
      } else {
        this.time++;
      }
      this.display = this.transform(this.time)
      // this.display = this.time
    }, 1000);
  }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return minutes + ':' + (value - minutes * 60);
  }

  pauseTimer() {
    clearInterval(this.interval);
    this.display = 0;
    this.time = 0;
  }

  gobackBtn(sectionType: any) {
    if (sectionType == 'productSection') {
      this._location.back();
    }
    else if (sectionType == 'businessTypeSection') {
      this.businessTypeSection = false
      this.productSection = true
    }
    else if (sectionType == 'regNum') {
      this.businessTypeSection = true
      this.RegNumberSection = false;
    }
    else if (sectionType == 'formSection') {
      this.RegNumberSection = true;
      this.formSection = false
    }
    else if (sectionType == 'formSectionOne') {
      this.formSection = true
      this.formSectionOne = false
    }
    else if (sectionType == 'formSectionTwo') {
      this.formSectionOne = true
      this.formSectionTwo = false
    }
    else if (sectionType == 'responseTable') {

      // routes
      this.formSectionTwo = true
      this.responseTable = false

      // empty the array

      this.sortedArray = []
      this.responseArray = []
      this.selectedCompany = 0

      this.pauseTimer()

      this.totalpremiumsompo = null
      this.qouteofsompo = null
      this.totalpremiumroyal = null
      this.qouteofroyal = null
      this.totalpremiumnational = null
      this.qouteofnational = null
      this.qouteoftataaig = null
      this.totalpremiumtataaig = null
      this.qouteofdigit = null
      this.totalpremiumdigit = null
      this.totalpremiumbajaj = null
      this.qouteofbajaj = null
      this.totalpremiumicici = null
      this.qouteoficici = null
      this.totalpremiumhdfc = null
      this.qouteofhdfc = null
      this.totalpremiumreliance = null
      this.qouteofreliance = null
      this.nationalError = false;
      this.royalError = false;
      this.sompoError = false;
      this.isDigitError = false;
      this.isTataError = false;
      this.isBajajError = false;
      this.isRelianceError = false;
      this.isHdfcError = false;
      this.iciciError = false;
    }
  }

  showCheckInput() {
    this.revealCheckbox = !this.revealCheckbox
  }

  nextStep(product: any) {
    this.product = product;
    // MADE FALSE ALL THE  REQ INSURER VARIABLE'S
    this.isNational = false
    this.isRoyalSundaram = false
    this.isSompo = false
    this.isDigit = false
    this.isTata = false
    this.isReliance = false
    this.isHdfc = false
    this.isIcici = false
    this.isBajaj = false
    this.isFuture = false
    this.unitedIndia = false

    if (product === 'car') {
      this.businessTypeSection = true
      this.productSection = false
    }
    // else if (product === 'bike') {
    //   this.businessTypeSection = true
    //   this.productSection = false
    // }
    else {
      console.log('Only Car & Bike !');
    }
  }

  showInput(businessType: any) {
    this.selectedBusinessType = businessType;
    console.log(this.selectedBusinessType);
    if (this.selectedBusinessType === 'rollover') {
      this.businessTypeSection = false
      this.RegNumberSection = true
    }
  }

  formatVehicleNumber(vehicleNumber) {
    // Remove all non-alphanumeric characters
    const cleanedNumber = vehicleNumber.replace(/[^a-zA-Z0-9]/g, '');
    return cleanedNumber.toUpperCase();
  }

  switchForm(value: any, step: any) {

    console.log(this.isFuture, 'isFuture    variable ');
    console.log(this.unitedIndia, 'unitedIndia variable  ');

    if (step == 'stepOne') {

      this.errMake = false
      this.errModel = false
      this.errVarient = false
      this.errImgPincode = false
      this.errImgRto = false
      this.errImgFuel = false
      this.errImgCc = false
      this.errImgSeat = false
      this.errTitle = false

      if (this.make === '' || this.make === undefined || this.make === null) {
        document.getElementById('errMake').style.display = 'block'
        this.errMake = true
      } else {
        document.getElementById('errMake').style.display = 'none'
        this.errMake = false
      }

      if (this.model === '' || this.model === undefined || this.model === null) {
        document.getElementById('errModel').style.display = 'block'
        this.errModel = true
      } else {
        document.getElementById('errModel').style.display = 'none'
        this.errModel = false
      }

      if (this.variant === '' || this.variant === undefined || this.variant === null) {
        document.getElementById('errVarient').style.display = 'block'
        this.errVarient = true
      } else {
        document.getElementById('errVarient').style.display = 'none'
        this.errVarient = false
      }

      if (this.rto === '' || this.rto === undefined || this.rto === null) {
        document.getElementById('errImgRto').style.display = 'block'
        this.errImgRto = true
      } else {
        document.getElementById('errImgRto').style.display = 'none'
        this.errImgRto = false
      }

      if (this.pincode === '' || this.pincode === undefined || this.pincode === null) {
        document.getElementById('errImgPincode').style.display = 'block'
        this.errImgPincode = true
      } else {
        document.getElementById('errImgPincode').style.display = 'none'
        this.errImgPincode = false
      }

      if (this.product === 'gcv' || this.product === 'lcv') {
        if (this.gvwValue === '' || this.gvwValue === undefined || this.gvwValue === null) {
          document.getElementById('errImgGvw').style.display = 'block'
        } else {
          document.getElementById('errImgGvw').style.display = 'none'
        }
      }

      if (this.fueltype === '' || this.fueltype === undefined || this.fueltype === null) {
        document.getElementById('errImgFuel').style.display = 'block'
        this.errImgFuel = true
      } else {
        document.getElementById('errImgFuel').style.display = 'none'
        this.errImgFuel = false
      }

      if (this.cc === '' || this.cc === undefined || this.cc === null) {
        document.getElementById('errImgCc').style.display = 'block'
        this.errImgCc = true
      } else {
        document.getElementById('errImgCc').style.display = 'none'
        this.errImgCc = false
      }

      if (this.seatingcapacity === '' || this.seatingcapacity === undefined || this.seatingcapacity === null) {
        document.getElementById('errImgSeat').style.display = 'block'
        this.errImgSeat = true
      } else {
        document.getElementById('errImgSeat').style.display = 'none'
        this.errImgSeat = false
      }

      if (this.clientNameTitle === '' || this.clientNameTitle === undefined || this.clientNameTitle === null) {
        document.getElementById('errTitle').style.display = 'block'
        this.errTitle = true
      } else {
        document.getElementById('errTitle').style.display = 'none'
        this.errTitle = false
      }

      if (!this.errMake && !this.errModel && !this.errVarient && !this.errImgPincode && !this.errImgRto && !this.errImgFuel && !this.errImgCc && !this.errImgSeat && !this.errTitle) {
        this.formSection = false;
        this.formSectionOne = true;
      } else {
        console.log(`Please Fill All The Field's`)
      }

    } else if (step == 'regNum') {

      let vehicleNumber = value;
      const formattedNumber = this.formatVehicleNumber(vehicleNumber);
      console.log(formattedNumber, 'formattedNumber1');

      this.regNumber = formattedNumber.toUpperCase()
      console.log(this.regNumber);
      let regNo = this.regNumber.split(/(\d+)/)
      console.log(regNo[0], regNo[1], regNo[2], regNo[3])
      this.rto = regNo[0] + regNo[1]
      this.RegNumberSection = false;
      this.formSection = true;

      if (this.reqInsurerArray.length > 0) {
        this.totalResponse = this.reqInsurerArray.length;
        for (let i = 0; i < this.reqInsurerArray.length; i++) {
          if (this.reqInsurerArray[i] === "Tata AIG") {
            this.isTata = true
            console.log('isTataaaaaaaaa', this.isTata);
          }
          else if (this.reqInsurerArray[i] === "Ifco Tokio") {
            this.isIfco = true
            console.log('isIfco000000000', this.isIfco);
          }
          else if (this.reqInsurerArray[i] === "New India") {
            this.newIndia = true
            console.log('newIndiaaaaaaaaa', this.newIndia);
          }
          else if (this.reqInsurerArray[i] === "National") {
            this.isNational = true
            console.log('isNationalllllll', this.isNational);
          }
          else if (this.reqInsurerArray[i] === "United India") {
            this.unitedIndia = true
            console.log('unitedIndiaaaaaa', this.unitedIndia);
          }
          else if (this.reqInsurerArray[i] === "HDFC Ergo") {
            this.isHdfc = true
            console.log('isHdfcccccccc', this.isHdfc);
          }
          else if (this.reqInsurerArray[i] === "Kotak") {
            this.isKotak = true
            console.log('Kotakkkkkk', this.isKotak);
          }
          else if (this.reqInsurerArray[i] === "ICICI") {
            this.isIcici = true
            console.log('isIcici', this.isIcici);
          }
          else if (this.reqInsurerArray[i] === "SBI") {
            this.isSBI = true
            console.log('SBI', this.isSBI);
          }
          else if (this.reqInsurerArray[i] === "Royal Sundaram") {
            this.isRoyalSundaram = true
            console.log('isRoyalSundaram', this.isRoyalSundaram);
          }
          else if (this.reqInsurerArray[i] === "Bajaj") {
            this.isBajaj = true
            console.log('Bajaj', this.isBajaj);
          }
          else if (this.reqInsurerArray[i] === "Raheja QBE") {
            this.isRahejaQbe = true
            console.log('isRahejaQbe', this.isRahejaQbe);
          }
          else if (this.reqInsurerArray[i] === "Sompo") {
            this.isSompo = true
            console.log('Sompo', this.isSompo);
          }
          else if (this.reqInsurerArray[i] === "Oriental") {
            this.isOriental = true
            console.log('isOriental', this.isOriental);
          }
          else if (this.reqInsurerArray[i] === "Reliance") {
            this.isReliance = true
            console.log('isReliance', this.isReliance);
          }
          else if (this.reqInsurerArray[i] === "Shriram") {
            this.isShriram = true
            console.log('isShriram', this.isShriram);
          }
          else if (this.reqInsurerArray[i] === "Magma HDI") {
            this.isMagmaHdi = true
            console.log('isMagmaHdi', this.isMagmaHdi);
          }
          else if (this.reqInsurerArray[i] === "Navi") {
            this.isNavi = true
            console.log('isNavi', this.isNavi);
          }
          else if (this.reqInsurerArray[i] === "Liberty") {
            this.isLiberty = true
            console.log('isLiberty', this.isLiberty);
          }
          else if (this.reqInsurerArray[i] === "Future") {
            this.isFuture = true
            console.log('isFuture', this.isFuture);
          }
          else if (this.reqInsurerArray[i] === "Edelweiss") {
            this.isEdelweiss = true
            console.log('isEdelweiss', this.isEdelweiss);
          }
          else if (this.reqInsurerArray[i] === "Chola MS") {
            this.isCholaMs = true
            console.log('isCholaMs', this.isCholaMs);
          }
          else if (this.reqInsurerArray[i] === "Acko") {
            this.isAcko = true
            console.log('isAcko', this.isAcko);
          }
          else if (this.reqInsurerArray[i] === "Digit") {
            this.isDigit = true
            console.log('isDigit', this.isDigit);
          }
        }
      } else {
        if (this.product === 'car') {
          this.isNational = true
          this.isRoyalSundaram = true
          this.isSompo = true;
          this.isDigit = true
          this.isTata = true
          this.isReliance = true
          this.isHdfc = true
        }
        this.isIcici = true
        this.isBajaj = true
        // this.isFuture = true
        // this.unitedIndia = true

        this.totalResponse = 9;
      }

      if (this.product === 'car') {
        this.fetchmake();
        this.fetchcardetails();
      }
      else if (this.product === 'bike') {
        this.fetchBikeDetails()
      }

    } else if (step == 'stepTwo') {

      this.errRegDate = false
      this.errMfgDate = false
      this.errExpDate = false

      if (this.registerdate === '' || this.registerdate === null || this.registerdate === undefined) {
        document.getElementById('errRegDate').style.display = 'block';
        this.errRegDate = true;
      } else {
        document.getElementById('errRegDate').style.display = 'none';
        this.errRegDate = false;
      }

      if (this.mftyear === '' || this.mftyear === null || this.mftyear === undefined) {
        document.getElementById('errMfgDate').style.display = 'block';
        this.errMfgDate = true;
      } else {
        document.getElementById('errMfgDate').style.display = 'none';
        this.errMfgDate = false;
      }

      if (this.expirydate === '' || this.expirydate === null || this.expirydate === undefined) {
        document.getElementById('errExpDate').style.display = 'block';
        this.errExpDate = true;
      } else {
        document.getElementById('errExpDate').style.display = 'none';
        this.errExpDate = false;
      }

      if (!this.errRegDate && !this.errMfgDate && !this.errExpDate) {
        this.formSectionOne = false;
        this.formSectionTwo = true;
      } else {
        console.log('Not Filled All Inputs');
      }
    }
  }

  fetchcardetails() {
    this.backendCall.fetchCarDetails(this.regNumber).subscribe(Response => {
      if (Response) {
        this.getcarDtails = Response.data
        this.getmakemodel = this.getcarDtails.model_details["0"]
        // this.make = this.getmakemodel.Brand
        // this.model = this.getmakemodel.Model
        this.clientName = this.getcarDtails.fName + this.getcarDtails.lName
        // this.variant = this.getmakemodel.Variant_Name
        // this.cc = this.getmakemodel.displacement
        // this.fueltype = this.getmakemodel.fuelType
        // this.selectedFuelType = this.fueltype
        // this.seatingcapacity = this.getmakemodel.seating_capacity
        this.registerdate = this.getcarDtails.regn_dt
        this.mftyear = this.getcarDtails.yrmanf
        this.pincode = this.getcarDtails.pincode
      }
    },
      error => {
        console.log(error.error.error_msg);
      });
  }

  fetchBikeDetails() {
    this.backendCall.fetchBikeDetails(this.regNumber).subscribe(Response => {
      if (Response) {

        this.getBikeDetails = Response.data
        this.getmakemodel = Response.data.model_details[0]
        this.make = this.getmakemodel.Brand
        this.model = this.getmakemodel.Model
        this.clientName = this.getBikeDetails.fName + this.getBikeDetails.lName
        this.variant = this.getmakemodel.Variant
        this.cc = this.getmakemodel.displacement
        this.fueltype = this.getmakemodel.fuelType
        this.selectedFuelType = this.fueltype
        this.seatingcapacity = this.getmakemodel.seating_capacity
        this.registerdate = this.getBikeDetails.regn_dt
        this.mftyear = this.getBikeDetails.yrmanf
        this.pincode = this.getBikeDetails.pincode

        console.log(this.clientName, '-------------->> fetchBikeDetails');

      }
    },
      error => {
        console.log(error.error.error_msg);
      });
  }

  handleTitle(e: { target: { value: any; }; }) {
    this.clientNameTitle = e.target.value;
    console.log(this.clientNameTitle, 'this.clientNameTitle');

  }

  mftmanually(data: any) {
    this.mftyear = data.target.value
    console.log(this.mftyear);
  }

  pincodemanually(data: any) {
    this.pincode = data.target.value
    console.log(this.pincode);
  }

  rtoManually(data: any) {
    this.rto = data.target.value
    console.log(this.rto);
  }

  gvwManual(data: any) {
    this.gvwValue = data.target.value
    console.log(this.gvwValue);
  }

  fuelManual(data: any) {
    this.fueltype = data.target.value
    console.log(this.fueltype, 'change fueltype');
  }

  fuelRadioValue(event: any) {
    this.fueltype = event.target.value;
    console.log(this.fueltype, 'modal fueltype');
  }

  ccManual(data: any) {
    this.cc = data.target.value
    console.log(this.cc);
  }

  seatManual(data: any) {
    this.seatingcapacity = data.target.value
    console.log(this.seatingcapacity);
  }

  registerdatemanually(data: any) {
    this.registerdate = data.target.value
    console.log(typeof (this.registerdate), 'registerdate');
    console.log(this.registerdate, 'registerdate');
  }

  seatingcapacitymanually(data: any) {
    this.seatingcapacity = data.target.value
    console.log(this.seatingcapacity);
  }

  variantmanually(data: any) {
    this.variant = data.target.value
    console.log(this.variant);
  }

  modelmanually(data: any) {
    this.model = data.target.value
    console.log(this.model);
  }

  makemanually(data: any) {
    this.make = data.target.value
    console.log(this.make);
  }

  nameManuall(data: any) {
    this.clientName = data.target.value
    console.log('nameeeeeeChangeddddddddddddddd--------->', this.clientName);
  }

  claimStatus(claimStat: any) {
    this.getClaimStatus = claimStat
  }

  onNcbSelect(ncbValue: any) {
    this.selectedNcb = ncbValue.item_text
    this.ncbNumber = ncbValue.item_id
    console.log(this.selectedNcb, this.ncbNumber)
  }

  deSelectNcb(ncbValue: any) {
    this.selectedNcb = ""
    console.log(this.selectedNcb)
  }

  deSelectPYP(selectedPyp: any) {
    this.selectedPYP = ""
    console.log(this.selectedPYP)
  }

  deSelectPoltype(selectedPolicy: any) {
    this.selectedPolType = ""
    console.log(this.selectedPolType)
  }

  addOnSelection(item: { item_text: any; }) {
    this.addonsArray.push(item.item_text)
    let temp_text = ""
    for (let i = 0; i < this.addonsArray.length; i++) {
      if (i == 0) {
        temp_text += (i + 1) + ". " + this.addonsArray[i]
      } else {
        temp_text += "\n" + (i + 1) + ". " + this.addonsArray[i] + "\r"
      }
    }
    this.addonsText = temp_text;
    console.log(temp_text, '<<<addonsArray')
  }

  PYPSelection(selectedPyp: { item_text: any; }) {
    this.selectedPYP = selectedPyp.item_text
    console.log(this.selectedPYP)
  }

  reqInsSelection(passedValue: { item_text: any; }) {
    this.reqInsurerArray.push(passedValue.item_text)
    console.log(this.reqInsurerArray);
  }

  PolicySelection(selectedPolicy: { item_text: any; }) {
    this.selectedPolType = selectedPolicy.item_text
    console.log(this.selectedPolType)
  }

  deSelectAllReqIns(item: any) {
    this.reqInsurerArray = []
    console.log(this.reqInsurerArray);
  }

  selectAllreqIns(item: string | any[]) {
    this.reqInsurerArray = []
    for (let i = 0; i < item.length; i++) {
      this.reqInsurerArray.push(item[i].item_text)
    }
    console.log(this.reqInsurerArray);
  }

  deSelectAddOns(item: { item_text: any; }) {
    for (let i = 0; i < this.addonsArray.length; i++) {
      if (this.addonsArray[i] == item.item_text) {
        this.addonsArray.splice(i, 1)
      }
    }

    let temp_text = ""
    for (let i = 0; i < this.addonsArray.length; i++) {
      if (i == 0) {
        temp_text += (i + 1) + ". " + this.addonsArray[i]
      } else {
        temp_text += "\n" + (i + 1) + ". " + this.addonsArray[i] + "\r"
      }
    }
    this.addonsText = temp_text;
    console.log(this.addonsArray);
  }

  deSelectReqIns(item: { item_text: any; }) {
    for (let i = 0; i < this.reqInsurerArray.length; i++) {
      if (this.reqInsurerArray[i] == item.item_text) {
        this.reqInsurerArray.splice(i, 1)
      }
    }
    console.log(this.reqInsurerArray);
  }

  selectAllAddOns(item: string | any[]) {
    this.addonsArray = []
    for (let i = 0; i < item.length; i++) {
      this.addonsArray.push(item[i].item_text)
    }

    let temp_text = ""
    for (let i = 0; i < this.addonsArray.length; i++) {
      if (i == 0) {
        temp_text += ". " + this.addonsArray[i]
      } else {
        temp_text += "\n" + (i + 1) + ", " + this.addonsArray[i] + "\n"
      }
    }
    console.log(temp_text, '<<<addonsArray')

    this.addonsText = temp_text;

    console.log(this.addonsArray);
  }

  deSelectAllAddOns() {
    this.addonsArray = []
    this.addonsText = ''
    console.log(this.addonsArray);
  }

  getExpiryDate(date: any) {

    // let selectedExpDate =  new Date(date);
    let selectedExpDate = formatDate(date, 'yyyy-MM-dd', 'en_US');
    let currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
    let currentDateTwo = new Date();
    let nextMonth = new Date();
    nextMonth.setMonth(currentDateTwo.getMonth() + 1);

    if (selectedExpDate < currentDate || new Date(selectedExpDate) >= nextMonth) {

      document.getElementById('errExpDateInValidation').style.display = 'block';
      this.expirydate = null; // Clear the input value if it's invalid
      this.errExpDate = true;

      console.log(this.expirydate, 'this.expirydate');
      console.log(typeof (this.expirydate), 'this.expirydate');

    } else {

      document.getElementById('errExpDateInValidation').style.display = 'none';
      this.errExpDate = false;
      this.expirydate = date;

      console.log(this.expirydate, 'this.expirydate');
      console.log(typeof (this.expirydate), 'this.expirydate');

    }
  }

  getidv(data: any) {
    console.log(data);
    this.idv = data
  }

  showInsurer() {
    this.showFilter = true;
    document.getElementById('mySidebar').style.display = 'block';
  }

  submitInsurer() {
    console.log(this.reqInsurerArray.length);
    console.log('kaaaaaaaaaaaaaaa');
  }

  hideInsurer() {
    // console.log(this.reqInsurerArray.length);
    document.getElementById('mySidebar').style.display = 'none';
  }

  hideFilter() {
    if (document.getElementById('mySidebar').style.display = 'block') {
      document.getElementById('mySidebar').style.display = 'none';
    }
  }

  onMakeSelect(makeValue: any) {
    this.make = makeValue.item_text
    console.log(this.make)
    this.fetchmodel();
  }

  onModelSelect(modelValue: any) {
    this.model = modelValue.item_text
    console.log(this.model)
    this.fetchvariant();
  }

  onVariantSelect(variantValue: any) {
    this.variant = variantValue.item_text
    console.log(this.variant)
    this.fetchcoreidandmmv();
  }

  // MODAL FUNCTION - - - - >

  showResponse(component: any, insurer: any) {
    this.component = component
    this.insurerErrCompany = insurer
    console.log(this.insurerErrCompany, 'insurerErrCompany');

    this.apiResponse.show();
  }

  showPopUp() {
    this.sendSocial.show();
  }

  setEmailId(event: any) {
    this.emailId = event;
  }

  setWhatsAppNumber(event: any) {
    this.whatsAppNumber = event;
  }

  handleSocial() {
    console.log(this.whatsAppNumber, 'handleSocial');
    console.log(this.emailId, 'handleSocial');
    var win = window.open(`https://wa.me/${this.whatsAppNumber}?text=I%27m%20api%20msg%20hello%20${'Vaibhav'}%20friend%20${'ka ho'}`, '_blank');
  }

  getCngParent(value: any, source: any) {
    this.getCngSource = value
    this.fueltype = source;
    console.log(this.fueltype, "cngfited");

  }

  getquote(stage: any) {
    if (this.selectedFuelType == undefined) {
      this.selectedFuelType = this.fueltype
      console.log(this.selectedFuelType, "radio fuel type");

    }
    console.log(this.isFuture, 'isFuture    variable ');
    console.log(this.unitedIndia, 'unitedIndia variable  ');
    if (stage === 'stepThree') {

      this.errIdv = false
      this.errClaim = false
      this.errNcb = false

      if (this.idv === '' || this.idv === undefined || this.idv === null) {
        document.getElementById('errIdv').style.display = 'block';
        this.errIdv = true
      } else {
        document.getElementById('errIdv').style.display = 'none';
        this.errIdv = false
      }

      if (this.getClaimStatus === '' || this.getClaimStatus === undefined || this.getClaimStatus === null) {
        document.getElementById('errClaim').style.display = 'block';
        this.errClaim = true
      } else {
        document.getElementById('errClaim').style.display = 'none';
        this.errClaim = false
      }

      if (this.getClaimStatus === 'no') {
        if (this.selectedNcb === '' || this.selectedNcb === undefined || this.selectedNcb === null) {
          // document.getElementById('errNcb').style.display = 'block';
          this.errNcb = true
        } else {
          // document.getElementById('errNcb').style.display = 'none';
          this.errNcb = false
        }
      }

      if (!this.errIdv && !this.errClaim) {

        // this.responseArray.sort((a, b) => {
        //   if (a.error === b.error) {
        //     return 0;
        //   } else if (a.error) {
        //     return 1;
        //   } else {
        //     return -1;
        //   }
        // });

        this.formSectionTwo = false
        this.responseTable = true
        this.startTimer()

        this.getquotation = (
          {
            enddate: this.expirydate, regnumber: this.regNumber, cc: this.cc, regdate: this.registerdate, carmake: this.make, carmodel: this.model,
            carvariant: this.variant, mftyear: this.mftyear, make: this.make + " " + this.model + " " + this.variant, fueltype: this.selectedFuelType,
            ncb: this.selectedNcb, ncbNo: this.ncbNumber, idv: this.idv, claim: this.getClaimStatus, addons: this.addonsArray, pincode: this.pincode,
            cardetails: this.getmakemodel, product: this.product, businessType: this.selectedBusinessType, clientname: this.clientName, title: this.clientNameTitle,
            seatingcapacity: this.seatingcapacity
          }
        )

        console.log(this.getquotation, 'getquotation');

        //********************************** Nodejs api for quote*********************************//

        // if (this.reqInsurerArray.length > 0) {
        //   for (let i = 0; i < this.reqInsurerArray.length; i++) {
        //     if (this.reqInsurerArray[i] == 'National') {
        //       this.isNational = true
        //       this.getnationalquote()
        //     } else if (this.reqInsurerArray[i] == 'Royal Sundaram') {
        //       this.isRoyalSundaram = true
        //       this.getroyalquote()
        //     } else if (this.reqInsurerArray[i] == 'Sompo') {
        //       this.getsompoquote()
        //       this.isSompo = true
        //     } else if (this.reqInsurerArray[i] == "Digit") {
        //       this.getdigitquote()
        //       this.isDigit = true
        //       console.log('isDigit', this.isDigit);
        //     } else if (this.reqInsurerArray[i] == "Tata AIG") {
        //       this.gettataaigquote()
        //       this.isTata = true
        //       console.log('isTataaaaaaaaa', this.isTata);
        //     } else if (this.reqInsurerArray[i] == "HDFC Ergo") {
        //       this.gethdfcquote()
        //       this.isHdfc = true
        //       console.log('isHdfcccccccc', this.isHdfc);
        //     } else if (this.reqInsurerArray[i] == "Reliance") {
        //       this.getreliancequote()
        //       this.isReliance = true
        //       console.log('isReliance', this.isReliance);
        //     } else if (this.reqInsurerArray[i] == "ICICI") {
        //       this.isIcici = true
        //       this.geticiciquote()
        //       console.log('isIcici', this.isIcici);
        //     } else if (this.reqInsurerArray[i] == "Bajaj") {
        //       this.isBajaj = true
        //       console.log('Bajaj', this.isBajaj);
        //       this.getbajajquote()
        //     } else if (this.reqInsurerArray[i] == "Future") {
        //       this.isFuture = true;
        //       console.log('Bajaj', this.isFuture);
        //       this.getfuturequote()
        //     } else if (this.reqInsurerArray[i] == "United India") {
        //       this.unitedIndia = true;
        //       console.log('Bajaj', this.unitedIndia);
        //       this.getunitedquote()
        //     }
        //   }
        // } else {
        //   this.getnationalquote()
        //   this.getroyalquote()
        //   this.getsompoquote()
        //   this.getdigitquote()
        //   this.gethdfcquote()
        //   this.getreliancequote()
        //   this.geticiciquote()
        //   this.getbajajquote()
        //   this.gettataaigquote()
        //   this.getunitedquote()
        //   this.getfuturequote()
        // }

        // **************************** lambda api for quote*********************************** //

        if (this.reqInsurerArray.length > 0) {
          for (let i = 0; i < this.reqInsurerArray.length; i++) {
            if (this.reqInsurerArray[i] == 'National') {
              this.isNational = true
              this.getnationalquotation()
            } else if (this.reqInsurerArray[i] == 'Royal Sundaram') {
              this.isRoyalSundaram = true
              this.getroyalquotation()
            } else if (this.reqInsurerArray[i] == 'Sompo') {
              this.getsompoquotation()
              this.isSompo = true
            } else if (this.reqInsurerArray[i] == "Digit") {
              this.getdigitquotation()
              this.isDigit = true
              console.log('isDigit', this.isDigit);
            } else if (this.reqInsurerArray[i] == "Tata AIG") {
              this.gettataaigquotation()
              this.isTata = true
              console.log('isTataaaaaaaaa', this.isTata);
            } else if (this.reqInsurerArray[i] == "HDFC Ergo") {
              this.gethdfcquotation()
              this.isHdfc = true
              console.log('isHdfcccccccc', this.isHdfc);
            } else if (this.reqInsurerArray[i] == "Reliance") {
              this.getreliancequotation()
              this.isReliance = true
              console.log('isReliance', this.isReliance);
            } else if (this.reqInsurerArray[i] == "ICICI") {
              this.isIcici = true
              this.geticiciquotation()
            } else if (this.reqInsurerArray[i] == "Bajaj") {
              this.isBajaj = true
              this.getbajajquotation()
            } else if (this.reqInsurerArray[i] == "Future") {
              this.isFuture = true;
              this.getfuturequotation();
            } else if (this.reqInsurerArray[i] == "United India") {
              this.unitedIndia = true;
              this.getunitedquotation();
            }
          }
        } else {

          if (this.product === 'car') {
            this.getnationalquotation()
            this.getroyalquotation()
            this.getsompoquotation()
            this.getdigitquotation()
            this.gettataaigquotation()
            this.getreliancequotation()
            this.gethdfcquotation()
          }

          // this.getunitedquotation()
          // this.getfuturequotation()
          this.geticiciquotation()
          this.getbajajquotation()

        }
      }
    }
  }

  handleRetry(company: any) {
    console.log(company);

    switch (company) {
      case 'national':
        this.getnationalquotation();
        this.nationalRetryLoader = true;
        this.nationalError = false;
        console.log(this.nationalRetryLoader, 'this.nationalRetryLoader  retryLoader');
        const index = this.responseArray.findIndex(item => item.companyName === 'national');
        if (index !== -1) {
          this.responseArray[index].retryLoader = true;
        }
        break;

      case 'royalSundaram':
        this.getroyalquotation();
        this.royalRetryLoader = true;
        this.royalError = false;
        console.log(this.royalRetryLoader, 'this.royalRetryLoader  retryLoader');
        const indexroyalSundaram = this.responseArray.findIndex(item => item.companyName === 'royalSundaram');
        if (indexroyalSundaram !== -1) {
          this.responseArray[indexroyalSundaram].retryLoader = true;
        }
        break;

      case 'sompo':
        this.getsompoquotation();
        this.sompoRetryLoader = true;
        this.sompoError = false;
        console.log(this.sompoRetryLoader, 'this.sompoRetryLoader  retryLoader');
        const indexsompo = this.responseArray.findIndex(item => item.companyName === 'sompo');
        if (indexsompo !== -1) {
          this.responseArray[indexsompo].retryLoader = true;
        }
        break;

      case 'tata':
        this.gettataaigquotation();
        this.tataRetryLoader = true;
        this.isTataError = false;
        console.log(this.tataRetryLoader, 'this.tataRetryLoader  retryLoader');
        const indextata = this.responseArray.findIndex(item => item.companyName === 'tata');
        if (indextata !== -1) {
          this.responseArray[indextata].retryLoader = true;
        }

        break;

      case 'digit':
        this.getdigitquotation();
        this.digitRetryLoader = true;
        this.isDigitError = false;
        console.log(this.digitRetryLoader, 'this.digitRetryLoader  retryLoader');
        const indexdigit = this.responseArray.findIndex(item => item.companyName === 'digit');
        if (indexdigit !== -1) {
          this.responseArray[indexdigit].retryLoader = true;
        }
        break;

      case 'icici':
        this.geticiciquotation();
        this.iciciRetryLoader = true;
        this.iciciError = false;
        console.log(this.iciciRetryLoader, 'this.iciciRetryLoader  retryLoader');
        const indexicici = this.responseArray.findIndex(item => item.companyName === 'icici');
        if (indexicici !== -1) {
          this.responseArray[indexicici].retryLoader = true;
        }
        break;

      case 'hdfc':
        this.gethdfcquotation();
        this.hdfcRetryLoader = true;
        this.isHdfcError = false;
        console.log(this.hdfcRetryLoader, 'this.hdfcRetryLoader  retryLoader');
        const indexhdfc = this.responseArray.findIndex(item => item.companyName === 'hdfc');
        if (indexhdfc !== -1) {
          this.responseArray[indexhdfc].retryLoader = true;
        }
        break;

      case 'reliance':
        this.getreliancequotation();
        this.relianceRetryLoader = true;
        this.isRelianceError = false;
        console.log(this.relianceRetryLoader, 'this.relianceRetryLoader  retryLoader');
        const indexreliance = this.responseArray.findIndex(item => item.companyName === 'reliance');
        if (indexreliance !== -1) {
          this.responseArray[indexreliance].retryLoader = true;
        }
        break;

      case 'bajaj':
        this.getbajajquotation();
        this.bajajRetryLoader = true;
        this.isBajajError = false;
        console.log(this.bajajRetryLoader, 'this.bajajRetryLoader  retryLoader');
        const indexbajaj = this.responseArray.findIndex(item => item.companyName === 'bajaj');
        if (indexbajaj !== -1) {
          this.responseArray[indexbajaj].retryLoader = true;
        }
        break;

      case 'united':
        this.getunitedquotation();
        this.unitedRetryLoader = true;
        this.unitedError = false;
        console.log(this.unitedRetryLoader, 'this.unitedRetryLoader  retryLoader');
        const indexunited = this.responseArray.findIndex(item => item.companyName === 'united');
        if (indexunited !== -1) {
          this.responseArray[indexunited].retryLoader = true;
        }
        break;

      case 'future':
        this.getfuturequotation();
        this.futureRetryLoader = true;
        this.futureError = false;
        console.log(this.futureRetryLoader, 'this.futureRetryLoader  retryLoader');
        const indexfuture = this.responseArray.findIndex(item => item.companyName === 'future');
        if (indexfuture !== -1) {
          this.responseArray[indexfuture].retryLoader = true;
        }
        break;

      default:
        console.log('retry btn clicked!');
        break;
    }
  }

  sortArray(): void {
    const uniqueArray = []; // To store unique objects

    // Separate arrays for error: false and error: true
    const errorFalseArray = this.responseArray.filter(obj => obj.error === false && obj.totalPremium !== 0);
    const errorTrueArray = this.responseArray.filter(obj => obj.error === true);

    errorFalseArray.sort((a, b) => a.totalPremium - b.totalPremium);
    errorTrueArray.sort((a, b) => a.totalPremium - b.totalPremium);

    // Iterate through errorFalseArray and add unique objects to uniqueArray
    for (const obj of errorFalseArray) {
      if (!uniqueArray.some(item => item.id === obj.id)) {
        uniqueArray.push(obj);
      }
    }

    // Iterate through errorTrueArray and add unique objects to uniqueArray
    for (const obj of errorTrueArray) {
      if (!uniqueArray.some(item => item.id === obj.id)) {
        uniqueArray.push(obj);
      }
    }

    this.sortedArray = uniqueArray;
    this.selectedCompany = this.sortedArray.length

    console.log(this.sortedArray, 'this.sortedArray');
    console.log(this.nationalRetryLoader, 'this.nationalRetryLoader  retryLoader');
    console.log(this.royalRetryLoader, 'this.royalRetryLoader  retryLoader');
    console.log(this.sompoRetryLoader, 'this.sompoRetryLoader  retryLoader');
    console.log(this.tataRetryLoader, 'this.tataRetryLoader  retryLoader');
    console.log(this.digitRetryLoader, 'this.digitRetryLoader  retryLoader');
    console.log(this.iciciRetryLoader, 'this.iciciRetryLoader  retryLoader');
    console.log(this.hdfcRetryLoader, 'this.hdfcRetryLoader  retryLoader');
    console.log(this.relianceRetryLoader, 'this.relianceRetryLoader  retryLoader');
    console.log(this.bajajRetryLoader, 'this.bajajRetryLoader  retryLoader');
    console.log(this.unitedRetryLoader, 'this.unitedRetryLoader  retryLoader');
    console.log(this.futureRetryLoader, 'this.futureRetryLoader  retryLoader');
  }

  //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&Nodejs coding api&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&//  

  getnationalquote() {
    let newData = [{ ...this.getquotation, ...this.nationalcoremmv }];
    console.log("newData", ...newData);
    let nationalquotationarray = Object.assign({}, ...newData);
    console.log(nationalquotationarray);
    this.backendCall.getnationalquote(nationalquotationarray).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        this.qouteofnational = Response.file
        this.totalpremiumnational = Response.premium
        this.nationalError = false
        this.nationalRetryLoader = false;
        console.log(this.qouteofnational, this.totalpremiumnational);
        if (this.totalpremiumnational) {
          console.log('timee-------------national---------->', this.display)
          this.fetchedTimeNational = this.display
        }
      }
    },
      error => {
        console.log(error.error, "errorrrrrrrrrrrrrrrrrrrrr");
        this.nationalError = true;
        this.nationalRetryLoader = false;
        this.qouteofnational = error.error.file
        this.totalpremiumnational = 0
        if (this.totalpremiumnational === 0) {
          console.log('timee-------------national---------->', this.display)
          this.fetchedTimeNational = this.display
        }
      });
  }

  geticiciquote() {
    let newData = [{ ...this.getquotation, ...this.icicicoremmv }];
    console.log("newData", ...newData);
    let iciciquotationarray = Object.assign({}, ...newData);
    console.log(iciciquotationarray);
    this.backendCall.geticiciquote(iciciquotationarray).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        this.qouteoficici = Response.file
        this.totalpremiumicici = Response.premium
        console.log(this.qouteoficici, this.totalpremiumicici);
        this.iciciRetryLoader = false;
        this.iciciError = false
        if (this.totalpremiumicici) {
          console.log('timee-------------sompo---------->', this.display)
          this.fetchedTimeIcici = this.display
        }
      }
    },
      error => {
        console.log(error.error.error_msg);
        this.qouteoficici = error.error.file
        this.totalpremiumicici = 0
        this.iciciError = true
        this.iciciRetryLoader = false;
        if (this.totalpremiumicici === 0) {
          console.log('timee-------------sompo---------->', this.display)
          this.fetchedTimeIcici = this.display
        }
      });
  }

  getroyalquote() {
    let newData = [{ ...this.getquotation, ...this.royalcoremmv }];
    console.log("newData", ...newData);
    let royalquotationarray = Object.assign({}, ...newData);
    console.log(royalquotationarray);
    this.backendCall.getroyalquote(royalquotationarray).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        this.qouteofroyal = Response.file
        this.totalpremiumroyal = Response.premium
        console.log(this.qouteofroyal, this.totalpremiumroyal);
        this.royalError = false
        this.royalRetryLoader = false;
        if (this.totalpremiumroyal) {
          console.log('timee-------------royal---------->', this.display)
          this.fetchedTimeRoyal = this.display
        }
      }
    },
      error => {
        console.log(error.error, "error");
        this.royalError = true;
        this.royalRetryLoader = false;
        this.qouteofroyal = error.error.file
        this.totalpremiumroyal = 0
        if (this.totalpremiumroyal === 0) {
          console.log('timee-------------royal---------->', this.display)
          this.fetchedTimeRoyal = this.display
        }
      });
  }

  getrahejaQbequote() {
    this.backendCall.getrahejaQbequote(this.getquotation).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        this.qouteofrahejaqbe = Response.file
        this.totalpremiumrahejaqbe = Response.premium
        console.log(this.qouteofrahejaqbe, this.totalpremiumrahejaqbe);
      }
    },
      error => {
        console.log(error.error.error_msg);
        this.qouteofrahejaqbe = error.error.file
        this.totalpremiumrahejaqbe = 0
        if (this.totalpremiumrahejaqbe === 0) {
          console.log('timee-------------digit---------->', this.display)
          this.fetchedTimeRaheja = this.display
        }
      });
  }

  getsompoquote() {
    let newData = [{ ...this.getquotation, ...this.sompocoremmv }];
    console.log("newData", ...newData);
    let sompoquotationarray = Object.assign({}, ...newData);
    console.log(sompoquotationarray);
    this.backendCall.getsompoquote(sompoquotationarray).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        this.qouteofsompo = Response.file
        this.totalpremiumsompo = Response.premium
        this.sompoRetryLoader = false;
        console.log(this.qouteofsompo, this.totalpremiumsompo);
        this.sompoError = false
        if (this.totalpremiumsompo) {
          console.log('timee-------------sompo---------->', this.display)
          this.fetchedTimeSompo = this.display
        }
      }
    },
      error => {
        console.log(error.error, "error");
        this.sompoError = true
        this.qouteofsompo = error.error.file
        this.totalpremiumsompo = 0
        this.sompoRetryLoader = false;
        if (this.totalpremiumsompo === 0) {
          console.log('timee-------------sompo---------->', this.display)
          this.fetchedTimeSompo = this.display
        }
      });
  }

  getdigitquote() {
    let newData = [{ ...this.getquotation, ...this.digitcoremmv }];
    console.log("newData", ...newData);
    let digitquotationarray = Object.assign({}, ...newData);
    console.log(digitquotationarray);
    this.backendCall.getdigitquote(digitquotationarray).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        this.qouteofdigit = Response.file
        this.totalpremiumdigit = Response.premium
        this.isDigitError = false;
        this.digitRetryLoader = false;
        console.log(this.qouteofdigit, this.totalpremiumdigit);
        if (this.totalpremiumdigit) {
          console.log('timee-------------digit---------->', this.display)
          this.fetchedTimeDigit = this.display
        }
      }
    },
      error => {
        console.log(error.error, "digit ------------------------------------error");
        this.qouteofdigit = error.error.file
        this.totalpremiumdigit = 0
        this.isDigitError = true;
        this.digitRetryLoader = false;
        if (this.totalpremiumdigit === 0) {
          console.log('timee-------------digit---------->', this.display)
          this.fetchedTimeDigit = this.display
        }
      });
  }

  gettataaigquote() {
    let newData = [{ ...this.getquotation, ...this.tatacoremmv }];
    console.log("newData", ...newData);
    let tataquotationarray = Object.assign({}, ...newData);
    console.log(tataquotationarray);
    this.backendCall.gettataaigquote(tataquotationarray).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        this.qouteoftataaig = Response.file
        this.totalpremiumtataaig = Response.premium
        this.isTataError = false;
        this.tataRetryLoader = false;
        console.log(this.qouteoftataaig, this.totalpremiumtataaig);
        if (this.totalpremiumtataaig) {
          console.log('timee-------------tata---------->', this.display)
          this.fetchedTimeTata = this.display
        }
      }
    },
      error => {
        console.log(error.error, "tata ------------------------------------error");
        this.qouteoftataaig = error.error.file
        this.totalpremiumtataaig = 0
        this.isTataError = true;
        this.tataRetryLoader = false;
        if (this.totalpremiumtataaig === 0) {
          console.log('timee-------------tata---------->', this.display)
          this.fetchedTimeTata = this.display
        }
      });
  }

  getbajajquote() {
    let newData = [{ ...this.getquotation, ...this.bajajcoremmv }];
    console.log("newData", ...newData);
    let bajajquotationarray = Object.assign({}, ...newData);
    console.log(bajajquotationarray);
    this.backendCall.getbajajquote(bajajquotationarray).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        this.qouteofbajaj = Response.file
        this.totalpremiumbajaj = Response.premium
        this.isBajajError = false;
        this.bajajRetryLoader = false;
        console.log(this.qouteofbajaj, this.totalpremiumbajaj);
        if (this.totalpremiumbajaj) {
          console.log('timee-------------tata---------->', this.display)
          this.fetchedTimeBajaj = this.display
        }
      }
    },
      error => {
        console.log(error.error, "tata ------------------------------------error");
        this.isBajajError = true;
        this.qouteofbajaj = error.error.file
        this.totalpremiumbajaj = 0;
        this.bajajRetryLoader = false;
        if (this.totalpremiumbajaj === 0) {
          console.log('timee-------------tata---------->', this.display)
          this.fetchedTimeBajaj = this.display
        }
      });
  }

  getreliancequote() {
    let newData = [{ ...this.getquotation, ...this.reliancecoremmv }];
    console.log("newData", ...newData);
    let reliancequotationarray = Object.assign({}, ...newData);
    console.log(reliancequotationarray);
    this.backendCall.getreliancequote(reliancequotationarray).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        this.qouteofreliance = Response.file
        this.totalpremiumreliance = Response.premium
        this.isRelianceError = false;
        this.relianceRetryLoader = false;
        console.log(this.qouteofreliance, this.totalpremiumreliance);
        if (this.totalpremiumreliance) {
          console.log('timee-------------tata---------->', this.display)
          this.fetchedTimeRel = this.display
        }
      }
    },
      error => {
        console.log(error.error, "reliance ------------------------------------error");
        this.isRelianceError = true;
        this.qouteofreliance = error.error.file
        this.totalpremiumreliance = 0;
        this.relianceRetryLoader = false;
        if (this.totalpremiumreliance === 0) {
          console.log('timee-------------tata---------->', this.display)
          this.fetchedTimeRel = this.display
        }
      });
  }

  gethdfcquote() {
    let newData = [{ ...this.getquotation, ...this.hdfccoremmv }];
    console.log("newData", ...newData);
    let hdfcquotationarray = Object.assign({}, ...newData);
    console.log(hdfcquotationarray);
    this.backendCall.gethdfcquote(hdfcquotationarray).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        this.qouteofhdfc = Response.file
        this.totalpremiumhdfc = Response.premium
        this.isHdfcError = false;
        this.hdfcRetryLoader = false;
        console.log(this.qouteofhdfc, this.totalpremiumhdfc);
        console.log('hdfcisrunning response', this.totalpremiumhdfc);
        if (this.totalpremiumhdfc) {
          console.log('timee-------------hdfc---------->', this.display)
          this.fetchedTimeHdfc = this.display
        }
      }
    },
      error => {
        console.log(error.error, "hdfc ------------------------------------error");
        console.log('hdfcisrunning err', this.totalpremiumhdfc, 'time----->', this.fetchedTimeHdfc, this.display);
        this.totalpremiumhdfc = 0;
        this.isHdfcError = true;
        this.qouteofhdfc = error.error.file;
        this.hdfcRetryLoader = false;
        if (this.totalpremiumhdfc === 0) {
          console.log('timee-------------tata---------->', this.display)
          this.fetchedTimeHdfc = this.display
        }
      });
  }

  getunitedquote() {
    console.log('hdfcisrunning');
    this.backendCall.getunitedquote(this.getquotation).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        this.qouteofunited = Response.file
        this.totalpremiumunited = Response.premium
        this.unitedError = false
        this.unitedRetryLoader = false;
        console.log(this.qouteofunited, this.totalpremiumunited);
        if (this.totalpremiumunited) {
          console.log('timee-------------united---------->', this.display)
          this.fetchedTimeUnited = this.display
        }
      }
    },
      error => {
        console.log(error.error, "errorrrrrrrrrrrrrrrrrrrrr");
        this.unitedError = true;
        this.qouteofunited = error.error.file
        this.totalpremiumunited = 0
        this.unitedRetryLoader = false;
        if (this.totalpremiumunited === 0) {
          console.log('timee-------------united---------->', this.display)
          this.fetchedTimeUnited = this.display
        }
      });
  }

  getfuturequote() {
    console.log('hdfcisrunning');
    this.backendCall.getfuturequote(this.getquotation).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        this.qouteoffuture = Response.file
        this.totalpremiumfuture = Response.premium
        this.futureError = false
        console.log(this.qouteoffuture, this.totalpremiumfuture);
        if (this.totalpremiumfuture) {
          console.log('timee-------------national---------->', this.display)
          this.fetchedTimeFuture = this.display
        }
      }
    },
      error => {
        console.log(error.error, "errorrrrrrrrrrrrrrrrrrrrr");
        this.futureError = true,
          this.qouteoffuture = error.error.file
        this.totalpremiumfuture = 0
        if (this.totalpremiumfuture === 0) {
          console.log('timee-------------national---------->', this.display)
          this.fetchedTimeFuture = this.display
        }
      });
  }

  //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&lambda coding api&&&&&&&&&&&&&&&&&&&&&&&&&&&// 

  getnationalquotation() {
    let newData = [{ ...this.getquotation, ...this.nationalcoremmv }];
    console.log("newData", ...newData);
    let nationalquotationarray = Object.assign({}, ...newData);
    console.log(nationalquotationarray);
    this.backendCall.getnationalquotation({ getquote: nationalquotationarray }).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        this.qouteofnational = Response.file
        this.totalpremiumnational = Response.premium
        this.nationalError = false
        console.log(this.totalpremiumnational, 'totalpremiumnational');
        const numericAmount = parseFloat(this.totalpremiumnational.replace(/,/g, ''));
        if (this.totalpremiumnational) {
          this.nationalRetryLoader = false;
          console.log('timee-------------national---------->', this.display)
          this.fetchedTimeNational = this.display
          this.responseArray.push(
            {
              id: 1,
              companyName: 'national',
              companyImage: '../../../assets/Partners_logo/national_logo.jpg',
              planType: 'Package + Addons',
              quote: this.qouteofnational,
              totalPremium: `${numericAmount}`,
              error: this.nationalError,
              fetchedTime: this.fetchedTimeNational,
              retryLoader: this.nationalRetryLoader,
            }
          );
        }
        this.sortArray();
      }
    },
      error => {
        console.log(error.error, "errorrrrrrrrrrrrrrrrrrrrr");
        this.nationalError = true;
        this.qouteofnational = error.error.file
        this.totalpremiumnational = 0
        if (this.totalpremiumnational === 0) {
          this.nationalRetryLoader = false;
          console.log('timee-------------national---------->', this.display)
          this.fetchedTimeNational = this.display
          this.responseArray.push(
            {
              id: 1,
              companyName: 'national',
              companyImage: '../../../assets/Partners_logo/national_logo.jpg',
              planType: 'Package + Addons',
              quote: this.qouteofnational,
              totalPremium: this.totalpremiumnational,
              error: this.nationalError,
              fetchedTime: this.fetchedTimeNational,
              retryLoader: this.nationalRetryLoader,
            }
          );
        }
        const index = this.responseArray.findIndex(item => item.companyName === 'national');
        if (index !== -1) {
          this.responseArray[index].retryLoader = false;
        }
        this.sortArray();
      }
    );
  }

  geticiciquotation() {
    let newData = [{ ...this.getquotation, ...this.icicicoremmv }];
    console.log("newData", ...newData);
    let iciciquotationarray = Object.assign({}, ...newData);
    console.log(iciciquotationarray);
    this.backendCall.geticiciquotation({ getquote: iciciquotationarray }).subscribe(Response => {
      this.isLoading = false;
      console.log(this.responseArray, 'this.responseArray');
      if (Response) {
        this.qouteoficici = Response.file
        this.totalpremiumicici = Response.premium
        console.log(this.qouteoficici, this.totalpremiumicici);
        this.iciciRetryLoader = false;
        this.iciciError = false
        if (this.totalpremiumicici) {
          console.log('timee-------------sompo---------->', this.display)
          this.fetchedTimeIcici = this.display
          this.responseArray.push(
            {
              id: 2,
              companyName: 'icici',
              companyImage: '../../../assets/Partners_logo/insurance_company_1.svg',
              planType: 'Package + Addons',
              quote: this.qouteoficici,
              totalPremium: this.totalpremiumicici,
              error: this.iciciError,
              fetchedTime: this.fetchedTimeIcici,
              retryLoader: this.iciciRetryLoader,
            }
          );
        }
        this.sortArray();
      }
    },
      error => {
        console.log(error.error.error_msg);
        this.qouteoficici = error.error.file
        this.totalpremiumicici = 0
        this.iciciError = true
        this.iciciRetryLoader = false;
        if (this.totalpremiumicici === 0) {
          console.log('timee-------------sompo---------->', this.display)
          this.fetchedTimeIcici = this.display
          this.responseArray.push(
            {
              id: 2,
              companyName: 'icici',
              companyImage: '../../../assets/Partners_logo/insurance_company_1.svg',
              planType: 'Package + Addons',
              quote: this.qouteoficici,
              totalPremium: this.totalpremiumicici,
              error: this.iciciError,
              fetchedTime: this.fetchedTimeIcici,
              retryLoader: this.iciciRetryLoader,
            }
          );
        }
        const indexicici = this.responseArray.findIndex(item => item.companyName === 'icici');
        if (indexicici !== -1) {
          this.responseArray[indexicici].retryLoader = false;
        }
        this.sortArray();
      });

  }

  getroyalquotation() {
    let newData = [{ ...this.getquotation, ...this.royalcoremmv }];
    console.log("newData", ...newData);
    let royalquotationarray = Object.assign({}, ...newData);
    console.log(royalquotationarray);
    this.backendCall.getroyalquotation({ getquote: royalquotationarray }).subscribe(Response => {
      this.isLoading = false;
      console.log(this.responseArray, 'this.responseArray');
      if (Response) {
        this.qouteofroyal = Response.file
        this.totalpremiumroyal = Response.premium
        console.log(this.qouteofroyal, this.totalpremiumroyal);
        this.royalError = false
        this.royalRetryLoader = false;
        // Remove commas and convert to numeric value
        const numericAmountRoyal = parseFloat(this.totalpremiumroyal.replace(/,/g, ''));
        if (this.totalpremiumroyal) {
          console.log('timee-------------royal---------->', this.display)
          this.fetchedTimeRoyal = this.display
        }
        this.responseArray.push(
          {
            id: 3,
            companyName: 'royalSundaram',
            companyImage: '../../../assets/Partners_logo/sundaram_logo.png',
            planType: 'Package + Addons',
            quote: this.qouteofroyal,
            totalPremium: `${numericAmountRoyal}`,
            error: this.royalError,
            fetchedTime: this.fetchedTimeRoyal,
            retryLoader: this.royalRetryLoader,
          }
        );
        this.sortArray();
      }
    },
      error => {
        console.log(error.error, "error");
        this.royalError = true;
        this.royalRetryLoader = false;
        this.qouteofroyal = error.error.file
        this.totalpremiumroyal = 0
        if (this.totalpremiumroyal === 0) {
          console.log('timee-------------royal---------->', this.display)
          this.fetchedTimeRoyal = this.display
        }
        this.responseArray.push(
          {
            id: 3,
            companyName: 'royalSundaram',
            companyImage: '../../../assets/Partners_logo/sundaram_logo.png',
            planType: 'Package + Addons',
            quote: this.qouteofroyal,
            totalPremium: this.totalpremiumroyal,
            error: this.royalError,
            fetchedTime: this.fetchedTimeRoyal,
            retryLoader: this.royalRetryLoader,
          }
        );
        const indexroyalSundaram = this.responseArray.findIndex(item => item.companyName === 'royalSundaram');
        if (indexroyalSundaram !== -1) {
          this.responseArray[indexroyalSundaram].retryLoader = false;
        }
        this.sortArray();
      });
  }

  getrahejaQbequotation() {
    this.backendCall.getrahejaQbequotation({ getquote: this.getquotation }).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        this.qouteofrahejaqbe = Response.file
        this.totalpremiumrahejaqbe = Response.premium
        console.log(this.qouteofrahejaqbe, this.totalpremiumrahejaqbe);
      }
    },
      error => {
        console.log(error.error.error_msg);
        this.qouteofrahejaqbe = error.error.file
        this.totalpremiumrahejaqbe = 0
        if (this.totalpremiumrahejaqbe === 0) {
          console.log('timee-------------digit---------->', this.display)
          this.fetchedTimeRaheja = this.display
        }
      });
  }

  getsompoquotation() {
    let newData = [{ ...this.getquotation, ...this.sompocoremmv }];
    console.log("newData", ...newData);
    let sompoquotationarray = Object.assign({}, ...newData);
    console.log(sompoquotationarray);
    this.backendCall.getsompoquotation({ getquote: sompoquotationarray }).subscribe(Response => {
      this.isLoading = false;
      console.log(this.responseArray, 'this.responseArray');
      if (Response) {
        this.qouteofsompo = Response.file
        this.totalpremiumsompo = Response.premium
        this.sompoRetryLoader = true;
        console.log(this.qouteofsompo, this.totalpremiumsompo);
        this.sompoError = false
        if (this.totalpremiumsompo) {
          this.sompoRetryLoader = false;
          console.log('timee-------------sompo---------->', this.display)
          this.fetchedTimeSompo = this.display
        }
        this.responseArray.push(
          {
            id: 4,
            companyName: 'sompo',
            companyImage: '../../../assets/Partners_logo/OIP.jfif',
            planType: 'Package + Addons',
            quote: this.qouteofsompo,
            totalPremium: this.totalpremiumsompo,
            error: this.sompoError,
            fetchedTime: this.fetchedTimeSompo,
            retryLoader: this.sompoRetryLoader,
          }
        );
        this.sortArray();
      }
    },
      error => {
        console.log(error.error, "error");
        this.sompoError = true
        this.qouteofsompo = error.error.file
        this.totalpremiumsompo = 0
        this.sompoRetryLoader = true;
        if (this.totalpremiumsompo === 0) {
          this.sompoRetryLoader = false;
          console.log('timee-------------sompo---------->', this.display)
          this.fetchedTimeSompo = this.display
        }
        this.responseArray.push(
          {
            id: 4,
            companyName: 'sompo',
            companyImage: '../../../assets/Partners_logo/OIP.jfif',
            planType: 'Package + Addons',
            quote: this.qouteofsompo,
            totalPremium: this.totalpremiumsompo,
            error: this.sompoError,
            fetchedTime: this.fetchedTimeSompo,
            retryLoader: this.sompoRetryLoader,
          }
        );
        const indexsompo = this.responseArray.findIndex(item => item.companyName === 'sompo');
        if (indexsompo !== -1) {
          this.responseArray[indexsompo].retryLoader = false;
        }
        this.sortArray();
      });
  }

  getdigitquotation() {
    let newData = [{ ...this.getquotation, ...this.digitcoremmv }];
    console.log("newData", ...newData);
    let digitquotationarray = Object.assign({}, ...newData);
    console.log(digitquotationarray);
    this.backendCall.getdigitquotation(digitquotationarray).subscribe(Response => {
      this.isLoading = false;
      console.log(this.responseArray, 'this.responseArray');
      if (Response) {
        this.qouteofdigit = Response.file
        this.totalpremiumdigit = Response.premium
        this.isDigitError = false;
        this.digitRetryLoader = false;
        console.log(this.qouteofdigit, this.totalpremiumdigit);
        if (this.totalpremiumdigit) {
          console.log('timee-------------digit---------->', this.display)
          this.fetchedTimeDigit = this.display
        }
        this.responseArray.push(
          {
            id: 5,
            companyName: 'digit',
            companyImage: '../../../assets/Partners_logo/digit_logo.svg',
            planType: 'Package + Addons',
            quote: this.qouteofdigit,
            totalPremium: this.totalpremiumdigit,
            error: this.isDigitError,
            fetchedTime: this.fetchedTimeDigit,
            retryLoader: this.digitRetryLoader,
          }
        );
        this.sortArray();
      }
    },
      error => {
        console.log(error.error, "digit ------------------------------------error");
        this.qouteofdigit = error.error.file
        this.totalpremiumdigit = 0
        this.isDigitError = true;
        this.digitRetryLoader = false;
        if (this.totalpremiumdigit === 0) {
          console.log('timee-------------digit---------->', this.display)
          this.fetchedTimeDigit = this.display
        }
        this.responseArray.push(
          {
            id: 5,
            companyName: 'digit',
            companyImage: '../../../assets/Partners_logo/digit_logo.svg',
            planType: 'Package + Addons',
            quote: this.qouteofdigit,
            totalPremium: this.totalpremiumdigit,
            error: this.isDigitError,
            fetchedTime: this.fetchedTimeDigit,
            retryLoader: false,
          }
        );
        const indexdigit = this.responseArray.findIndex(item => item.companyName === 'digit');
        if (indexdigit !== -1) {
          this.responseArray[indexdigit].retryLoader = false;
        }
        this.sortArray();
      });
  }

  gettataaigquotation() {
    let newData = [{ ...this.getquotation, ...this.tatacoremmv }];
    console.log("newData", ...newData);
    let tataquotationarray = Object.assign({}, ...newData);
    console.log(tataquotationarray);
    this.backendCall.gettataaigquotation({ getquote: tataquotationarray }).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        this.qouteoftataaig = Response.file
        this.totalpremiumtataaig = Response.premium
        this.isTataError = false;
        this.tataRetryLoader = true;
        if (this.totalpremiumtataaig) {
          this.tataRetryLoader = false;
          console.log('timee-------------tata---------->', this.display)
          this.fetchedTimeTata = this.display
        }
        this.responseArray.push(
          {
            id: 6,
            companyName: 'tata',
            companyImage: '../../../assets/Partners_logo/tata_aig_img.png',
            planType: 'Package + Addons',
            quote: this.qouteoftataaig,
            totalPremium: this.totalpremiumtataaig,
            error: this.isTataError,
            fetchedTime: this.fetchedTimeTata,
            retryLoader: false,
          }
        );
        this.sortArray();
      }
    },
      error => {
        console.log(error.error, "tata ------------------------------------error");
        this.qouteoftataaig = error.error.file
        this.totalpremiumtataaig = 0
        this.isTataError = true;
        if (this.totalpremiumtataaig === 0) {
          console.log('timee-------------tata---------->', this.display)
          this.fetchedTimeTata = this.display
          this.tataRetryLoader = false;
        }
        this.responseArray.push(
          {
            id: 6,
            companyName: 'tata',
            companyImage: '../../../assets/Partners_logo/tata_aig_img.png',
            planType: 'Package + Addons',
            quote: this.qouteoftataaig,
            totalPremium: this.totalpremiumtataaig,
            error: this.isTataError,
            fetchedTime: this.fetchedTimeTata,
            retryLoader: false,
          }
        );
        const indextata = this.responseArray.findIndex(item => item.companyName === 'tata');
        if (indextata !== -1) {
          this.responseArray[indextata].retryLoader = false;
        }
        this.sortArray();
      });
  }

  getbajajquotation() {
    let newData = [{ ...this.getquotation, ...this.bajajcoremmv }];
    console.log("newData", ...newData);
    let bajajquotationarray = Object.assign({}, ...newData);
    console.log(bajajquotationarray);
    this.backendCall.getbajajquotation({ getquote: bajajquotationarray }).subscribe(Response => {
      this.isLoading = false;
      console.log(this.responseArray, 'responseArray');
      if (Response) {
        this.qouteofbajaj = Response.file
        this.totalpremiumbajaj = Response.premium
        this.isBajajError = false;
        this.bajajRetryLoader = false;
        console.log(this.qouteofbajaj, this.totalpremiumbajaj);
        if (this.totalpremiumbajaj) {
          console.log('timee-------------tata---------->', this.display)
          this.fetchedTimeBajaj = this.display
        }
        this.responseArray.push(
          {
            id: 7,
            companyName: 'bajaj',
            companyImage: '../../../assets/Partners_logo/bajaj_logo.svg',
            planType: 'Package + Addons',
            quote: this.qouteofbajaj,
            totalPremium: this.totalpremiumbajaj,
            error: this.isBajajError,
            fetchedTime: this.fetchedTimeBajaj,
            retryLoader: this.bajajRetryLoader,
          }
        );
        this.sortArray();
      }
    },
      error => {
        console.log(error.error, "tata ------------------------------------error");
        this.isBajajError = true;
        this.qouteofbajaj = error.error.file
        this.totalpremiumbajaj = 0;
        this.bajajRetryLoader = false;
        if (this.totalpremiumbajaj === 0) {
          console.log('timee-------------tata---------->', this.display)
          this.fetchedTimeBajaj = this.display
        }
        this.responseArray.push(
          {
            id: 7,
            companyName: 'bajaj',
            companyImage: '../../../assets/Partners_logo/bajaj_logo.svg',
            planType: 'Package + Addons',
            quote: this.qouteofbajaj,
            totalPremium: this.totalpremiumbajaj,
            error: this.isBajajError,
            fetchedTime: this.fetchedTimeBajaj,
            retryLoader: this.bajajRetryLoader,
          }
        );
        const indexbajaj = this.responseArray.findIndex(item => item.companyName === 'bajaj');
        if (indexbajaj !== -1) {
          this.responseArray[indexbajaj].retryLoader = false;
        }
        this.sortArray();
      });
  }

  getreliancequotation() {
    let newData = [{ ...this.getquotation, ...this.reliancecoremmv }];
    console.log("newData", ...newData);
    let reliancequotationarray = Object.assign({}, ...newData);
    console.log(reliancequotationarray);
    this.backendCall.getreliancequotation({ getquote: reliancequotationarray }).subscribe(Response => {
      this.isLoading = false;
      console.log(this.responseArray, 'responseArray');
      if (Response) {
        this.qouteofreliance = Response.file
        this.totalpremiumreliance = Response.premium
        this.isRelianceError = false;
        this.relianceRetryLoader = false;
        console.log(this.qouteofreliance, this.totalpremiumreliance);
        if (this.totalpremiumreliance) {
          console.log('timee-------------tata---------->', this.display)
          this.fetchedTimeRel = this.display
        }
        this.responseArray.push(
          {
            id: 8,
            companyName: 'reliance',
            companyImage: '../../../assets/Partners_logo/reliance_logo.png',
            planType: 'Package + Addons',
            quote: this.qouteofreliance,
            totalPremium: this.totalpremiumreliance,
            error: this.isRelianceError,
            fetchedTime: this.fetchedTimeRel,
            retryLoader: this.relianceRetryLoader,
          }
        );
        this.sortArray();
      }
    },
      error => {
        console.log(error.error, "reliance ------------------------------------error");
        this.isRelianceError = true;
        this.qouteofreliance = error.error.file
        this.totalpremiumreliance = 0;
        this.relianceRetryLoader = false;
        if (this.totalpremiumreliance === 0) {
          console.log('timee-------------tata---------->', this.display)
          this.fetchedTimeRel = this.display
        }
        this.responseArray.push(
          {
            id: 8,
            companyName: 'reliance',
            companyImage: '../../../assets/Partners_logo/reliance_logo.png',
            planType: 'Package + Addons',
            quote: this.qouteofreliance,
            totalPremium: this.totalpremiumreliance,
            error: this.isRelianceError,
            fetchedTime: this.fetchedTimeRel,
            retryLoader: this.relianceRetryLoader,
          }
        );
        const indexreliance = this.responseArray.findIndex(item => item.companyName === 'reliance');
        if (indexreliance !== -1) {
          this.responseArray[indexreliance].retryLoader = false;
        }
        this.sortArray();
      });
  }

  gethdfcquotation() {
    let newData = [{ ...this.getquotation, ...this.hdfccoremmv }];
    console.log("newData", ...newData);
    let hdfcquotationarray = Object.assign({}, ...newData);
    console.log(hdfcquotationarray);
    this.backendCall.gethdfcquotation({ getquote: hdfcquotationarray }).subscribe(Response => {
      this.isLoading = false;
      console.log(this.hdfcRetryLoader, 'this.hdfcRetryLoader  retryLoader');
      console.log(this.responseArray, 'responseArray');
      if (Response) {
        this.qouteofhdfc = Response.file
        this.totalpremiumhdfc = Response.premium
        this.isHdfcError = false;
        this.hdfcRetryLoader = false;
        if (this.totalpremiumhdfc) {
          console.log('timee-------------hdfc---------->', this.display)
          this.fetchedTimeHdfc = this.display
        }
        this.responseArray.push(
          {
            id: 9,
            companyName: 'hdfc',
            companyImage: '../../../assets/Partners_logo/hdfc_img.svg',
            planType: 'Package + Addons',
            quote: this.qouteofhdfc,
            totalPremium: this.totalpremiumhdfc,
            error: this.isHdfcError,
            fetchedTime: this.fetchedTimeHdfc,
            retryLoader: this.hdfcRetryLoader,
          }
        );
      }
      this.sortArray();
    },
      error => {
        console.log(error.error, "hdfc ------------------------------------error");
        console.log('hdfcisrunning err', this.totalpremiumhdfc, 'time----->', this.fetchedTimeHdfc, this.display);
        this.totalpremiumhdfc = 0;
        this.isHdfcError = true;
        this.qouteofhdfc = error.error.file;
        this.hdfcRetryLoader = false;
        if (this.totalpremiumhdfc === 0) {
          console.log('timee-------------tata---------->', this.display)
          this.fetchedTimeHdfc = this.display
        }
        this.responseArray.push(
          {
            id: 9,
            companyName: 'hdfc',
            companyImage: '../../../assets/Partners_logo/hdfc_img.svg',
            planType: 'Package + Addons',
            quote: this.qouteofhdfc,
            totalPremium: this.totalpremiumhdfc,
            error: this.isHdfcError,
            fetchedTime: this.fetchedTimeHdfc,
            retryLoader: this.hdfcRetryLoader,
          }
        );
        const indexhdfc = this.responseArray.findIndex(item => item.companyName === 'hdfc');
        if (indexhdfc !== -1) {
          this.responseArray[indexhdfc].retryLoader = false;
        }
        this.sortArray();
      });
  }

  getunitedquotation() {
    this.backendCall.getunitedquotation({ getquote: this.getquotation }).subscribe(Response => {
      this.isLoading = false;
      console.log(this.responseArray, 'responseArray');
      if (Response) {
        this.qouteofunited = Response.file
        this.totalpremiumunited = Response.premium
        this.unitedError = false
        this.unitedRetryLoader = false;
        console.log(this.qouteofunited, this.totalpremiumunited);
        if (this.totalpremiumunited) {
          console.log('timee-------------united---------->', this.display)
          this.fetchedTimeUnited = this.display
        }
        this.responseArray.push(
          {
            id: 10,
            companyName: 'united',
            companyImage: '../../../assets/Partners_logo/united_india.png',
            planType: 'Package + Addons',
            quote: this.qouteofunited,
            totalPremium: this.totalpremiumunited,
            error: this.unitedError,
            fetchedTime: this.fetchedTimeUnited,
            retryLoader: this.unitedRetryLoader,
          }
        );
      }
      this.sortArray();
    },
      error => {
        console.log(error.error, "errorrrrrrrrrrrrrrrrrrrrr");
        this.unitedError = true;
        this.qouteofunited = error.error.file
        this.totalpremiumunited = 0
        this.unitedRetryLoader = false;
        if (this.totalpremiumunited === 0) {
          console.log('timee-------------united---------->', this.display)
          this.fetchedTimeUnited = this.display
        }
        this.responseArray.push(
          {
            id: 10,
            companyName: 'united',
            companyImage: '../../../assets/Partners_logo/united_india.png',
            planType: 'Package + Addons',
            quote: this.qouteofunited,
            totalPremium: this.totalpremiumunited,
            error: this.unitedError,
            fetchedTime: this.fetchedTimeUnited,
            retryLoader: this.unitedRetryLoader,
          }
        );
        const indexunited = this.responseArray.findIndex(item => item.companyName === 'united');
        if (indexunited !== -1) {
          this.responseArray[indexunited].retryLoader = false;
        }
        this.sortArray();
      });
  }

  getfuturequotation() {
    this.backendCall.getfuturequotation({ getquote: this.getquotation }).subscribe(Response => {
      this.isLoading = false;
      console.log(this.responseArray, 'responseArray');
      if (Response) {
        this.qouteoffuture = Response.file
        this.totalpremiumfuture = Response.premium
        this.futureError = false
        console.log(this.qouteoffuture, this.totalpremiumfuture);
        if (this.totalpremiumfuture) {
          console.log('timee-------------national---------->', this.display)
          this.fetchedTimeFuture = this.display
        }
        this.responseArray.push(
          {
            id: 11,
            companyName: 'future',
            companyImage: '../../../assets/Partners_logo/future_logo.png',
            planType: 'Package + Addons',
            quote: this.qouteoffuture,
            totalPremium: this.totalpremiumfuture,
            error: this.futureError,
            fetchedTime: this.fetchedTimeFuture,
            // retryLoader: this.unitedRetryLoader,
          }
        );
      }
      this.sortArray();
    },
      error => {
        console.log(error.error, "errorrrrrrrrrrrrrrrrrrrrr");
        this.futureError = true;
        this.qouteoffuture = error.error.file
        this.totalpremiumfuture = 0
        if (this.totalpremiumfuture === 0) {
          console.log('timee-------------national---------->', this.display)
          this.fetchedTimeFuture = this.display
        }
        this.responseArray.push(
          {
            id: 11,
            companyName: 'future',
            companyImage: '../../../assets/Partners_logo/future_logo.png',
            planType: 'Package + Addons',
            quote: this.qouteoffuture,
            totalPremium: this.totalpremiumfuture,
            error: this.futureError,
            fetchedTime: this.fetchedTimeFuture,
          }
        );
        const indexfuture = this.responseArray.findIndex(item => item.companyName === 'future');
        if (indexfuture !== -1) {
          this.responseArray[indexfuture].retryLoader = false;
        }
        this.sortArray();
      });
  }

  //mmv function

  fetchmake() {
    this.backendCall.getmake().subscribe(Response => {
      if (Response) {
        let temp = [];
        console.log(Response);
        for (let i = 0; i < Response.data[0].getmake.length; i++) {
          temp.push({ item_id: (i + 1), item_text: Response.data[0].getmake[i].Make })
        }
        this.coremakearray = temp
        console.log(this.coremakearray);
      }
    },
      error => {
        console.log(error);

      });
  }

  fetchmodel() {
    this.backendCall.getmodel(this.make).subscribe(Response => {
      if (Response) {
        let temp = [];
        console.log(Response);
        for (let i = 0; i < Response.data[0].getmodel.length; i++) {
          temp.push({ item_id: (i + 1), item_text: Response.data[0].getmodel[i].Model })
        }
        this.coremodelarray = temp
        console.log(this.coremodelarray);
      }
    },
      error => {
        console.log(error);
      });
  }

  fetchvariant() {
    this.backendCall.getvariant(this.make, this.model).subscribe(Response => {
      if (Response) {
        let temp = [];
        console.log(Response);
        for (let i = 0; i < Response.data[0].getvariant.length; i++) {
          temp.push({ item_id: (i + 1), item_text: Response.data[0].getvariant[i].Variant })
        }
        this.corevariantarray = temp
        console.log(this.corevariantarray);
      }
    },
      error => {
        console.log(error);
      });
  }

  fetchcoreidandmmv() {
    this.backendCall.getcoreidandmmv(this.make, this.model, this.variant).subscribe(Response => {
      if (Response) {
        console.log(Response);
        console.log(Response.coredata[0]);
        this.cc = Response.coredata[0].Cubic_Capacity
        this.seatingcapacity = Response.coredata[0].Seating_Capacity
        this.fueltype = Response.coredata[0].Fuel_Type
        this.selectedFuelType = this.fueltype

        //portal MMV
        this.bajajcoremmv = ({ bajajcoremmv: Response.data[0].bajaj })
        console.log(this.bajajcoremmv, "bajaj");
        this.digitcoremmv = ({ digitcoremmv: Response.data[0].digit })
        console.log(this.digitcoremmv, "digit");
        this.hdfccoremmv = ({ hdfccoremmv: Response.data[0].hdfc })
        console.log(this.hdfccoremmv, "hdfc");
        this.icicicoremmv = ({ icicicoremmv: Response.data[0].icici })
        console.log(this.icicicoremmv, "icici");
        this.nationalcoremmv = ({ nationalcoremmv: Response.data[0].national })
        console.log(this.nationalcoremmv, "national");
        this.reliancecoremmv = ({ reliancecoremmv: Response.data[0].reliance })
        console.log(this.reliancecoremmv, "reliance");
        this.royalcoremmv = ({ royalcoremmv: Response.data[0].royal })
        console.log(this.royalcoremmv, "royal");
        this.tatacoremmv = ({ tatacoremmv: Response.data[0].tata })
        console.log(this.tatacoremmv, "tata");
        this.sompocoremmv = ({ sompocoremmv: Response.data[0].sompo })
        console.log(this.sompocoremmv, "sompo");

      }
    },
      error => {
        console.log(error);
      });
  }
}
