import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  name: any = localStorage.getItem('name');
  constructor(private router: Router, private activatedRoute: ActivatedRoute, public location: Location) { }

  ngOnInit() { }

  showProfile() {
    // this.router.navigateByUrl(`user-profile`);
  }

  showBreak() {
    // alert('Break----Options');
  }

}
