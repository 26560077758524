import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { BackendCallService } from '../../services/backend-call.service';
type AOA = any[][];

@Component({
  selector: 'app-sale-upload',
  templateUrl: './sale-upload.component.html',
  styleUrls: ['./sale-upload.component.scss']
})
export class SaleUploadComponent implements OnInit {
  uploadFile: FormGroup;
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  data: AOA;
  buttonDisable: boolean = true;
  btnLoader: boolean = false
  chooseFile: string = 'Choose File';
  submitted: boolean = false;
  count: any;
  excelFileName: any;
  submitStatus: boolean;
  policyRecivedArray: any[];
  quoteUpload: string;
  totalPremium: any;
  fromDate: any;
  toDate: any;
  constructor(private formBuilder: FormBuilder, private backendCall: BackendCallService, private router: Router) { }

  ngOnInit() {
    this.getCountRoleWise();
    this.uploadFile = this.formBuilder.group({
      excelFile: ['', Validators.required]
    });
  }

  onFileSelected(event) {
    if (event.target.files.length > 0) {
      this.excelFileName = event.target.files[0].name;
      //  console.log(this.excelFileName);
      console.log(event.target.files[0].name);
      console.log("ghjrtydfg");


    }
  }

  public getCountRoleWise(): any {
    this.backendCall.getLeadCount(this.fromDate,this.toDate).subscribe(Response => {
      if (Response) {
        this.count = Response.data[0];
        this.totalPremium = Response.premiumCount[0].Premium;

      }
    },
      error => {
        alert(error.error.error_msg);
      });
  }

  get f() { return this.uploadFile.controls; }



  onSubmit(evt: any): void {
    // Process checkout data here
    /* wire up file reader */
    this.btnLoader = true;
    this.chooseFile = evt.target.files[0].name;
    //console.log(evt);
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {
        type: 'binary', cellDates: true,
        cellNF: false,
        cellText: false
      });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, {
        header: 1, dateNF: "yyyy/mm/dd"
      }));
      // console.log(this.data);
      this.btnLoader = false;
      this.buttonDisable = false;
    };
    reader.readAsBinaryString(target.files[0]);
    //console.warn('Your order has been submitted', this.uploadFile.value);

  }

  formValidation(formElement) {
    let _element = this.uploadFile.controls[formElement];

    //console.log(_element)
    if (_element.invalid && this.submitted) {
      return 'custom-file-input form-control is-invalid';

    } else if (_element.touched && _element.valid) {
      return 'form-control is-valid';

    } else {
      return 'custom-file-input form-control';
    }
  }

  uplaodData(): void {
    //console.log("upload this data", this.data)
    this.buttonDisable = true;
    this.submitted = true;
    this.btnLoader = true;
    if (!this.uploadFile.invalid) {
      // console.log(this.data);
      this.backendCall.uploadFile({ uploadContent: this.data }).subscribe(Response => {
        //console.log(Response, 'return data')
        this.btnLoader = false;
        if (Response) {
          this.router.navigate(['/lead/list']);
          alert("File uploaded successfully")
        }
      },
        error => {
          //console.log(error);
          alert(error.error.error_msg);
          this.router.navigate(['/dashboard/analytics']);
        });
    }
  }

  downloadMotorFormat() {
    const arr = [
      [
        {
          "Inward Date": "",
          "Inward Branch": "",
          "CUSTOMER NAME": "",
          "MOBILE NO": "",
          "EMAIL ID": "",
          "ADDRESS": "",
          "BQP": "",
          "REFFERED BY": "",
          "INSURER NAME": "",
          "BRANCH": "",
          "CODE": "",
          "DEPARTMENT": "",
          "POLICY ISSUANCE": "",
          "BUSINESS TYPE": "",
          "POLICY TYPE": "",
          "PRODUCT": "",
          "VEHICLE TYPE": "",
          "POLICY NUMBER": "",
          "ENDROSEMENT NUMBER": "",
          "ISSUED DATE": "",
          "RISK START DATE": "",
          "RISK END DATE": "",
          "GVW": "",
          "REGISTARION NO": "",
          "ENGINE NO": "",
          "CHASSIS NO": "",
          "MAKE": "",
          "MODEL": "",
          "FULE TYPE": "",
          "MFG YEAR": "",
          "SEATING CAPACITY": "",
          "ZERO DEP": "",
          "PA COVER": "",
          "NCB": "",
          "SUM INSURED": "",
          "OD PREMIUM": "",
          "TP PREMIUM": "",
          "NET PREMIUM": "",
          "GST": "",
          "Total Premium": "",
          "Mode of Payment": "",
          "Payment details": "",
          "staff": ""
        }
      ],
    ];
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(arr[0]);
    XLSX.utils.book_append_sheet(wb, ws, 'Motor');
    XLSX.writeFile(wb, "VIAANSH_MOTOR_FORMAT.xlsx");
  }

  downloadNonMotorFormat() {
    const array = [
      [
        {
          "INWARD DATE": "",
          "INWARD BRANCH": "",
          "CUSTMOR NAME": "",
          "EMIAL ID": "",
          "ADDRESS": "",
          "BQP": "",
          "REFFERED BY": "",
          "INSURER NAME": "",
          "BRANCH": "",
          "CODE": "",
          "DEPARTMENT": "",
          "PRODUCT": "",
          "POLICY ISSUANCE": "",
          "BUSINESS TYPE": "",
          "POLICY NUMBER": "",
          "ENDROSEMENT NUMBER": "",
          "ENDROSEMENT TYPE": "",
          "ISSUED DATE": "",
          "RISK START DATE": "",
          "RISK END DATE": "",
          "SUM INSURED": "",
          "OD PREMIUM": "",
          "TP PREMIUM": "",
          "TERRORISM PREMIUM": "",
          "NET PREMIUM": "",
          "GST": "",
          "TOTAL PREMIUM": "",
          "Mode of Payment": "",
          "Payment details": "",
          "Co Sharing": "",
          "Leader Name": "",
          "Leader Share%": "",
          "Follower Name 1": "",
          "Follower Share 1 %": "",
          "Follower Name 2": "",
          "Follower Share 2 %": "",
          "Follower Name 3": "",
          "Follower Share 3 %": "",
          "Follower Name 4": "",
          "Follower Share 4 %": "",
          "staff": ""
        }
      ],
    ];
    let wb1 = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(array[0]);
    XLSX.utils.book_append_sheet(wb1, ws1, 'NON_MOTOR');
    XLSX.writeFile(wb1, "VIAANSH_NONMOTOR_FORMAT.xlsx");
    // alert("Working.............")
    // console.log("bvbvbvbvbvvb");
  }

  goBack() {
    document.getElementById("upload_dpt").style.display = "flex";
    document.getElementById("motor").style.display = "none";
    document.getElementById("nonMotor").style.display = "none";
    document.getElementById("goBackTo").style.display = "none";
    document.getElementById("box").style.display = "none";
    document.getElementById('margin').style.marginTop='0rem'
  }

  updateMotor() {
    document.getElementById("motor").style.display = "block";
    document.getElementById("box").style.display = "block";
    document.getElementById("nonMotor").style.display = "none";
    document.getElementById("upload_dpt").style.display = "none";
    document.getElementById("goBackTo").style.display = "block";
    document.getElementById('margin').style.marginTop='2rem'
  }

  updateNonMotor() {
    document.getElementById("nonMotor").style.display = "block";
    document.getElementById("box").style.display = "block";
    document.getElementById("motor").style.display = "none";
    document.getElementById("upload_dpt").style.display = "none";
    document.getElementById("goBackTo").style.display = "block";
  }

  policyRecivedFileHandler(files: FileList){
    // let fileData = files;
    // console.log(fileData);
    // let uploadedFile = fileData.name;
    // alert(fileData)
    this.submitStatus = true;
    this.policyRecivedArray = [];
    for (var key in files) {
      if (files.hasOwnProperty(key)) {
        let fileData = files[key]
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = () => {
          console.log(reader.result)
          console.log(this.quoteUpload = fileData.name);
          this.policyRecivedArray.push({
            name: fileData.name,
            size: fileData.size,
            type: fileData.type,
            codec: reader.result
          })
        };
      }
    }
  }

}

