
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy'
})

export class OrderByPipe implements PipeTransform {
        transform(value: string): string {
          return value.replace(/,/g, '');
        }
}