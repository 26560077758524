import { style } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NextConfig } from '../../../../app-config';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  public nextConfig: any;
  public menuClass: boolean;
  public collapseStyle: string;
  public windowWidth: number;
  public clientTicket: any;
  public clientTicketBox: any;

  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavHeaderMobCollapse = new EventEmitter();
  clientTicketBox1: HTMLElement;
  clientTicketBox2: any;
  clientTicketBox3: any;

  opened: boolean;
  offlineFixedDiv: HTMLElement;

  constructor() {
    this.nextConfig = NextConfig.config;
    this.menuClass = false;
    this.collapseStyle = 'none';
    this.windowWidth = window.innerWidth;
  }

  ngOnInit() { }

  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.collapseStyle = (this.menuClass) ? 'block' : 'none';
  }

  navCollapse() {
    this.clientTicket = document.getElementById('clientTicket');
    this.clientTicketBox = document.getElementById('clientTicketBox');
    this.clientTicketBox1 = document.getElementById('clientTicketBox1');
    this.clientTicketBox2 = document.getElementById('clientTicketBox2');
    this.clientTicketBox3 = document.getElementById('clientTicketBox3');
    this.offlineFixedDiv = document.getElementById('fixed_div_id');
    if (this.windowWidth >= 992) {
      this.onNavCollapse.emit();
      console.log('navbarrrrrr')
      this.opened = true;
      console.log('openeddddddddddd', this.opened);
      this.clientTicket.style.left = '70px'
      this.clientTicketBox.style.left = '70px'
      this.clientTicketBox1.style.left = '70px'
      this.clientTicketBox2.style.left = '70px'
      this.clientTicketBox3.style.left = '70px'
      this.offlineFixedDiv.style.paddingRight = '3%';
    } else {
      this.opened = false;
      this.offlineFixedDiv.style.paddingRight = '3%';
      this.onNavHeaderMobCollapse.emit();
      console.log('closedddddddddddddddddd', this.opened);
    }
  }

}
