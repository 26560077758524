import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-overview',
  templateUrl: './data-overview.component.html',
  styleUrls: ['./data-overview.component.scss']
})
export class DataOverviewComponent implements OnInit {

  bifercateData = false;
  firstStep = true;

  constructor() { }

  ngOnInit() {
  }

  showData(){
    this.bifercateData = true;
    this.firstStep = false;
  }

  backBtn(){
    this.bifercateData = false;
    this.firstStep = true;
  }

}
