import { style } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BackendCallService } from '../../services/backend-call.service'
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgForm } from '@angular/forms';
import { ConnectTabComponent } from 'src/app/dispositon/connect-tab/connect-tab.component';
import { type } from 'os';

@Component({
	selector: 'app-ticket-management',
	templateUrl: './ticket-management.component.html',
	styleUrls: ['./ticket-management.component.scss']
})

export class TicketManagementComponent implements OnInit {
	@ViewChild('exampleModalCenter', { static: true }) myModal: { show: () => void; };
	@ViewChild('whatsapp', { static: true }) addNum: { show: () => void; };
	@ViewChild('claim', { static: true }) claimEvent: { show: () => void; };
	@ViewChild('closebutton', { static: true }) closebutton;
	@ViewChild('history', { static: true }) activities: { show: () => void; };
	// @ViewChild('pdf', { static: true }) pdfshow: { show: () => void; };
	@ViewChild('trans', { static: true }) transshow: { show: () => void; };
	@ViewChild('lnote', { static: true }) lnoteshow: { show: () => void; };
	@ViewChild('notepad', { static: true }) addNote: any;
	// @ViewChild('Datetime', { static: true }) addDate: any;
	@ViewChild('viewnotepad', { static: true }) viewNote: any;
	@ViewChild('leadDetailsModal', { static: true }) leadModal;
	@ViewChild('template', { static: true }) templateRef;
	@ViewChild('itemForm', { static: false }) form!: NgForm;

	public rows: Array<any> = [
	];

	isLoadingClaim = false;
	claimDetails: any;
	policyDetails: any;
	noClaimDetails = false;
	noPolicyDetails = false;
	getClaimDetailErr = false;
	updateLeadHistory: FormGroup;
	filterLeads: FormGroup;
	isLoading = true;
	shared = false;
	required = false;
	logicalStatus;
	allCount = 0;
	activityHistory: any;
	paymentLink = false;
	currentData: any;
	make: any;
	mobileNumber: any;
	reg_number: any;
	textArea: any;
	isAddNote = true;
	currentStatus;
	head: boolean = false;
	head1: boolean = false;
	head2: boolean = false;
	head3: boolean = false;
	head4: boolean = false;
	message: string;
	whatsappNum: any;
	noteData = ""
	notesData: any
	lnotesData: any
	sharedQuotes = []
	addedWhatsappMo = false;
	idv = []
	sharedPaymentLinks = []
	addonsStr = ""
	insPolicyArry = []
	quoteReqObj = {
		idv: "",
		ncb: "",
		plan: "",
		addOns: "",
		insurer: "",
		prevInsurer: ""
	}


	public columns: Array<any> = [
		// { title: 'Action', name: 'action', sort: false },
		{ title: 'PRODUCT NAME', name: 'product_name', filtering: { filterString: '', placeholder: 'Filter' } },
		{ title: 'CUSTOMER NAME', name: 'customer_name', filtering: { filterString: '', placeholder: 'Filter' }, sort: false },
		{ title: 'COMPANY NAME', name: 'company_name', filtering: { filterString: '', placeholder: 'Filter' } },
		{ title: 'EXPIRY DATE', name: 'expiry_date', filtering: { filterString: '', placeholder: 'Filter' } },
		{ title: 'GROSS PREMIUM', name: 'gross_premium', filtering: { filterString: '', placeholder: 'Filter' } },
		{ title: 'INWARD  ', name: 'branch', filtering: { filterString: '', placeholder: 'Filter' } },
		{ title: 'BQP', name: 'bqp', filtering: { filterString: '', placeholder: 'Filter' } },
		{ title: 'QC', name: '', filtering: { filterString: '', placeholder: 'Filter' } },
	];

	public TableData: Array<any> = []
	public page: number = 1;
	public itemsPerPage: number = 10;
	public maxSize: number = 5;
	public numPages: number = 1;
	public length: number = 0;
	public listType: any;
	OpenAll: boolean = true;
	public filterObject: any = {
		page: 1,
		ticketStatus: "",
		agentName: "",
		policyHolderName: ""
	};

	popUpData = {}
	leadData = {}
	public config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: '' },
		className: ['table-striped', 'table-bordered', 'm-b-0']
	};

	private data: Array<any> = this.TableData;
	statusType: any;
	statusCode: any;
	type;
	textBoxForNum: any;
	quote = "quote_required";
	dispositionType: any;
	dispoCode: any;
	oShell: any;
	commandtoRun: string;
	show: HTMLElement;
	show1: HTMLElement;
	show2: HTMLElement;
	show3: HTMLElement;
	show4: HTMLElement;
	f: any;
	loggedInAgentName = localStorage.getItem('name');
	copiedText: string;
	toast: boolean;
	getquotation: any;
	totalClaims: any;

	public constructor(private router: Router, private formBuilder: FormBuilder, public dialog: MatDialog, private backendCall: BackendCallService, private route: ActivatedRoute) {

	}

	public ngOnInit(): void {
		this.leadData = this.route.snapshot.queryParamMap.get('leadData') || this.leadData;
		console.log("ngOnInit", this.route.snapshot.queryParamMap.get('type'));
		this.type = this.route.snapshot.queryParamMap.get('type');
		this.leadData = this.route.snapshot.queryParamMap.get('leadData')
		console.log(this.leadData);
		this.backendCall.getLeadListData(this.leadData).subscribe(Response => {
			this.isLoading = false;
			if (Response) {
				this.currentData = Response.data[0];
				this.make = Response.data[0][0].make;
				this.reg_number = Response.data[0][0].reg_number;
				this.mobileNumber = Response.data[0][0].customer_mobile;
				console.log(this.make, this.reg_number, this.mobileNumber);

				this.currentStatus = this.currentData[0].status_id
				this.whatsappNum = this.currentData[0].alternate_number;
				this.addedWhatsappMo = this.currentData[0].alternate_number;
				this.backendCall.getLeadDetails(
					{
						id: this.leadData,
						status: this.currentStatus
					}
				)
					.subscribe(success => {
						if (success) {
							if (success.data.length) {
								console.log(success);
								this.logicalStatus = success.data[0].status[0].status_name
								this.allCount = success.data[0].status[0].count
								this.quoteReqObj.idv = success.data[0].leadDetails[0].idv
								if (success.data[0].leadDetails[0].claim_taken == "yes") {
									this.quoteReqObj.ncb = "Claim taken"
								} else {
									let detailsData = success.data[0].leadDetails
									let finalCurrentNcb = []
									success.data[0].ncbDrop.forEach(element => {
										finalCurrentNcb.push({ item_id: element.id, item_text: element.current_ncb });
										if (detailsData.length && element.id == detailsData[0].current_ncb) {
											// this.f.current_ncb.setValue([{ item_id: element.id, item_text: element.current_ncb }])
											this.quoteReqObj.ncb = element.current_ncb
										}
									});
									// this.quoteReqObj.ncb = success.data[0].leadDetails[0].current_ncb
								}
								this.quoteReqObj.prevInsurer = success.data[0].previousInsurer[0].item_text
								let addOnsArry = success.data[0].selectedAddOn
								let tempStr = ""
								for (let i = 0; i < addOnsArry.length; i++) {
									if (i == 0) {
										tempStr += (i + 1) + ". " + addOnsArry[i].item_text
									} else {
										tempStr += "/n" + (i + 1) + ". " + addOnsArry[i].item_text + "/r"
									}
								}

								let insurerArry = success.data[0].selectedInsurance
								let tempStr2 = ""
								for (let i = 0; i < insurerArry.length; i++) {
									if (i == 0) {
										tempStr2 += (i + 1) + ". " + insurerArry[i].item_text
									} else {
										tempStr2 += "/n" + (i + 1) + ". " + insurerArry[i].item_text + "/r"
									}
								}
								this.quoteReqObj.insurer = tempStr2

								this.quoteReqObj.addOns = tempStr

								let detailsData = success.data[0].leadDetails
								let planData = success.data[0].Ptype
								let planTypeArray = []
								planData.forEach(ele => {
									planTypeArray.push(ele.plan_type)
								});
								let setArray = [];
								success.data[0].policyTypeDrop.forEach(element => {
									if (detailsData.length && planTypeArray.includes(element.id)) {
										setArray.push({ item_id: element.id, item_text: element.policy_type });
									}
								});
								for (let i = 0; i < setArray.length; i++) {
									if (i == 0) {
										this.quoteReqObj.plan += (i + 1) + ". " + setArray[i].item_text
									} else {
										this.quoteReqObj.plan += "/n" + (i + 1) + ". " + setArray[i].item_text + "/r"
									}
									console.log(setArray);

								}


								this.showGroup("payment_link");
								this.showGroup("quote_shared");
								this.showGroup("quote_required");

							}
						}
					},
						error => {
							alert(error.error.error_msg);
						});

			}
		},
			error => {
				alert(error.error.error_msg);
			});



		this.getLeadsdata(this.listType, { page: 1 });
		this.filterLeads = this.formBuilder.group({
			from_date: [''],
			to_date: [''],
			premium_from: [''],
			premium_to: ['']
		});
		console.log("00000000000000000000000000000000000000000000")
		let temData = {}
		this.popUpData = localStorage.getItem("lastname")
		this.onCellClick(this.popUpData)
		console.log(this.popUpData);


	}
	get submitMessage() { return this._submitMessage; }
	private _submitMessage = '';

	openModal() {
		console.log("Opening Modal");
		this.myModal.show()
	}

	onSubmit(form: NgForm) {
		this.isLoading = true;
		this._submitMessage = 'Submitted. Form value is ' + JSON.stringify(form.value.dpt);
		this.backendCall.getFilteredList(form.value).subscribe(Response => {
			this.isLoading = false;
			if (Response) {
				this.data = Response.data[0];
				this.length = Response.count;
				this.numPages = Math.ceil(Number(Response.count) / 10)
				let filteredData = this.changeFilter(this.data, this.config);
				let sortedData = this.changeSort(filteredData, this.config);
				this.rows = this.page && this.config.paging ? this.changePage(this.page, sortedData) : sortedData;
				let dataInTable = ``

				if (this.rows.length == 0) {
					dataInTable += `
					<div style=" height: 450px; display: flex; justify-content: center; align-items: center " ><h3>No Such Data Available...</h3></div>
					`
					document.getElementById("emptyTable").innerHTML = dataInTable
				}
			}
		},
			error => {
				alert(error.error.error_msg);
				this.router.navigate(['/dashboard/analytics']);
			});
	}

	submit() {
		this.filterObject = {
			from_date_expiry: this.filterLeads.controls.from_date.value || "",
			to_date_expiry: this.filterLeads.controls.to_date.value || "",
			premium_from: this.filterLeads.controls.premium_from.value || "",
			premium_to: this.filterLeads.controls.premium_to.value || "",
			page: 1
		}
		console.log(this.filterObject)
		this.getLeadsdata(this.listType, this.filterObject);
	}

	reset() {
		this.filterLeads.controls.from_date.reset();
		this.filterLeads.controls.to_date.reset();
		this.filterLeads.controls.premium_from.reset();
		this.filterLeads.controls.premium_to.reset();
		this.getLeadsdata(this.listType, null);
	}

	viewHistory(leadID: any) {
		this.backendCall.getActivityHistory(leadID).subscribe(Response => {
			this.isLoading = false;
			if (Response) {
				console.log(Response);
				this.activityHistory = Response.data
			}
		},
			error => {
				alert(error.error.error_msg);
			});
		this.activities.show();
	}

	openDialyog() {
		const dialogRef = this.dialog.open(this.templateRef);

		dialogRef.afterClosed().subscribe(result => {

		});
	}

	public changePage(page: any, data: Array<any> = this.data): Array<any> {

		return data;
	}

	public changeSort(data: any, config: any): any {
		if (!config.sorting) {
			return data;
		}

		let columns = this.config.sorting.columns || [];
		let columnName: string = void 0;
		let sort: string = void 0;

		for (let i = 0; i < columns.length; i++) {
			if (columns[i].sort !== '' && columns[i].sort !== false) {
				columnName = columns[i].name;
				sort = columns[i].sort;
			}
		}

		if (!columnName) {
			return data;
		}

		// simple sorting
		return data.sort((previous: any, current: any) => {
			if (previous[columnName] > current[columnName]) {
				return sort === 'desc' ? -1 : 1;
			} else if (previous[columnName] < current[columnName]) {
				return sort === 'asc' ? -1 : 1;
			}
			return 0;
		});
	}

	public changeFilter(data: any, config: any): any {
		let filteredData: Array<any> = data;
		this.columns.forEach((column: any) => {
			if (column.filtering) {
				filteredData = filteredData.filter((item: any) => {
					if (item[column.name] == "" || item[column.name] == "null" || item[column.name] == null) {
						item[column.name] = "NA";
					}

					return item[column.name].toString().toLowerCase().match(column.filtering.filterString.toString().toLowerCase());

				});
			}
		});
		if (!config.filtering) {
			return filteredData;
		}
		if (config.filtering.columnName) {
			return filteredData.filter((item: any) =>
				item[config.filtering.columnName].toString().toLowerCase().match(this.config.filtering.filterString.toString().toLowerCase()));
		}

		let tempArray: Array<any> = [];
		filteredData.forEach((item: any) => {
			let flag = false;
			this.columns.forEach((column: any) => {
				if (column.name != "action" && (item[column.name].toString().toLowerCase().match(this.config.filtering.filterString.toString().toLowerCase())) || (item['mobile'].match(this.config.filtering.filterString))) {
					flag = true;
				}
			});
			if (flag) {
				item.action =
					` 
						<span>
							<a href='/#/lead/create?hash=${btoa(item.id)}' > 
							<i class="fas fa-edit"></i> Edit</a>
						</span>					
						`;
				tempArray.push(item);
			}
		});
		filteredData = tempArray;
		return filteredData;
	}

	public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
		console.log("are u?", page)
		if (config.filtering) {
			Object.assign(this.config.filtering, config.filtering);
		}

		if (config.sorting) {
			Object.assign(this.config.sorting, config.sorting);
		}
		let filteredData = this.changeFilter(this.data, this.config);
		let sortedData = this.changeSort(filteredData, this.config);
		this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData;

	}

	public onCellClick(data: any): any {
		this.router.navigateByUrl(`pushTicket/ticket-management?leadData=${this.leadData}&type=${this.route.snapshot.queryParamMap.get('type')}`);
		console.log("I Am Working", data)
		if (data.column != 'action') {
			//this.mydiv.show();
			this.currentData = [data];
			console.log(this.currentData);
			// this.myModal.show();
		}
	}

	public onMsgClick(): any {
		console.log("I Am Working ")
	}

	public onLeadDetails(): any {
		console.log("herterere");
		this.leadModal.show();

	}

	public getLeadsdata(type: any, filterObject): any {
		this.backendCall.getLeadList(type, filterObject).subscribe(Response => {
			this.isLoading = false;
			if (Response) {
				console.log(Response);
				this.data = Response.data;
				this.length = Response.count;
				this.numPages = Math.ceil(Number(Response.count) / 10)
				let filteredData = this.changeFilter(this.data, this.config);
				let sortedData = this.changeSort(filteredData, this.config);
				this.rows = this.page && this.config.paging ? this.changePage(this.page, sortedData) : sortedData;
				let dataInTable = ``

				if (this.rows.length == 0) {
					dataInTable += `
					<div style=" height: 450px; display: flex; justify-content: center; align-items: center " ><h3>No Lead Entries</h3></div>
					`
					document.getElementById("emptyTable").innerHTML = dataInTable
				}
			}
		},
			error => {
				alert(error.error.error_msg);
				this.router.navigate(['/dashboard/analytics']);
			});
	}

	public dataDetailInModal(modal, event: any) {
		if (event.target.checked)
			modal.show();
	}

	updateDispostion(statusType, statusCode) {
		this.statusType = statusType;
		this.statusCode = statusCode;
		console.log("update dus type check", this.type);

		if (this.statusCode == '1') {
			this.router.navigate(['connect/Disposition'], { queryParams: { despositionType: "connected", leadID: this.leadData, type: this.type } })
		} else {
			this.router.navigate(['connect/Disposition'], { queryParams: { despositionType: "notConnected", leadID: this.leadData, type: this.type } })
		}

	}

	showdataquote(til) {
		this.quote = til;
		console.log(this.quote);
	}

	gobackBtn() {
		this.router.navigate(['lead/list'], { queryParams: { type: this.route.snapshot.queryParamMap.get('type') } });
	}

	showTxtBox() {
		this.addNum.show()
	}

	showGroup(status) {
		this.quote = status;
		console.log(this.quote);
		if (status == "quote_required") {
			this.backendCall.getLeadDetails(
				{
					id: this.leadData,
					status: "5"
				}
			)
				.subscribe(success => {
					if (success) {
						if (success.data.length) {
							console.log(success);
							this.quoteReqObj.idv = success.data[0].leadDetails[0].idv
							if (success.data[0].leadDetails[0].claim_taken == "yes") {
								this.quoteReqObj.ncb = "Claim taken"
							} else {
								let detailsData = success.data[0].leadDetails
								let finalCurrentNcb = []
								success.data[0].ncbDrop.forEach(element => {
									finalCurrentNcb.push({ item_id: element.id, item_text: element.current_ncb });
									if (detailsData.length && element.id == detailsData[0].current_ncb) {
										// this.f.current_ncb.setValue([{ item_id: element.id, item_text: element.current_ncb }])
										this.quoteReqObj.ncb = element.current_ncb
									}
								});
								// this.quoteReqObj.ncb = success.data[0].leadDetails[0].current_ncb
							}
							this.quoteReqObj.prevInsurer = success.data[0].previousInsurer[0].item_text
							let addOnsArry = success.data[0].selectedAddOn
							let tempStr = ""
							for (let i = 0; i < addOnsArry.length; i++) {
								if (i == 0) {
									tempStr += (i + 1) + ". " + addOnsArry[i].item_text
								} else {
									tempStr += "\n" + (i + 1) + ". " + addOnsArry[i].item_text + "\r"
								}
							}

							let insurerArry = success.data[0].selectedInsurance
							let tempStr2 = ""

							for (let i = 0; i < insurerArry.length; i++) {
								if (i == 0) {
									tempStr2 += (i + 1) + ". " + insurerArry[i].item_text
								} else {
									tempStr2 += "\n" + (i + 1) + ". " + insurerArry[i].item_text + "\r"
								}
							}
							this.quoteReqObj.insurer = tempStr2

							this.quoteReqObj.addOns = tempStr

							let detailsData = success.data[0].leadDetails
							let planData = success.data[0].Ptype
							let planTypeArray = []
							planData.forEach(ele => {
								planTypeArray.push(ele.plan_type)
							});
							let setArray = [];
							success.data[0].policyTypeDrop.forEach(element => {
								if (detailsData.length && planTypeArray.includes(element.id)) {
									setArray.push({ item_id: element.id, item_text: element.policy_type });
								}
							});
							this.quoteReqObj.plan = ""
							for (let i = 0; i < setArray.length; i++) {
								if (i == 0) {
									this.quoteReqObj.plan += (i + 1) + ". " + setArray[i].item_text
								} else {
									this.quoteReqObj.plan += "\n" + (i + 1) + ". " + setArray[i].item_text + "\r"
								}
								console.log(setArray);

							}
							console.log(this.quoteReqObj.plan);

							console.log(this.quoteReqObj.idv || this.quoteReqObj.ncb || this.quoteReqObj.prevInsurer || this.quoteReqObj.insurer || this.quoteReqObj.addOns || this.quoteReqObj.plan)
							if (this.quoteReqObj.idv || this.quoteReqObj.ncb || this.quoteReqObj.prevInsurer || this.quoteReqObj.insurer || this.quoteReqObj.addOns || this.quoteReqObj.plan) {
								this.required = true;
							}

						}
					}
				},
					error => {
						alert(error.error.error_msg);
					});
		}
		else if (status == "quote_shared") {
			this.backendCall.fetchInsPol(this.leadData, "4")
				.subscribe(success => {
					let tempArray = []
					for (let i = 0; i < success.data.length; i++) {
						tempArray.push({ "ins": { "item_text": success.data[i].name }, "policy": { "item_text": success.data[i].policy_type }, "img": { "item_link": success.data[i].url }, "leadid": this.leadData })
					}

					this.insPolicyArry = tempArray
					this.shared = this.insPolicyArry.length == 0 ? false : true;
					console.log(tempArray);

				},
					error => {
						//   this.message = 'Something went wrong while Updating entry';
					});
		} else {
			this.backendCall.fetchInsPol(this.leadData, "9")
				.subscribe(success => {
					let tempArray = []
					for (let i = 0; i < success.data.length; i++) {
						tempArray.push({ "ins": { "item_text": success.data[i].name }, "policy": { "item_text": success.data[i].policy_type }, "img": success.data[i].payment_link, "leadid": this.leadData })
					}

					this.insPolicyArry = tempArray;
					this.paymentLink = this.insPolicyArry.length == 0 ? false : true;

					console.log(tempArray);

				},
					error => {
						//   this.message = 'Something went wrong while Updating entry';
					});
		}
	}


	showForm(dispositionType, dispoCode) {
		console.log(dispoCode, "dispoCodedispoCodedispoCodedispoCode")
		this.dispositionType = dispositionType;
		this.dispoCode = dispoCode;
		console.log(dispositionType, "dispotype");
		let despositionType;
		if (dispoCode == 5) {
			despositionType = "connected"
		} else {
			despositionType = "notConnected"

		}
		console.log("after select", this.route.snapshot.queryParamMap.get('type'))

		this.router.navigate(['connect/quoteRequired'], { queryParams: { dispoCode: dispoCode, dispositionType: despositionType, leadData: this.leadData, type: this.route.snapshot.queryParamMap.get('type') } });
	}


	openNote() {
		// alert("note")
		this.addNote.show();
	}

	viewnote() {
		// alert("note")
		// this.viewNote.show();
	}

	openDropdown() {
		this.OpenAll = !this.OpenAll
		this.show = document.getElementById("head")
		this.show1 = document.getElementById("head1")
		this.show2 = document.getElementById("head2")
		this.show3 = document.getElementById("head3")
		this.show4 = document.getElementById("head4")
		console.log(this.head1, "####head1")
		console.log(this.head, "####head")
		console.log(this.head2, "####head2")
		console.log(this.head3, "####head3")
		console.log(this.head4, "####head4")


		if (this.head) {
			this.show.click();
			this.show.click();
			this.head = !this.head;

		} else {
			this.show.click();
			this.head = !this.head;

		}

		if (this.head1) {
			this.show1.click();
			this.show1.click();
			this.head1 = !this.head1;



		} else {
			this.show1.click();
			this.head1 = !this.head1;

		}

		if (this.head2) {
			this.show2.click();
			this.show2.click();
			this.head2 = !this.head2;



		} else {
			this.show2.click();
			this.head2 = !this.head2;

		}

		if (this.head3) {
			this.show3.click();
			this.show3.click();
			this.head3 = !this.head3;



		} else {
			this.show3.click();
			this.head3 = !this.head3;

		}

		if (this.head4) {
			this.show4.click();
			this.show4.click();
			this.head4 = !this.head4;



		} else {
			this.show4.click();
			this.head4 = !this.head4;

		}

	}

	borderradius(type) {
		console.log("insdie borderadius ################################################")
		console.log(this.head1, "####head1")
		console.log(this.head, "####head")
		console.log(this.head2, "####head2")
		console.log(this.head3, "####head3")
		console.log(this.head4, "####head4")
		console.log("insdie borderadius ################################################")

		if (type == "head") {
			this.head = !this.head;
		}
		else if (type == "head1") {
			this.head1 = !this.head1;

		}
		else if (type == "head4") {
			this.head4 = !this.head4;

		}
		else if (type == "head2") {
			this.head2 = !this.head2;

		}
		else if (type == "head3") {
			this.head3 = !this.head3;

		}

		console.log("en ding borderadius ################################################")


		console.log(this.head1, "####head1")
		console.log(this.head, "####head")
		console.log(this.head2, "####head2")
		console.log(this.head3, "####head3")
		console.log(this.head4, "####head4")
		console.log("en ding borderadius ################################################")

	}


	toggleNotes() {
		this.isAddNote = !this.isAddNote
		if (!this.isAddNote) {
			this.backendCall.getNotes(this.leadData).subscribe(Response => {
				this.isLoading = false;
				if (Response) {
					console.log(Response);
					let rnotes = []
					rnotes = Response.data
					console.log(rnotes.length);
					let ndata = []
					for (let i = (rnotes.length - 1); i > -1; i--) {


						ndata.push(rnotes[i]);

					}
					console.log(ndata);
					this.notesData = ndata
				}
			},
				error => {
					alert(error.error.error_msg);
				});
		}
	}

	notePadHide() {
		this.addNote.hide();
		this.isAddNote = true;
	}

	submitNote() {
		if (!this.noteData) {
			alert("1231")
		}

		this.backendCall.addNotes(this.leadData, this.noteData).subscribe(Response => {
			this.isLoading = false;
			this.addNote.hide();
			if (Response) {
				console.log(Response);
				this.noteData = ""
			}
		},
			error => {
				alert(error.error.error_msg);
			});
	}

	tranvieww() {
		this.transshow.show()
	}

	submitWhatsappNum() {
		console.log(this.whatsappNum);
		this.addedWhatsappMo = this.whatsappNum;
		this.backendCall.addWhatsappNum(this.leadData, this.whatsappNum)
			.subscribe(success => {
				//alert("Number added successfully!")
				this.closebutton.nativeElement.click();
			},
				error => {
					this.message = 'Something went wrong while Updating entry';
				});
	}

	openCalender() {
		const calender = document.getElementById("openInAppointment");
		const appointmentBtn = document.getElementById("appointment");
		appointmentBtn.style.display = "none"
		calender.style.display = "block"
		// alert("helo")
	}

	selectedDate(date: any) {
		const calender = document.getElementById("openInAppointment");
		const appointmentBtn = document.getElementById("appointment");
		// STORING INPUT VALUE
		const selectedValue = date.target.value
		// this.f.follow_up_datetime.setValue(selectedValue);
		appointmentBtn.style.display = "block"
		calender.style.display = "none"
		// TESTING
		alert("selected date is --> " + selectedValue)
	}

	copyMessage(val) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		this.copiedText = selBox.value;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);

		if (this.copiedText) {
			this.toast = true
		}

		setTimeout(() => {
			if (this.toast = true) {
				this.toast = false
			}
		}, 1000)
	}

	openOffline() {
		this.router.navigate(['offline/offline-quote']);
		// offline/offline-quote
	}

	handleClaim() {
		this.claimEvent.show()
		this.getClaimDetails('')
		console.log(this.reg_number);
		this.isLoadingClaim = true
	}

	handleClaimRetry() {
		this.getClaimDetails('retry');
	}

	getClaimDetails(stage: any) {

		if (stage === 'retry') {
			this.isLoadingClaim = true
			this.getClaimDetailErr = false
		}

		this.backendCall.getClaimDetails(this.reg_number).subscribe(Response => {
			if (Response) {
				this.claimDetails = Response.data.data.ClaimDetails;
				this.policyDetails = Response.data.data.PolicyDetails;
				this.totalClaims = this.claimDetails.length;
				this.isLoadingClaim = false;

				console.log(Response.data.data.error, 'Status');

				if (this.claimDetails === 'Claim Record not Found in given reg no') {
					this.noClaimDetails = true;
					this.isLoadingClaim = false;
				} else {
					this.noClaimDetails = false;
					this.isLoadingClaim = false;
					this.claimDetails = Response.data.data.ClaimDetails.reverse();
				}

				if (this.policyDetails === 'policy data not found in given regno') {
					this.noPolicyDetails = true;
					this.isLoadingClaim = false;
				} else {
					this.noPolicyDetails = false;
					this.isLoadingClaim = false;
				}

				if (Response === null) {
					this.getClaimDetailErr = true
					this.isLoadingClaim = false;
				} else {
					this.getClaimDetailErr = false
					this.isLoadingClaim = false;
				}

				if (Response.data.txt === 'Authentication Needed (token not found or expired)') {
					this.getClaimDetailErr = true
					this.isLoadingClaim = false;
				} else {
					this.getClaimDetailErr = false
					this.isLoadingClaim = false;
				}


			}
		},
			error => {
				console.log(error)
				this.getClaimDetailErr = true
				this.isLoadingClaim = false;
			});
	}

}