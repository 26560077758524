import { Component, OnInit, ViewChild } from '@angular/core';
import { BackendCallService } from '../../services/backend-call.service'
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pull-data',
  templateUrl: './pull-data.component.html',
  styleUrls: ['./pull-data.component.scss']
})
export class PullDataComponent implements OnInit {
  @ViewChild('history', { static: true }) activities: { show: () => void; };
  storeFromDate: any;
  storeToDate: any;
  stepOne = true;
  stepTwo = false;
  stepThree = false;
  isLoading: boolean;
  stageParams: any;
  pulldata: any;
  pulldatalength: any;
  pulldatapremium: any;
  pushdatainfo: any;

  constructor(private backendCall: BackendCallService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams
      .subscribe(params => {
        console.log(params);
        this.stageParams = params.stage;
        this.storeFromDate = params.fromdate;
        this.storeToDate = params.todate;
        console.log('fromQueryParams >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.stageParams, this.storeFromDate, this.storeToDate);
      })

    if (this.stageParams === 'fromRecordListComponent') {
      this.stepOne = false;
      this.stepTwo = true;
      this.fetchData()
    }


  }

  dateChangeEvent(value: any, stage, event) {
    if (stage === 'from') {
      this.storeFromDate = value;
      console.log('from---------------', this.storeFromDate);
    }
    else if (stage === 'to') {
      this.storeToDate = value;
      console.log('To---------------', this.storeToDate);
    }

  }

  fetchData() {
    console.log('DATE RANGE ------- FROM', this.storeFromDate, '-----To----', this.storeToDate);
    this.stepOne = false;
    this.stepTwo = true;

    this.backendCall.pulldatafrommaster(this.storeFromDate, this.storeToDate).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        console.log(Response);
        this.pulldata = Response.data
        this.pulldatalength = this.pulldata.length
        console.log(this.pulldata, this.pulldatalength, this.pulldata[0].total_premium);

        this.pulldatapremium = 0
        for (let i = 0; i < this.pulldatalength; i++) {
          this.pulldatapremium += this.pulldata[i].total_premium
        }
        console.log(this.pulldatapremium);
      }
    },
      error => {
        alert(error.error.error_msg);
      });
  }

  goBack(stage) {
    if (stage === 'stepTwo') {
      this.stepOne = true;
      this.stepTwo = false;
    }
    else if (stage === 'stepThree') {
      this.stepTwo = true;
      this.stepThree = false;
    }
  }

  confirm() {
    // alert('confirmation')
    // this.router.navigate([`/lead/list`])
    this.backendCall.pushintolead({ fromdate: this.storeFromDate, todate: this.storeToDate }).subscribe(Response => {
      if (Response) {
        alert("lead pushed successfully")
        this.router.navigate(['/lead/list']);
      }
    },
      error => {
        //console.log(error);
        alert(error.error.error_msg);
        window.location.reload();
        this.router.navigate(['pull-data']);
      });
  }

  showHistory() {
    this.stepTwo = false;
    this.stepThree = true;
  }

  showDelete(item) {
    this.activities.show();
  }

  route() {
    let stage = 'fromPullComponent'
    // this.router.navigate([/record-list], { queryParams: { id: 'ww' });
    this.router.navigate([`/record-list`], { queryParams: { stage: stage, fromdate: this.storeFromDate, todate: this.storeToDate } })
  }

}
