import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AuthService } from './services/auth.service';
import { BackendCallService } from './services/backend-call.service';
import { TokenInterceptor } from './services/token.interceptor';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2TableModule } from 'ngx-datatable/ng2-table';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import { MatDialogModule } from '@angular/material';

import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TicketOverviewComponent } from './ticket/ticket-overview/ticket-overview.component';
import { TicketManagementComponent } from './pushTicket/ticket-management/ticket-management.component';
import { QuickSearchComponent } from './quick-search/quick-search.component';
import { ConnectTabComponent } from './dispositon/connect-tab/connect-tab.component';
import { NotConnectComponent } from './dispo/not-connect/not-connect.component';
import { QuoteRequiredComponent } from './dispositionForm/quote-required/quote-required.component';
import { InsurerTrackingComponent } from './track-insurer/insurer-tracking/insurer-tracking.component';
import { MonthTrackingComponent } from './track-month/month-tracking/month-tracking.component';
import { DateTrackingComponent } from './track-date/date-tracking/date-tracking.component';
import { TrackDispositonComponent } from './track-dispositon/track-dispositon.component';
import { YearTrackingComponent } from './track-year/year-tracking/year-tracking.component';
import { OfflineQuoteComponent } from './offline/offline-quote/offline-quote.component';
import { RecordManagementComponent } from './records/record-management/record-management.component';
import { RecordListComponent } from './records/record-list/record-list.component';
import { DataOverviewComponent } from './records/data-overview/data-overview.component';
import { DataReconComponent } from './recon/data-recon/data-recon.component';
import { SaleReconComponent } from './recon/sale-recon/sale-recon.component';
import { RawReconComponent } from './recon/raw-recon/raw-recon.component';
import { UserProfileManagementComponent } from './user-profile/user-profile-management/user-profile-management.component';
import { SaleManagementComponent } from './sales-management/sale-management/sale-management.component';
import { SaleUploadComponent } from './sales-management/sale-upload/sale-upload.component';
import { SaleEntryComponent } from './sales-management/sale-entry/sale-entry.component';
import { GridComponent } from './grid-management/grid/grid.component';
import { PullDataComponent } from './pull-management/pull-data/pull-data.component';
import { OrderByPipe } from './offline/offline-quote/order-by.pipe';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    TicketOverviewComponent,
    TicketManagementComponent,
    QuickSearchComponent,
    ConnectTabComponent,
    NotConnectComponent,
    QuoteRequiredComponent,
    InsurerTrackingComponent,
    MonthTrackingComponent,
    DateTrackingComponent,
    TrackDispositonComponent,
    YearTrackingComponent,
    OfflineQuoteComponent,
    RecordManagementComponent,
    RecordListComponent,
    DataOverviewComponent,
    DataReconComponent,
    SaleReconComponent,
    RawReconComponent,
    UserProfileManagementComponent,
    SaleManagementComponent,
    SaleUploadComponent,
    SaleEntryComponent,
    GridComponent,
    PullDataComponent,
    OrderByPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    Ng2TableModule,
    NgbModule,
    NgbPaginationModule,
    MatDialogModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [NavigationItem, { provide: LocationStrategy, useClass: HashLocationStrategy }, AuthService, BackendCallService,CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }], /*{ provide: LocationStrategy, useClass: HashLocationStrategy }*/
  bootstrap: [AppComponent]
})
export class AppModule { }
