import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { BackendCallService } from 'src/app/services/backend-call.service';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-record-list',
  templateUrl: './record-list.component.html',
  styleUrls: ['./record-list.component.scss']
})
export class RecordListComponent implements OnInit {
  @ViewChild('history', { static: true }) activities: { show: () => void; };
  @ViewChild('exampleModalCenter', { static: true }) myModal;

  public TableData: Array<any> = []
	public page: number = 1;
	public itemsPerPage: number = 10;
	public maxSize: number = 5;
	public numPages: number = 1;
	public length: number = 0;

  public rows: Array<any> = [
	];

  public config: any = {
		paging: true,
		className: ['table-striped', 'table-bordered', 'm-b-0']
	};

  isVerified = true;
  firstStep = true;
  isRejected = false;
  masterleadhisid: any;
  isLoading: boolean;
  private masterleadData: Array<any> = this.TableData;
  // masterleadData: any;
  countmasleads: any;
  showTable = false;
  isDuplicate: any;
  totalmaslead: any;
  duplicatesmaslead: any;
  uniquemaslead: any;
  type: string;

  head: boolean = false;
  head1: boolean = false;
  head2: boolean = false;
  head3: boolean = false;
  head4: boolean = false;
  OpenAll: boolean = true;
  show: HTMLElement;
  show1: HTMLElement;
  show2: HTMLElement;
  show3: HTMLElement;
  show4: HTMLElement;
  currentData: any[];
  stage: any;
  fromdate: any;
  todate: any;
  renewals: any;

  constructor(private _location: Location, private router: Router, private formBuilder: FormBuilder, public dialog: MatDialog, private backendCall: BackendCallService, private activatedRoute: ActivatedRoute) {
    this.masterleadhisid = this.activatedRoute.snapshot.queryParamMap.get('uplopedFileid');
    console.log(this.masterleadhisid);

  }

  ngOnInit() {

    this.activatedRoute.queryParams
      .subscribe(params => {
        console.log(params);
        this.stage = params.stage;
        this.fromdate = params.fromdate;
        this.todate = params.todate;
        console.log('fromQueryParams >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.stage,this.fromdate,this.todate);
      })

    if (this.stage === 'fromPullComponent') {
      this.showTable = true;
      this.firstStep = false;
      this.type = "TOTAL"
      this.masterleads();
    }

    // this.masterleads();
    this.countmasterleads();
    if (this.isDuplicate === 'yes') {
      this.isRejected = true;
    } else {
      this.isVerified = true;
    }
  }

  goBack(stage: any) {

    if (this.stage) {
      let stage = 'fromRecordListComponent'
      this.router.navigate([`/pull-data`], { queryParams: { stage: stage,fromdate:this.fromdate,todate:this.todate } })
    } else {
      if (stage === 'history') {
        this.showTable = false;
        this.firstStep = true;
      }
      else {
        this._location.back();
      }
    }


  }

  showRenewalList(regnumber) {
    this.activities.show();
    console.log(regnumber);
		this.backendCall.getrenewals(regnumber).subscribe(Response => {
			this.isLoading = false;
			if (Response) {
				console.log(Response);
				this.renewals = Response.data
				console.log(this.renewals);
			}
		},
			error => {
				alert(error.error.error_msg);
			});
  }

  public changePage(page: any, data: Array<any> = this.masterleadData): Array<any> {

		return data;
	}

  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
		console.log("are u?", config)
		this.page = page
		this.masterleads()
	}

  masterleads() {
    let uphisid = this.masterleadhisid
    let type = this.type
    this.backendCall.getmasterleadData(uphisid, type,this.fromdate,this.todate,this.page).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        console.log(Response);
        this.masterleadData = Response.data
        this.countmasleads = Response.count
        this.numPages = Math.ceil(Number(Response.count) / 10)
      }
    },
      error => {
        alert(error.error.error_msg);
      });
  }

  countmasterleads() {
    let uphisid = this.masterleadhisid
    this.backendCall.getcounttotalduplicateunique(uphisid).subscribe(Response => {
      this.isLoading = false;
      if (Response) {
        console.log(Response);
        // this.masterleadData = Response.data
        // this.countmasleads = this.masterleadData.length
        this.totalmaslead = Response.data[0].Total
        this.duplicatesmaslead = Response.data[1].Total
        this.uniquemaslead = Response.data[2].Total
      }
    },
      error => {
        alert(error.error.error_msg);
      });
  }

  showTableList() {
    this.showTable = true;
    this.firstStep = false;
    this.type = "UNIQUE"
    this.masterleads();
  }

  showTableList1() {
    this.showTable = true;
    this.firstStep = false;
    this.type = "DUPLICATE"
    this.masterleads();
  }

  showTableList2() {
    this.showTable = true;
    this.firstStep = false;
    this.type = "TOTAL"
    this.masterleads();
  }

  showPopUp(data: any) {
    this.currentData = [data]
    this.myModal.show()
  }

  borderradius(type) {
    console.log("insdie borderadius ################################################")
    console.log(this.head1, "####head1")
    console.log(this.head, "####head")
    console.log(this.head2, "####head2")
    console.log(this.head3, "####head3")
    console.log(this.head4, "####head4")
    console.log("insdie borderadius ################################################")

    if (type == "head") {
      this.head = !this.head;
    }
    else if (type == "head1") {
      this.head1 = !this.head1;

    }
    else if (type == "head4") {
      this.head4 = !this.head4;

    }
    else if (type == "head2") {
      this.head2 = !this.head2;

    }
    else if (type == "head3") {
      this.head3 = !this.head3;

    }

    console.log("en ding borderadius ################################################")


    console.log(this.head1, "####head1")
    console.log(this.head, "####head")
    console.log(this.head2, "####head2")
    console.log(this.head3, "####head3")
    console.log(this.head4, "####head4")
    console.log("en ding borderadius ################################################")

  }

  openDropdown() {
    this.OpenAll = !this.OpenAll
    this.show = document.getElementById("head")
    this.show1 = document.getElementById("head1")
    this.show2 = document.getElementById("head2")
    this.show3 = document.getElementById("head3")
    this.show4 = document.getElementById("head4")
    console.log(this.head1, "####head1")
    console.log(this.head, "####head")
    console.log(this.head2, "####head2")
    console.log(this.head3, "####head3")
    console.log(this.head4, "####head4")


    if (this.head) {
      this.show.click();
      this.show.click();
      this.head = !this.head;

    } else {
      this.show.click();
      this.head = !this.head;

    }

    if (this.head1) {
      this.show1.click();
      this.show1.click();
      this.head1 = !this.head1;



    } else {
      this.show1.click();
      this.head1 = !this.head1;

    }

    if (this.head2) {
      this.show2.click();
      this.show2.click();
      this.head2 = !this.head2;



    } else {
      this.show2.click();
      this.head2 = !this.head2;

    }

    if (this.head3) {
      this.show3.click();
      this.show3.click();
      this.head3 = !this.head3;



    } else {
      this.show3.click();
      this.head3 = !this.head3;

    }

    if (this.head4) {
      this.show4.click();
      this.show4.click();
      this.head4 = !this.head4;



    } else {
      this.show4.click();
      this.head4 = !this.head4;

    }

  }


}
