import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
// import { formatDate } from '@angular/common';
// import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { element } from 'protractor';
import Swal from 'sweetalert2';
import { BackendCallService } from '../../services/backend-call.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-update-lead-status',
  templateUrl: './quote-required.component.html',
  styleUrls: ['./quote-required.component.scss']
})
export class QuoteRequiredComponent implements OnInit {
  @ViewChild('paymentLink', { static: true }) linkModal;
  @ViewChild('closebutton', { static: true }) closebutton;
  @ViewChild('myTable', { static: true }) mytable;
  @ViewChild('closeButtonNotes', { static: true }) closeButtonNotes;
  @ViewChild('notepad', { static: true }) addNote: any;
  @ViewChild('whatsapp', { static: true }) addNum: { show: () => void; };
  @ViewChild('ViewBtn', { static: true }) linkButton: { show: () => void; };

  updateLeadForm: FormGroup;
  globalDataSet: any;
  successAlert: boolean = false;
  policyUrl: any;
  submitted: boolean;
  failedAlert: boolean = false;
  message: string;
  leadId = null;
  insuranceDataArray: any;
  addOnDataArray: any;
  reasonArray: any;
  statusDrop: any;
  statusTypeDrop: any;
  selectedItems: any;
  dropdownSettings: IDropdownSettings = {};
  dropdownSettingsForStatus: IDropdownSettings = {};
  currentSelectedStatus: any;
  currentSelectedStatusType: any;
  fetchedStatusData: any;
  currentRole: any;
  type: any;
  browseArray: any = [];
  policyArray: any = [];
  policyRecivedArray: any = [];
  updatedPolicyArray: any = [];
  submitStatus: any = false;
  fileUploaded: any = [];
  latestPolicyCopy: any = null;
  redirectType: any;
  assign_closer_data: any;
  sucessStatus: any;
  policy_type: any;
  language: any;
  business_source_data: any;
  monthDropData: any;
  inspectionData: any;
  policy_issued_data: any;
  source_channel: any;
  current_ncb: any;
  current_insurer: any;
  teamDataDetails: any;
  ncbDecider: any = true;
  cashbackDecider: any = true;
  addonDecider: any = true;
  competitiveDecider: any = false;
  policyRecivedDecider: any = true;
  currentPolicyDecider: any = true;
  policyIssuedDecider: any = true;
  policyTypeClaimHide: any = false;
  newCarDecider: any = true;
  oldFollowUpDateAndTime: any = false;
  idvDecider: any = false;
  loggedInAgentName = localStorage.getItem('name');
  make: any;
  leadID = ""
  despositionType = ""
  quoteUpload: string;
  insPolicyArry = [];
  enteredInsPolArry = []
  selectedIns: any
  selectedpol: any
  selectedInsArry = []
  selectedPolArry = []
  selectedPreInsArry = []
  selectedAddOnsArry = []
  idIncreament = 1
  isLoading: boolean;
  notesData: any;
  isAddNote = true;
  noteData: any;
  hidden = true

  quoteReqObj = {
    idv: "",
    ncb: "",
    plan: "",
    insurer: "",
    addOns: "",
    prevInsurer: "",
    currentPolicy: ""
  }

  imgLink: any
  addOnsArray: any[];
  whatsappNum: any;
  leadData = {};
  deSelectIns: any;
  selectedPreArry: any;
  previousSelectedArry = []
  ischecked = false;
  ischeckedinsurer = false;
  imageArry = []
  policyTypeArry = []
  myTrustedUrl: any | SafeUrl;




  // funPaste(){
  //   pasteFun();
  // }

  constructor(private activatedRoute: ActivatedRoute, private backendCall: BackendCallService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private sanitizer: DomSanitizer) {
    this.route.queryParams.subscribe(params => {
      //console.log(params['hash'])
      // this.leadId = atob(params['hash']);
      this.currentRole = localStorage.getItem('role');
      //console.log("lead id", this.leadId)
      this.leadId = this.route.snapshot.queryParamMap.get('leadData')
      this.type = this.route.snapshot.queryParamMap.get('type')
      console.log("type comm", this.route.snapshot.queryParamMap.get('type'));
    });
  }

  get f() {
    //console.log(this.updateLeadForm.controls.status_type.errors.required)
    return this.updateLeadForm.controls;
  }

  fetchImgUrl() {
    var myImgSrc = document.getElementById("paste_here").getElementsByTagName("img")[0].src;
  }

  ngOnInit() {
    this.currentSelectedStatus = this.route.snapshot.queryParamMap.get('dispoCode')
    // alert(this.currentSelectedStatus)insPolicyArry
    this.despositionType = this.activatedRoute.snapshot.queryParamMap.get('dispositionType')
    if (this.despositionType == "connected") {
      this.redirectType = 1
    } else {
      this.redirectType = 2
    }

    this.backendCall.getWhatsappNum(this.leadId)
      .subscribe(success => {
        console.log("ffffffffffffffffffffffff");
        console.log(success);
        this.whatsappNum = success.data[0].alternate_number

      },
        error => {
          this.message = 'Something went wrong while Updating entry';
        });

    if (this.currentSelectedStatus == 4 || this.currentSelectedStatus == 36 || this.currentSelectedStatus == 9 || this.currentSelectedStatus == 8) {
      this.backendCall.fetchInsPol(this.leadId, this.currentSelectedStatus)
        .subscribe(success => {
          let tempArray = []
          if (this.currentSelectedStatus == 4 || this.currentSelectedStatus == 36) {
            for (let i = 0; i < success.data.length; i++) {
              tempArray.push({ "ins": { "item_text": success.data[i].name }, "policy": { "item_text": success.data[i].policy_type }, "img": [success.data[i].url], "leadid": this.leadId })
            }
          }
          else {
            for (let i = 0; i < success.data.length; i++) {
              tempArray.push({ "ins": { "item_text": success.data[i].name }, "policy": { "item_text": success.data[i].policy_type }, "img": success.data[i].payment_link, "leadid": this.leadId })
            }
          }

          this.insPolicyArry = tempArray
          this.insPolicyArry.push({ "insptId": this.idIncreament, "ins": "", "policy": "", "img": [], "leadid": this.leadId, "added": "" })
          console.log(this.insPolicyArry);


        },
          error => {
            this.message = 'Something went wrong while Updating entry';
          });
    }
    this.getLeadDetails();
    this.getDropDownData();
    this.updateLeadForm = this.formBuilder.group({
      customer_name: [{ value: '', disabled: true }],
      customer_number: [{ value: '', disabled: true }],
      vehicle_model: [{ value: '', disabled: true }],
      vehicle_number: [{ value: '', disabled: true }],
      previous_policy_number: [{ value: '', disabled: true }],
      follow_up_datetime: [''],
      remarks: [''],
      new_car_taken: [''],
      monthDrop: [''],
      updated_policy: [''],
      idv: [''],
      claim_taken: [''],
      cashback_amount: [''],
      cashback_required: [''],
      current_policy: [''],
      insurer_name: [''],
      policy_number: [''],
      language_barrier_drop: [''],
      policy_expiry_date: [''],
      policy_copy: [''],
      payment_link: [''],
      payment_date_promised: [''],
      insuranceCompany: [''],
      policy_recived_file: [''],
      addon: [''],
      assigne_closer: [''],
      policy_recieved: [''],
      inspection: [''],
      policy_type: [''],
      browse_files: [''],
      competitive_quote: [''],
      business_source: [''],
      policy_issued_status: [''],
      current_insurer: [''],
      source_channel: [''],
      current_ncb: [''],
      whatsapp: [''],
      reason: [''],
      status_type: ['', Validators.required],
      status: ['', Validators.required]
    });

  }
  // getDropDownData() {
  //   throw new Error('Method not implemented.');
  // }
  // getLeadDetails() {
  //   throw new Error('Method not implemented.');
  // }

  // addLink() {
  // 	console.log("Adding Link");

  // 	this.linkModal.show()
  // }



  formValidation(formElement, bool) {
    let _element
    if (bool) {
      // console.log("############################", formElement);
      // console.log("&&&&&&&&&&&&&&&&&&&&&&", this.submitStatus)
      _element = formElement;

    } else {
      //console.log("***************************", this.updateLeadForm.controls[formElement]);
      _element = this.updateLeadForm.controls[formElement];
    }
    if (_element.errors) {
      return 'form-control is-invalid';

    } else if (_element.valid) {
      return 'form-control is-valid';

    } else if (_element.untouched && _element.invalid && this.submitStatus) {
      return 'form-control is-invalid';

    } else {
      return 'form-control';
    }
  }

  addLink() {
    console.log("Adding Link");

    this.linkModal.show()
  }

  async submit() {
    this.submitStatus = true;
    console.log(this.current_insurer, this.f.assigne_closer.value.length, "#############################################");

    // stop here if form is invalid
    if (this.f.assigne_closer.value.length == 0) {
      Swal.fire(
        'Oops...',
        'Please Select atleast 1 user to Assign_To Field!',
        'error')
      this.submitStatus = false;
      return;
    }
    console.log(this.enteredInsPolArry);
    let finalArry = []
    let imgArray = []
    if (this.enteredInsPolArry.length > 0) {
      if (this.currentSelectedStatus == 4 || this.currentSelectedStatus == 36) {
        for (let i = 0; i < this.enteredInsPolArry.length; i++) {
          if (this.enteredInsPolArry[i].ins.item_id) {
            let image_blob = await this.getBase64ImageFromUrl(this.enteredInsPolArry[i].img[0])
            console.log(image_blob);

            finalArry.push([this.enteredInsPolArry[i].leadid, image_blob, this.enteredInsPolArry[i].ins.item_id, this.enteredInsPolArry[i].policy.item_id])
          }
        }
      } else {
        for (let i = 0; i < this.enteredInsPolArry.length; i++) {
          if (this.enteredInsPolArry[i].ins.item_id) {
            finalArry.push([this.enteredInsPolArry[i].leadid, null, this.enteredInsPolArry[i].ins.item_id, this.enteredInsPolArry[i].policy.item_id, this.enteredInsPolArry[i].link])
          }
        }
      }
      console.log("this is final array", finalArry);

    }
    //stop if follow up date time is not changes
    let statusFordateCheck = this.currentSelectedStatus
    console.log("status", statusFordateCheck);
    if (statusFordateCheck == 3 || statusFordateCheck == 4 || statusFordateCheck == 36 || statusFordateCheck == 5 || statusFordateCheck == 8 || statusFordateCheck == 9) {
      console.log("date compare", this.oldFollowUpDateAndTime, this.updateLeadForm.controls.follow_up_datetime.value)
      if (this.oldFollowUpDateAndTime == this.updateLeadForm.controls.follow_up_datetime.value) {
        Swal.fire(
          'Oops...',
          'Please Set A new different value for follow up Date and time!',
          'error')
        this.submitStatus = false;
        return;
      }
    }


    // console.log(this.updateLeadForm.hasError)
    console.log(this.updateLeadForm.controls, "plllllllllllll")

    if (!this.updateLeadForm.invalid && this.leadId != null) {
      let updateData = {
        status_type: this.redirectType,
        status: this.currentSelectedStatus,
        follow_up_datetime: this.updateLeadForm.controls.follow_up_datetime.value,
        idv: this.updateLeadForm.controls.idv.value,
        claim_taken: this.updateLeadForm.controls.claim_taken.value,
        payment_date_promised: this.updateLeadForm.controls.payment_date_promised.value,
        payment_link: this.updateLeadForm.controls.payment_link.value,
        policy_expiry_date: this.updateLeadForm.controls.policy_expiry_date.value,
        policy_number: this.updateLeadForm.controls.policy_number.value,
        insurer_name: this.updateLeadForm.controls.insurer_name.value,
        cashback_required: this.updateLeadForm.controls.cashback_required.value,
        cashback_amount: this.updateLeadForm.controls.cashback_amount.value,
        policy_copy: this.policyArray,
        browse_files: this.browseArray,
        updated_policy: this.updatedPolicyArray,
        current_policy: this.updateLeadForm.controls.current_policy.value,
        current_insurer: this.updateLeadForm.controls.current_insurer.value.length ? this.updateLeadForm.controls.current_insurer.value[0].item_id : 0,
        remarks: this.updateLeadForm.controls.remarks.value,
        insuranceCompany: this.updateLeadForm.controls.insuranceCompany.value ? this.updateLeadForm.controls.insuranceCompany.value : [],
        assigne_closer: this.updateLeadForm.controls.assigne_closer.value,
        addon: this.updateLeadForm.controls.addon.value,
        policy_type: this.updateLeadForm.controls.policy_type.value ? (this.updateLeadForm.controls.policy_type.value.length ? this.updateLeadForm.controls.policy_type.value[0].item_id : 0) : 0,
        current_ncb: this.updateLeadForm.controls.current_ncb.value.length ? this.updateLeadForm.controls.current_ncb.value[0].item_id : 0,
        whatsapp: this.updateLeadForm.controls.whatsapp.value,
        competitive_quote: this.updateLeadForm.controls.competitive_quote.value,
        policy_recived_file: this.policyRecivedArray,
        language: this.updateLeadForm.controls.language_barrier_drop.value.length ? this.updateLeadForm.controls.language_barrier_drop.value[0].item_id : 0,
        policy_issued_source: this.updateLeadForm.controls.business_source.value.length ? this.updateLeadForm.controls.business_source.value[0].item_id : 0,
        new_car_taken: this.updateLeadForm.controls.new_car_taken.value,
        month: this.updateLeadForm.controls.monthDrop.value.length ? this.updateLeadForm.controls.monthDrop.value[0].item_id : 0,
        inspection: this.updateLeadForm.controls.inspection.value.length ? this.updateLeadForm.controls.inspection.value[0].item_id : 0,
        source_channel_id: this.updateLeadForm.controls.source_channel.value.length ? this.updateLeadForm.controls.source_channel.value[0].item_id : 0,
        policy_issued_status: this.updateLeadForm.controls.policy_issued_status.value.length ? this.updateLeadForm.controls.policy_issued_status.value[0].item_id : 0,
        policy_recieved: this.updateLeadForm.controls.policy_recieved.value,
        reason: this.updateLeadForm.controls.reason.value.length ? this.updateLeadForm.controls.reason.value : []
      }

      let updateLeadData = {
        status_id: this.currentSelectedStatus,
        follow_up_date: updateData.follow_up_datetime
      }
      let selectedUser = this.teamDataDetails.filter(ele => ele.mobile == this.f.assigne_closer.value[0].item_id);
      if (selectedUser[0].role == 3) {
        updateLeadData["quotation_agent"] = this.f.assigne_closer.value[0].item_id;
      } else {
        updateLeadData["agent"] = this.f.assigne_closer.value[0].item_id;
        updateLeadData["quotation_agent"] = "";
      }
      console.log(updateData);
      console.log(updateLeadData);
      //return;
      this.backendCall.updateLeadStatus(
        {
          updateData,
          updateLeadData,
          id: this.leadId,
          policyTypeArray: this.policyTypeArry

        }
      )
        .subscribe(success => {
          if (success) {
            this.backendCall.addInsPol(
              finalArry,
              this.currentSelectedStatus,
              success.data.lead_history_id
            )
              .subscribe(success => {
                if (this.leadId != null) {
                  this.successAlert = true;
                  this.failedAlert = false;
                  Swal.fire(
                    'Good Job!',
                    'Updated the entry Successfully',
                    'success')
                  this.submitStatus = false;
                  console.log("herere454", this.route.snapshot.queryParamMap.get('type'))
                  this.router.navigate(['/lead/list'], { queryParams: { type: this.route.snapshot.queryParamMap.get('type') } });
                  return;

                }
              },
                error => {
                  this.message = 'Something went wrong while Updating entry';
                });
          }
        },
          error => {
            this.submitStatus = false;
            console.log(error);
            this.successAlert = false;
            this.failedAlert = true;
            this.message = 'Something went wrong while Updating entry';
          });
    } else {
      this.submitStatus = false;
      if (this.leadId == null)
        this.router.navigate(['/lead/list'], { queryParams: { type: this.redirectType } });
      return;
    }

    //console.log("outtt")
  }


  getLeadDetails() {
    this.backendCall.getLead(
      {
        id: this.leadId
      }
    )
      .subscribe(success => {
        if (success) {
          console.log("rdcftvghbjnkm")
          console.log(success)
          if (success.data.leadDetails.length) {
            this.sucessStatus = success.data.leadDetails[0].status_id
            if (this.sucessStatus == 1) {
              this.openTicket()
            }
            console.log(success.data.leadDetails)
            this.f.customer_name.setValue(success.data.leadDetails[0].policy_holder_name);
            this.f.customer_number.setValue(success.data.leadDetails[0].mobile);
            this.f.vehicle_model.setValue(success.data.leadDetails[0].model);
            this.f.vehicle_number.setValue(success.data.leadDetails[0].reg_number);
            this.f.previous_policy_number.setValue(success.data.leadDetails[0].policy_number);
            this.policyUrl = success.data.leadDetails[0].policy_document_url;
            this.make = success.data.leadDetails[0].make == "NA" ? "" : ("'" + success.data.leadDetails[0].make + "'");
            this.backendCall.getLeadDetails(
              {
                id: this.leadId,
                status: this.currentSelectedStatus
              }
            )
              .subscribe(success => {
                if (success) {
                  if (success.data.length) {
                    this.globalDataSet = success;
                    this.f.status_type.setValue(this.redirectType);
                    this.currentSelectedStatusType = success.data[0].selectedStatusType[0].item_id;
                    this.fakeOnItemSelect(success.data[0].selectedStatusType[0])

                    this.f.status.setValue(this.currentSelectedStatus);
                    // this.currentSelectedStatus = success.data[0].selectedStatus[0].item_id
                    this.dataSetter(success);
                    let detailsData = success.data[0].leadDetails
                    if (detailsData.length) {
                      if (detailsData[0].follow_up_datetime != '0000-00-00 00:00:00' && this.currentSelectedStatus == this.sucessStatus) {
                        this.oldFollowUpDateAndTime = formatDate((detailsData[0].follow_up_datetime), 'yyyy-MM-ddTHH:mm', 'en');
                      }
                    }

                  }
                }
              },
                error => {
                  //alert(error.error.error_msg);
                  this.successAlert = false;
                  this.failedAlert = true;
                  this.message = 'Something went wrong while Fetching entry for update';
                });


          }
        }
      },
        error => {
          //alert(error.error.error_msg);
          this.successAlert = false;
          this.failedAlert = true;
          this.message = 'Something went wrong while Fetching entry for update';
        });
  }
  getDropDownData() {
    this.backendCall.getDropDown()
      .subscribe(success => {
        if (success) {
          if (success.data.length) {
            let addOnData = success.data[0].addsOn;

            let insuranceApidata = success.data[0].insurance;
            let tempadd = [];
            let tempinsu = [];
            addOnData.forEach(ele => {
              tempadd.push({ item_id: Number(ele.id), item_text: ele.name })
            });

            insuranceApidata.forEach(ele => {
              tempinsu.push({ item_id: Number(ele.id), item_text: ele.name })
            });
            this.addOnDataArray = tempadd;

            console.log(tempadd, "###############################");
            this.insuranceDataArray = tempinsu

            this.dropdownSettings = {
              singleSelection: false,
              idField: 'item_id',
              textField: 'item_text',
              selectAllText: 'Select All',
              unSelectAllText: 'UnSelect All',
              itemsShowLimit: 1,
              allowSearchFilter: true
            };

            //add status
            let tempStatusType = [];
            this.fetchedStatusData = success.data[0].status;
            success.data[0].statusType.forEach(ele => {
              tempStatusType.push({ item_id: Number(ele.id), item_text: ele.name })
            });
            //this.selectedItems = [{item_id: 1, item_text: 'HDFC ERGO'}]
            this.statusTypeDrop = tempStatusType

            this.dropdownSettingsForStatus = {
              singleSelection: true,
              idField: 'item_id',
              textField: 'item_text',
              selectAllText: 'Select All',
              unSelectAllText: 'UnSelect All',
              itemsShowLimit: 2,
              allowSearchFilter: true
            };

          }
        }
      },
        error => {
          //alert(error.error.error_msg);
          this.successAlert = false;
          this.failedAlert = true;
          this.message = 'Something went wrong while Fetching entry for update';
        });
  }

  onItemSelect(item: any) {
    console.log(item);
    this.currentSelectedStatusType = item.item_id;
    this.f.status.reset();
    this.currentSelectedStatus = '';
    let tempStatus = this.fetchedStatusData;
    let finalArr = [];
    tempStatus.forEach(ele => {
      if (ele.status_type_id == item.item_id)
        finalArr.push({ item_id: Number(ele.id), item_text: ele.status })
    });

    this.statusDrop = finalArr;
  }

  fakeOnItemSelect(item) {
    console.log(item);
    let tempStatus = this.fetchedStatusData;
    let finalArr = [];
    tempStatus.forEach(ele => {
      if (ele.status_type_id == item.item_id)
        finalArr.push({ item_id: Number(ele.id), item_text: ele.status })
    });

    this.statusDrop = finalArr;
  }

  currentStatus() {
    console.log("hehehehehe")
    //console.log("status", item.item_id);
    // this.currentSelectedStatus = item;

    this.nullifyFields();
    this.dataSetter(this.globalDataSet);


    //insurance compnsy
    if (this.currentSelectedStatus == 4 || this.currentSelectedStatus == 6 || this.currentSelectedStatus == 7 || this.currentSelectedStatus == 5 || this.currentSelectedStatus == 8 || this.currentSelectedStatus == 9 || this.currentSelectedStatus == 10 || this.currentSelectedStatus == 12 || this.currentSelectedStatus == 17 || this.currentSelectedStatus == 35 || this.currentSelectedStatus == 36 || this.currentSelectedStatus == 39) {
      this.f.insuranceCompany.setValidators(Validators.required);

      this.f.insuranceCompany.updateValueAndValidity()

      this.formValidation(this.f.insuranceCompany, true)

    }
    if (this.currentSelectedStatus == 5) {
      this.f.current_insurer.setValidators(Validators.required);

      this.f.current_insurer.updateValueAndValidity()

      this.formValidation(this.f.current_insurer, true)

    }

    if (this.currentSelectedStatus == 5 || this.currentSelectedStatus == 4 || this.currentSelectedStatus == 6 || this.currentSelectedStatus == 7 || this.currentSelectedStatus == 8 || this.currentSelectedStatus == 9 || this.currentSelectedStatus == 36 || this.currentSelectedStatus == 39) {
      this.policyTypeClaimHide = false;
      this.f.policy_type.setValidators(Validators.required);

      this.f.policy_type.updateValueAndValidity()

      this.formValidation(this.f.policy_type, true)
    }

    if (this.currentSelectedStatus == 6 || this.currentSelectedStatus == 5 || this.currentSelectedStatus == 7 || this.currentSelectedStatus == 39) {
      this.f.claim_taken.setValidators(Validators.required);

      this.f.claim_taken.updateValueAndValidity()

      this.formValidation(this.f.claim_taken, true)
    }

    if (this.currentSelectedStatus == 36 || this.currentSelectedStatus == 4 || this.currentSelectedStatus == 8 || this.currentSelectedStatus == 9) {
      this.f.source_channel.setValidators(Validators.required);

      this.f.source_channel.updateValueAndValidity()

      this.formValidation(this.f.source_channel, true)
    }

    if (this.currentSelectedStatus == 4 || this.currentSelectedStatus == 6 || this.currentSelectedStatus == 7 || this.currentSelectedStatus == 36 || this.currentSelectedStatus == 39) {
      this.competitiveDecider = false;
      this.f.browse_files.setValidators(Validators.required);

      this.f.browse_files.updateValueAndValidity()

      this.formValidation(this.f.browse_files, true)
    }

    if (this.currentSelectedStatus == 6 || this.currentSelectedStatus == 7) {
      this.f.competitive_quote.setValidators(Validators.required);

      this.f.competitive_quote.updateValueAndValidity()

      this.formValidation(this.f.competitive_quote, true)
    }

    if (this.currentSelectedStatus == 4 || this.currentSelectedStatus == 5 || this.currentSelectedStatus == 39 || this.currentSelectedStatus == 36) {
      this.idvDecider = false;
      this.f.idv.setValidators(Validators.required);

      this.f.idv.updateValueAndValidity()

      this.formValidation(this.f.idv, true)
    }

    if (this.currentSelectedStatus == 8 || this.currentSelectedStatus == 9) {
      this.f.payment_date_promised.setValidators(Validators.required);

      this.f.payment_date_promised.updateValueAndValidity()

      this.formValidation(this.f.payment_date_promised, true)

      this.f.payment_link.setValidators(Validators.required);

      this.f.payment_link.updateValueAndValidity()

      this.formValidation(this.f.payment_link, true)
    }


    if (this.currentSelectedStatus == 10 || this.currentSelectedStatus == 12 || this.currentSelectedStatus == 37) {
      this.f.policy_recieved.setValidators(Validators.required);

      this.f.policy_recieved.updateValueAndValidity()

      this.formValidation(this.f.policy_recieved, true)

    }

    if (this.currentSelectedStatus == 10 || this.currentSelectedStatus == 11) {
      this.f.policy_expiry_date.setValidators(Validators.required);

      this.f.policy_expiry_date.updateValueAndValidity()

      this.formValidation(this.f.policy_expiry_date, true)

    }

    if (this.currentSelectedStatus == 15) {
      this.f.language_barrier_drop.setValidators(Validators.required);

      this.f.language_barrier_drop.updateValueAndValidity()

      this.formValidation(this.f.language_barrier_drop, true)
    }

    if (this.currentSelectedStatus == 5 || this.currentSelectedStatus == 39 || this.currentSelectedStatus == 11) {
      this.f.current_policy.setValidators(Validators.required);

      this.f.current_policy.updateValueAndValidity()

      this.formValidation(this.f.current_policy, true)
    }

    if (this.currentSelectedStatus == 17) {
      this.f.business_source.setValidators(Validators.required);

      this.f.business_source.updateValueAndValidity()

      this.formValidation(this.f.business_source, true)

      this.f.policy_issued_status.setValidators(Validators.required);

      this.f.policy_issued_status.updateValueAndValidity()

      this.formValidation(this.f.policy_issued_status, true)

      this.f.cashback_required.setValidators(Validators.required);

      this.f.cashback_required.updateValueAndValidity()

      this.formValidation(this.f.cashback_required, true)
    }

    if (this.currentSelectedStatus == 27) {
      this.f.new_car_taken.setValidators(Validators.required);

      this.f.new_car_taken.updateValueAndValidity()

      this.formValidation(this.f.new_car_taken, true)
    }

    if (this.currentSelectedStatusType == 1
      && this.currentSelectedStatus != 10
      && this.currentSelectedStatus != 6
      && this.currentSelectedStatus != 7
      && this.currentSelectedStatus != 11
      && this.currentSelectedStatus != 12
      && this.currentSelectedStatus != 13
      && this.currentSelectedStatus != 15
      && this.currentSelectedStatus != 16
      && this.currentSelectedStatus != 17
      && this.currentSelectedStatus != 27
      && this.currentSelectedStatus != 31
      && this.currentSelectedStatus != 33
      && this.currentSelectedStatus != 37
      && this.currentSelectedStatus != 38) {
      this.f.follow_up_datetime.setValidators(Validators.required);

      this.f.follow_up_datetime.updateValueAndValidity()

      this.formValidation(this.f.follow_up_datetime, true)
    }

    if (this.currentSelectedStatus == 39) {
      this.f.inspection.setValidators(Validators.required);

      this.f.inspection.updateValueAndValidity()

      this.formValidation(this.f.inspection, true)
    }
  }

  onDeSelectStatusType(item: any) {
    this.currentSelectedStatusType = this.currentSelectedStatus = "";
    this.f.status.reset();
    this.statusDrop = "";

    this.nullifyFields()

  }

  onDeSelectStatus(item: any) {
    this.currentSelectedStatus = "";
    this.nullifyFields()

  }

  openTicket() {
    this.backendCall.openTicket(
      {
        id: this.leadId

      }
    )
      .subscribe(success => {
        if (success) {
          if (this.leadId != null) {
            //alert("Updated the entry Successfully")
            //this.router.navigate(['/lead/list']);
            return;
          }
        }
      },
        error => {
          console.log(error);
          this.successAlert = false;
          this.failedAlert = true;
          this.message = 'Something went wrong while Updating entry';
        });
  }

  adminAlert(modal, event: any) {
    // window.global.
    // exampleModalCenter.show()
    //console.log(event)
    if (event.target.checked)
      modal.show();
  }

  handleFileInput(files: FileList) {
    this.submitStatus = true;
    this.browseArray = [];
    for (var key in files) {
      if (files.hasOwnProperty(key)) {
        let fileData = files[key]
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = () => {
          console.log(reader.result)
          console.log(this.quoteUpload = fileData.name);

          this.browseArray.push({
            name: fileData.name,
            size: fileData.size,
            type: fileData.type,
            codec: reader.result
          })
        };
      }
    }

    setTimeout(() => this.submitStatus = false, 3000);

  }

  policyFile(files: FileList) {
    this.submitStatus = true;
    this.policyArray = [];
    for (var key in files) {
      if (files.hasOwnProperty(key)) {
        let fileData = files[key];
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = () => {
          console.log(this.quoteUpload = fileData.name);
          this.policyArray.push({
            name: fileData.name,
            size: fileData.size,
            type: fileData.type,
            codec: reader.result
          })
        };
      }
    }
    setTimeout(() => this.submitStatus = false, 3000);
  }

  updatedPolicyHandler(files: FileList) {
    this.submitStatus = true;
    this.updatedPolicyArray = [];
    for (var key in files) {
      if (files.hasOwnProperty(key)) {
        let fileData = files[key]
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = () => {
          console.log(reader.result)
          console.log(this.quoteUpload = fileData.name);
          this.updatedPolicyArray.push({
            name: fileData.name,
            size: fileData.size,
            type: fileData.type,
            codec: reader.result
          })
        };
      }
    }

    setTimeout(() => this.submitStatus = false, 3000);

  }

  policyRecivedFileHandler(files: FileList) {
    this.submitStatus = true;
    this.policyRecivedArray = [];
    for (var key in files) {
      if (files.hasOwnProperty(key)) {
        let fileData = files[key]
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = () => {
          console.log(reader.result)
          console.log(this.quoteUpload = fileData.name);
          this.policyRecivedArray.push({
            name: fileData.name,
            size: fileData.size,
            type: fileData.type,
            codec: reader.result
          })
        };
      }
    }

    setTimeout(() => this.submitStatus = false, 3000);

  }


  removeFile(data, id) {
    console.log('removed');
    const index = this.fileUploaded.indexOf(data);
    console.log(index)
    this.fileUploaded.splice(index, 1);

    this.backendCall.removeFile(
      id
    )
      .subscribe(success => {

      },
        error => {
          this.message = 'Something went wrong while Updating entry';
        });
  }


  claimTakenEvent(status) {
    this.f.claim_taken.setValue(status)
    console.log("hurrray", this.f.claim_taken.value)
    if ((this.currentSelectedStatus == 5 || this.currentSelectedStatus == 39)) {
      if (status) {
        this.ncbDecider = true;
        this.quoteReqObj.ncb = 'Claim Taken';
        document.getElementById("claim_taken").style.background = "#000";
        document.getElementById("claim_taken").style.color = "#fff";
        document.getElementById("not_claim_taken").style.color = "#000";
        document.getElementById("claim_taken").style.boxShadow = "none";
        document.getElementById("not_claim_taken").style.boxShadow = " 3px 3px 6px #bfc3cf,  -3px -3px 6px #fff";
        document.getElementById("not_claim_taken").style.background = "#fff";
        this.f.current_ncb.setValidators(null);
        this.f.current_ncb.updateValueAndValidity()
      } else {
        this.ncbDecider = false;
        // this.quoteReqObj.ncb = "";
        document.getElementById("not_claim_taken").style.background = "#000";
        document.getElementById("claim_taken").style.background = "#fff";
        document.getElementById("not_claim_taken").style.color = "#fff";
        document.getElementById("claim_taken").style.color = "#000";
        document.getElementById("claim_taken").style.boxShadow = " 3px 3px 6px #bfc3cf,  -3px -3px 6px #fff";
        document.getElementById("not_claim_taken").style.boxShadow = "none";
        this.f.current_ncb.setValidators(Validators.required);
        this.f.current_ncb.updateValueAndValidity()
        this.formValidation(this.f.current_ncb, true)

      }
    }
  }

  cashbackEvent(status) {
    this.f.cashback_required.setValue(status)
    if (this.currentSelectedStatus == 17) {
      if (status) {
        const yesBtn = document.getElementById("cashback_required");
        const noBtn = document.getElementById("false_cashback_required");
        // STYLING 
        yesBtn.style.background = "#000"
        yesBtn.style.color = "#fff"
        yesBtn.style.boxShadow = " 3px 3px 6px #bfc3cf,  -3px -3px 6px #fff";
        noBtn.style.background = "#fff"
        noBtn.style.color = "#000"
        noBtn.style.boxShadow = "  3px 3px 6px #bfc3cf,  -3px -3px 6px #fff";
        this.cashbackDecider = false;
        this.f.cashback_amount.setValidators(Validators.required);
        this.f.cashback_amount.updateValueAndValidity()
        this.formValidation(this.f.cashback_amount, true)
      } else {
        const yesBtn = document.getElementById("cashback_required");
        const noBtn = document.getElementById("false_cashback_required");
        // STYLING 
        yesBtn.style.background = "#fff"
        yesBtn.style.color = "#000"
        yesBtn.style.boxShadow = "  3px 3px 6px #bfc3cf,  -3px -3px 6px #fff";
        noBtn.style.background = "#000"
        noBtn.style.color = "#fff"
        noBtn.style.boxShadow = "  3px 3px 6px #bfc3cf,  -3px -3px 6px #fff";
        this.cashbackDecider = true;
        this.f.cashback_amount.setValidators(null);
        this.f.cashback_amount.updateValueAndValidity()
      }
    }
  }

  currentPolicyEvent(status) {
    console.log("this is status====================>", status + this.currentSelectedStatus)
    this.f.current_policy.setValue(status)
    if (this.currentSelectedStatus == 5 || this.currentSelectedStatus == 39 || this.currentSelectedStatus == 11) {
      if (status) {
        this.quoteReqObj["previousPolicy"] = 'Policy Have';
        this.currentPolicyDecider = true;
        document.getElementById("current_policy").style.background = "#000";
        document.getElementById("current_policy").style.color = "#fff";
        document.getElementById("no_current_policy").style.color = "#000";
        // document.getElementById("current_policy").style.border = "none";
        document.getElementById("no_current_policy").style.boxShadow = " 3px 3px 6px #bfc3cf,  -3px -3px 6px #fff";
        document.getElementById("no_current_policy").style.background = "#fff";
        this.f.updated_policy.setValidators(null);
        this.f.updated_policy.updateValueAndValidity()

      } else {
        console.log("this false status====================>", status)
        this.quoteReqObj["previousPolicy"] = `Policy Received`;
        this.currentPolicyDecider = false;
        document.getElementById("no_current_policy").style.background = "#000";
        document.getElementById("current_policy").style.background = "#fff";
        document.getElementById("no_current_policy").style.color = "#fff";
        document.getElementById("current_policy").style.color = "#000";
        document.getElementById("current_policy").style.boxShadow = " 3px 3px 6px #bfc3cf,  -3px -3px 6px #fff";
        document.getElementById("no_current_policy").style.boxShadow = "none";
        this.f.updated_policy.setValidators(null);

        this.f.updated_policy.updateValueAndValidity()

        this.formValidation(this.f.updated_policy, true)
        // this.checkbox();

      }
    }
  }



  policyTypeEvent(item) {
    console.log("heheheheheh", this.currentSelectedStatus)
    this.selectedpol = item
    this.selectedPolArry.push(item.item_text)
    let temp_text = ""

    for (let i = 0; i < this.insPolicyArry.length; i++) {
      if (this.insPolicyArry[i].insptId == this.idIncreament) {
        this.insPolicyArry[i].policy = item
      }
    }
    console.log(this.insPolicyArry);


    for (let i = 0; i < this.selectedPolArry.length; i++) {
      if (i == 0) {
        temp_text += (i + 1) + ". " + this.selectedPolArry[i]
      } else {
        temp_text += "\n" + (i + 1) + ". " + this.selectedPolArry[i] + "\r"
      }
    }
    this.quoteReqObj.plan = temp_text;
    let addOn = false;
    for (let index = 0; index < this.selectedPolArry.length; index++) {
      if (this.selectedPolArry[index] == 'Comprehensive with Add ons' || this.selectedPolArry[index] == 'SAOD' || this.selectedPolArry[index] == 'BUNDLE') {
        addOn = true;
      }
    }
    console.log(typeof addOn, "select")
    if (this.currentSelectedStatus == 5 || this.currentSelectedStatus == 6 || this.currentSelectedStatus == 7 || this.currentSelectedStatus == 8 || this.currentSelectedStatus == 9 || this.currentSelectedStatus == 36 || this.currentSelectedStatus == 39) {
      if (this.currentSelectedStatus == 5 || this.currentSelectedStatus == 39) {
        console.log(addOn, "select")
        if (addOn || item.item_text == 'Comprehensive with Add ons' || item.item_text == "SAOD" || item.item_text == "BUNDLE") {
          this.addonDecider = false;
          this.f.addon.setValidators(Validators.required);

          this.f.addon.updateValueAndValidity()

          this.formValidation(this.f.addon, true)
        } else {
          this.addonDecider = true;

          this.f.addon.setValidators(null);
          this.f.addon.updateValueAndValidity()
        }
      }
      // if (item.item_text == 'SATP') {
      //   //this.policyTypeClaimHide = true;//
      //   this.idvDecider = true;

      //   this.f.claim_taken.setValidators(null);
      //   this.f.claim_taken.updateValueAndValidity()

      //   this.f.idv.setValidators(null);
      //   this.f.idv.updateValueAndValidity()
      // } else {
      //   this.policyTypeClaimHide = false

      // }
    }
  }

  deSelectPolicy(item) {
    this.policyTypeArry = []
    for (let i = 0; i < this.selectedPolArry.length; i++) {
      if (this.selectedPolArry[i] == item.item_text) {
        this.selectedPolArry.splice(i, 1)
      }
    }
    let temp_text = ""
    for (let i = 0; i < this.selectedPolArry.length; i++) {
      if (i == 0) {
        temp_text += this.selectedPolArry[i]
      } else {
        temp_text += ", " + this.selectedPolArry[i]
      }
    }
  }

  selectAllPolicy(item) {
    this.selectedPolArry = []
    for (let i = 0; i < item.length; i++) {
      this.selectedPolArry.push(item[i].item_text)
    }
    console.log(item);
    let temp_id = ""
    let temp1_text = ""
    for (let i = 0; i < item.length; i++) {
      // this.policyTypeArry.push(item.item_id)
      // console.log( this.policyTypeArry);

      if (i == 0) {
        temp_id += item[i].item_id
        temp1_text += (i + 1) + ". " + item[i].item_text
        console.log(temp_id, temp1_text, "1");

      } else {
        temp_id += "," + item[i].item_id
        temp1_text += "\n" + (i + 1) + ". " + item[i].item_text + "\r"
        console.log(temp_id, temp1_text, "2");
      }
      // this.policyTypeArry.push(item.item_id,item.text= temp_text)
      // console.log(this.policyTypeArry);

    }


    this.quoteReqObj.plan = temp1_text
    const tempArray = temp_id.split(",");
    console.log(tempArray);
    this.policyTypeArry = []
    for (let i = 0; i < tempArray.length; i++) {
      this.policyTypeArry.push(parseInt(tempArray[i]))
    }
    // for (let i = 0; i < tempArray.length; i++) {
    //   this.policyTypeArry.push(parseInt(tempArray[i]))
    // }
    // tempArray.forEach(index => {
    //   this.policyTypeArry.push(index)
    // });
    console.log(this.policyTypeArry);

    console.log(temp1_text);
    console.log(this.quoteReqObj.plan);
    let addOn = true;
    console.log(typeof addOn, "select")
    if (this.currentSelectedStatus == 5 || this.currentSelectedStatus == 6 || this.currentSelectedStatus == 7 || this.currentSelectedStatus == 8 || this.currentSelectedStatus == 9 || this.currentSelectedStatus == 36 || this.currentSelectedStatus == 39) {
      if (this.currentSelectedStatus == 5 || this.currentSelectedStatus == 39) {
        console.log(addOn, "select")
        if (addOn) {
          this.addonDecider = false;
          this.f.addon.setValidators(Validators.required);

          this.f.addon.updateValueAndValidity()

          this.formValidation(this.f.addon, true)
        }
        // else {
        //   this.addonDecider = true;

        //   this.f.addon.setValidators(null);
        //   this.f.addon.updateValueAndValidity()
        // }
      }
    }
  }

  deSelectAllPolicy() {
    this.selectedPolArry = []
    this.quoteReqObj.plan = ""
    this.addonDecider = true;
    this.f.addon.setValidators(null);
    this.f.addon.updateValueAndValidity()
  }

  policyTypeMultiSelecEvent(item) {
    this.policyTypeEvent(item)
    this.policyTypeArry.push(item.item_id)
    console.log(this.policyTypeArry);

  }

  deSelectMultiSelecPolicy(item) {

    // this.policyTypeArry = []
    for (let i = 0; i < this.policyTypeArry.length; i++) {
      if (this.policyTypeArry[i] == item.item_id) {
        this.policyTypeArry.splice(i, 1)
      }
    }
    console.log(this.selectedPolArry);
    for (let i = 0; i < this.selectedPolArry.length; i++) {
      if (this.selectedPolArry[i] == item.item_text) {
        this.selectedPolArry.splice(i, 1)
      }
    }

    let temp_text1 = ""
    for (let i = 0; i < this.selectedPolArry.length; i++) {
      if (i == 0) {
        temp_text1 += (i + 1) + ". " + this.selectedPolArry[i]
      } else {
        temp_text1 += "\n" + (i + 1) + ". " + this.selectedPolArry[i] + "\r"
      }
    }

    let temp_text = ""
    for (let i = 0; i < this.policyTypeArry.length; i++) {
      if (i == 0) {
        temp_text += (i + 1) + ". " + this.policyTypeArry[i]
      } else {
        temp_text += "\n" + (i + 1) + ". " + this.policyTypeArry[i] + "\r"
      }
    }

    this.quoteReqObj.plan = temp_text1

    let addOn = false;
    for (let index = 0; index < this.selectedPolArry.length; index++) {
      if (this.selectedPolArry[index] == 'Comprehensive with Add ons' || this.selectedPolArry[index] == 'SAOD' || this.selectedPolArry[index] == 'BUNDLE') {
        addOn = true;
      }
    }
    if (this.currentSelectedStatus == 5 || this.currentSelectedStatus == 6 || this.currentSelectedStatus == 7 || this.currentSelectedStatus == 8 || this.currentSelectedStatus == 9 || this.currentSelectedStatus == 36 || this.currentSelectedStatus == 39) {
      if (this.currentSelectedStatus == 5 || this.currentSelectedStatus == 39) {
        console.log(addOn, "dselect")
        if (addOn) {
          this.addonDecider = false;
          this.f.addon.setValidators(Validators.required);

          this.f.addon.updateValueAndValidity()

          this.formValidation(this.f.addon, true)
        }
        else {
          console.log(addOn, "ififififdselect")

          this.addonDecider = true;

          this.f.addon.setValidators(null);
          this.f.addon.updateValueAndValidity()
        }
      }
    }
    console.log(this.policyTypeArry);
  }

  //////////////REQUESTED INSURER

  insSelection(item) {
    this.selectedInsArry.push(item.item_text)
    let temp_text = ""
    for (let i = 0; i < this.insPolicyArry.length; i++) {
      if (this.insPolicyArry[i].insptId == this.idIncreament) {
        this.insPolicyArry[i].ins = item
      }
    }
    for (let i = 0; i < this.selectedInsArry.length; i++) {
      if (i == 0) {
        temp_text += (i + 1) + ". " + this.selectedInsArry[i]
      } else {
        temp_text += "\n" + (i + 1) + ". " + this.selectedInsArry[i] + "\r"
      }
    }
    this.quoteReqObj.insurer = temp_text
    this.selectedIns = item
    console.log(item);
    console.log("aaaaaaaaaaaaaaaaadddddddd", item.item_text);
  }

  onDeSelectins(item) {
    for (let i = 0; i < this.selectedInsArry.length; i++) {
      if (this.selectedInsArry[i] == item.item_text) {
        this.selectedInsArry.splice(i, 1)
      }
    }
    let temp_text = ""
    for (let i = 0; i < this.selectedInsArry.length; i++) {
      if (i == 0) {
        temp_text += (i + 1) + ". " + this.selectedInsArry[i]
      } else {
        temp_text += "\n" + (i + 1) + ". " + this.selectedInsArry[i] + "\r"
      }
    }
    this.quoteReqObj.insurer = temp_text


  }

  selectAllIns(item) {
    this.selectedInsArry = []
    for (let i = 0; i < item.length; i++) {
      this.selectedInsArry.push(item[i].item_text)
    }
    console.log(this.selectedInsArry);
    let temp_text = ""
    for (let i = 0; i < item.length; i++) {
      if (i == 0) {
        temp_text += (i + 1) + ". " + item[i].item_text
      } else {
        temp_text += "\n" + (i + 1) + ". " + item[i].item_text + "\r"
      }
    }
    this.quoteReqObj.insurer = temp_text
  }


  deSelectAllIns() {
    // if ((item.length == 23)) {
    //   this.quoteReqObj.insurer = ""
    //   console.log(item);

    // } else {
    //   for (let i = 0; i < item.length; i++) {
    //     if (this.selectedInsArry[i] == item.item_text) {
    //       this.selectedInsArry.splice(i, 1)
    //     }
    //   }
    // }
    this.quoteReqObj.insurer = ""
  }

  /////////////////PREVIOUS INSURANCE

  insPreviousSelection(item) {
    this.quoteReqObj.prevInsurer = item.item_text
  }

  deSelectPreIns() {
    this.quoteReqObj.prevInsurer = ""
  }



  /////////////////////////ADDONS
  addOnSelection(item) {
    this.selectedAddOnsArry.push(item.item_text)
    let temp_text = ""
    for (let i = 0; i < this.selectedAddOnsArry.length; i++) {
      if (i == 0) {
        temp_text += (i + 1) + ". " + this.selectedAddOnsArry[i]
      } else {
        temp_text += "\n" + (i + 1) + ". " + this.selectedAddOnsArry[i] + "\r"
      }
    }
    console.log(item)
    this.quoteReqObj.addOns = temp_text

  }

  deSelectAddOns(item) {
    for (let i = 0; i < this.selectedAddOnsArry.length; i++) {
      if (this.selectedAddOnsArry[i] == item.item_text) {
        this.selectedAddOnsArry.splice(i, 1)
      }
    }

    let temp_text = ""
    for (let i = 0; i < this.selectedAddOnsArry.length; i++) {
      if (i == 0) {
        temp_text += (i + 1) + ". " + this.selectedAddOnsArry[i]
      } else {
        temp_text += "\n" + (i + 1) + ". " + this.selectedAddOnsArry[i] + "\r"
      }
    }
    this.quoteReqObj.addOns = temp_text
  }

  selectAllAddOns(item) {

    this.selectedAddOnsArry = []
    for (let i = 0; i < item.length; i++) {
      this.selectedAddOnsArry.push(item[i].item_text)
    }
    console.log(this.selectedAddOnsArry);
    let temp_text = ""
    for (let i = 0; i < item.length; i++) {
      if (i == 0) {
        temp_text += (i + 1) + ". " + item[i].item_text
      } else {
        temp_text += "\n" + (i + 1) + ". " + item[i].item_text + "\r"
      }
    }
    this.quoteReqObj.addOns = temp_text
  }

  deSelectAllAddOns() {
    this.quoteReqObj.addOns = ""
  }

  addBothInarry() {
    console.log(this.currentSelectedStatus, "#################current status")
    if (this.currentSelectedStatus == 4 || this.currentSelectedStatus == 36) {
      let image = []
      let imgCount = 0
      try {
        imgCount = document.getElementById("paste_here").getElementsByTagName("img").length
        for (let i = 0; i < imgCount; i++) {
          image.push(document.getElementById("paste_here").getElementsByTagName("img")[i].src)
        }
        console.log(this.insPolicyArry, "insssss");
        console.log(this.idIncreament, "increeeeee");
        for (let i = 0; i < this.insPolicyArry.length; i++) {
          if (this.insPolicyArry[i].insptId == this.idIncreament) {
            this.insPolicyArry[i].img = image
          }
        }
      } catch (error) {
        alert("paste image")
      }
      let isPresInArry = false
      if (!isPresInArry) {
        if (this.f.insuranceCompany.value && this.f.policy_type.value && image.length > 0) {
          for (let i = 0; i < this.insPolicyArry.length; i++) {
            if (this.insPolicyArry[i].insptId == this.idIncreament) {
              this.insPolicyArry[i].added = "yes"
            }
          }
          this.idIncreament = this.idIncreament + 1
          this.insPolicyArry.push({ "insptId": this.idIncreament, "ins": "", "policy": "", "img": [], "leadid": this.leadId })
          this.enteredInsPolArry.push({ "insptId": this.idIncreament - 1, "ins": this.selectedIns, "policy": this.selectedpol, "img": image, "leadid": this.leadId })

          // Reseting Values
          this.f.insuranceCompany.reset()
          this.f.policy_type.reset()
          this.f.payment_link.reset()
          const list = document.getElementById("paste_here");
          list.innerHTML = "";
        } else {
          if (image) {
            alert("Select all three feilds")
          }
        }
      }
      console.log(this.insPolicyArry)
      console.log(this.idIncreament)
    }
    else if (this.currentSelectedStatus == 8 || this.currentSelectedStatus == 9) {
      this.updateLeadForm.controls.payment_link.setValue((<HTMLInputElement>document.getElementById("paymentLink")).value)
      let link = this.updateLeadForm.controls.payment_link.value
      console.log(link);
      console.log(this.updateLeadForm.controls.payment_link.value);


      for (let i = 0; i < this.insPolicyArry.length; i++) {
        if (this.insPolicyArry[i].insptId == this.idIncreament) {
          this.insPolicyArry[i].img = link
          console.log(this.insPolicyArry[i].img);
          console.log(link);

        }
      }
      let isPresInArry = false
      if (!isPresInArry) {
        this.f.payment_link.updateValueAndValidity();
        console.log("linkkkkk", (<HTMLInputElement>document.getElementById("paymentLink")).value)
        this.updateLeadForm.controls.payment_link.setValue((<HTMLInputElement>document.getElementById("paymentLink")).value)
        console.log(this.updateLeadForm.controls.payment_link.value);

        if (this.f.insuranceCompany.value && this.f.policy_type.value && this.updateLeadForm.controls.payment_link.value) {
          for (let i = 0; i < this.insPolicyArry.length; i++) {
            if (this.insPolicyArry[i].insptId == this.idIncreament) {
              this.insPolicyArry[i].added = "yes"
            }
          }
          this.idIncreament = this.idIncreament + 1
          this.insPolicyArry.push({ "insptId": this.idIncreament, "ins": "", "policy": "", "img": [], "leadid": this.leadId })
          console.log("link valueee", this.updateLeadForm.controls.payment_link.value)
          let Plink = this.updateLeadForm.controls.payment_link.value
          console.log(Plink);
          console.log(typeof (Plink));

          this.enteredInsPolArry.push({ "insptId": this.idIncreament - 1, "ins": this.selectedIns, "policy": this.selectedpol, "img": this.updateLeadForm.controls.payment_link.value, "leadid": this.leadId, "link": this.updateLeadForm.controls.payment_link.value })

          // Reseting Values
          this.f.insuranceCompany.reset()
          this.f.policy_type.reset()
          this.f.payment_link.reset()
        } else {
          alert("Select all three feilds")
        }
      }

    }

    console.log(this.insPolicyArry);
    console.log(this.enteredInsPolArry);

  }

  sanitizeUrl(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url)
  }

  pasteEvent(event: ClipboardEvent) {
    console.log("sdfghjmk,")
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    console.log("pasted text", pastedText)
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        console.log("here is image blob", reader.result)
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  addBothPaymentInarry() {
  }

  competativeEvent(item) {
    console.log(item)
    this.f.competitive_quote.setValue(item)
    if (this.currentSelectedStatus == 6 || this.currentSelectedStatus == 7) {
      if (item) {
        this.competitiveDecider = true;
        document.getElementById("competitive_quote").style.background = "#000";
        document.getElementById("competitive_quote").style.color = "#fff";
        document.getElementById("no_competitive_quote").style.color = "#000";
        document.getElementById("competitive_quote").style.boxShadow = "none";
        document.getElementById("no_competitive_quote").style.boxShadow = " 3px 3px 6px #bfc3cf,  -3px -3px 6px #fff";
        document.getElementById("no_competitive_quote").style.background = "#fff";
        this.f.browse_files.setValidators(Validators.required);

        this.f.browse_files.updateValueAndValidity()

        this.formValidation(this.f.browse_files, true)
      } else {
        this.competitiveDecider = false

        document.getElementById("no_competitive_quote").style.background = "#000";
        document.getElementById("competitive_quote").style.background = "#fff";
        document.getElementById("no_competitive_quote").style.color = "#fff";
        document.getElementById("competitive_quote").style.color = "#000";
        document.getElementById("competitive_quote").style.boxShadow = " 3px 3px 6px #bfc3cf,  -3px -3px 6px #fff";
        document.getElementById("no_competitive_quote").style.boxShadow = "none";

        this.f.browse_files.setValidators(null);
        this.f.browse_files.updateValueAndValidity()
      }
    }
  }

  policyRecievedEvent(status) {
    this.f.policy_recieved.setValue(status)
    if (this.currentSelectedStatus == 10 || this.currentSelectedStatus == 12 || this.currentSelectedStatus == 37) {
      if (status) {
        this.policyRecivedDecider = false;
        document.getElementById("policy_recieved").style.background = "#000";
        document.getElementById("policy_recieved").style.color = "#fff";
        document.getElementById("no_policy_recieved").style.color = "#000";
        document.getElementById("policy_recieved").style.boxShadow = "none";
        document.getElementById("no_policy_recieved").style.boxShadow = " 3px 3px 6px #bfc3cf,  -3px -3px 6px #fff";
        document.getElementById("no_policy_recieved").style.background = "#fff";
        this.f.policy_recived_file.setValidators(Validators.required);
        this.f.policy_recived_file.updateValueAndValidity()
        this.formValidation(this.f.policy_recived_file, true)
      } else {
        this.policyRecivedDecider = true;
        document.getElementById("no_policy_recieved").style.background = "#000";
        document.getElementById("policy_recieved").style.background = "#fff";
        document.getElementById("no_policy_recieved").style.color = "#fff";
        document.getElementById("policy_recieved").style.color = "#000";
        document.getElementById("policy_recieved").style.boxShadow = " 3px 3px 6px #bfc3cf,  -3px -3px 6px #fff";
        document.getElementById("no_policy_recieved").style.boxShadow = "none";
        this.f.policy_recived_file.setValidators(null);
        this.f.policy_recived_file.updateValueAndValidity()
      }
    }
  }

  policyIssuesEvent(item) {
    if (this.currentSelectedStatus == 17) {
      if (item.item_text == 'Not Issued') {
        this.policyIssuedDecider = true;

        this.f.policy_number.setValidators(null);
        this.f.policy_number.updateValueAndValidity()

        this.f.policy_copy.setValidators(null);
        this.f.policy_copy.updateValueAndValidity()


      } else {
        this.policyIssuedDecider = false

        this.f.policy_number.setValidators(Validators.required);

        this.f.policy_number.updateValueAndValidity()

        this.formValidation(this.f.policy_number, true)


        // this.f.policy_copy.setValidators(Validators.required);

        // this.f.policy_copy.updateValueAndValidity()

        // this.formValidation(this.f.policy_copy, true)

      }
    }
  }

  newCarEvent(status) {
    console.log("status for new car", status);
    this.f.new_car_taken.setValue(status)
    if (this.currentSelectedStatus == 27) {
      if (status) {
        this.newCarDecider = true
        document.getElementById("new_car_taken").style.background = "#000";
        document.getElementById("new_car_taken").style.color = "#fff";
        document.getElementById("not_new_car_taken").style.color = "#000";
        document.getElementById("new_car_taken").style.boxShadow = "none";
        document.getElementById("not_new_car_taken").style.boxShadow = " 3px 3px 6px #bfc3cf,  -3px -3px 6px #fff";
        document.getElementById("not_new_car_taken").style.background = "#fff";

        this.f.monthDrop.setValidators(Validators.required);

        this.f.monthDrop.updateValueAndValidity()

        this.formValidation(this.f.monthDrop, true)
      } else {
        this.newCarDecider = false
        document.getElementById("not_new_car_taken").style.background = "#000";
        document.getElementById("new_car_taken").style.background = "#fff";
        document.getElementById("not_new_car_taken").style.color = "#fff";
        document.getElementById("new_car_taken").style.color = "#000";
        document.getElementById("new_car_taken").style.boxShadow = " 3px 3px 6px #bfc3cf,  -3px -3px 6px #fff";
        document.getElementById("not_new_car_taken").style.boxShadow = "none";
        this.f.monthDrop.setValidators(null);
        this.f.monthDrop.updateValueAndValidity()
      }
    }


  }

  nullifyFields() {
    this.f.updated_policy.setValidators(null);
    this.f.updated_policy.updateValueAndValidity()

    this.f.addon.setValidators(null);
    this.f.addon.updateValueAndValidity()

    this.f.monthDrop.setValidators(null);
    this.f.monthDrop.updateValueAndValidity()


    this.f.current_ncb.setValidators(null);
    this.f.current_ncb.updateValueAndValidity()

    this.f.policy_type.setValidators(null);
    this.f.policy_type.updateValueAndValidity()

    this.f.insuranceCompany.setValidators(null);
    this.f.insuranceCompany.updateValueAndValidity()

    this.f.current_insurer.setValidators(null);
    this.f.current_insurer.updateValueAndValidity()


    this.f.claim_taken.setValidators(null);
    this.f.claim_taken.updateValueAndValidity()

    this.f.browse_files.setValidators(null);
    this.f.browse_files.updateValueAndValidity()

    this.f.source_channel.setValidators(null);
    this.f.source_channel.updateValueAndValidity()

    this.f.competitive_quote.setValidators(null);
    this.f.competitive_quote.updateValueAndValidity()

    this.f.idv.setValidators(null);
    this.f.idv.updateValueAndValidity()

    this.f.payment_date_promised.setValidators(null);
    this.f.payment_date_promised.updateValueAndValidity()


    this.f.payment_link.setValidators(null);
    this.f.payment_link.updateValueAndValidity()

    this.f.policy_recieved.setValidators(null);
    this.f.policy_recieved.updateValueAndValidity()

    this.f.policy_expiry_date.setValidators(null);
    this.f.policy_expiry_date.updateValueAndValidity()

    this.f.language_barrier_drop.setValidators(null);
    this.f.language_barrier_drop.updateValueAndValidity()

    this.f.current_policy.setValidators(null);
    this.f.current_policy.updateValueAndValidity()

    this.f.business_source.setValidators(null);
    this.f.business_source.updateValueAndValidity()

    this.f.policy_issued_status.setValidators(null);
    this.f.policy_issued_status.updateValueAndValidity()

    this.f.policy_number.setValidators(null);
    this.f.policy_number.updateValueAndValidity()

    this.f.cashback_required.setValidators(null);
    this.f.cashback_required.updateValueAndValidity()

    this.f.new_car_taken.setValidators(null);
    this.f.new_car_taken.updateValueAndValidity()

    this.f.follow_up_datetime.setValidators(null);
    this.f.follow_up_datetime.updateValueAndValidity()

    this.f.inspection.setValidators(null);
    this.f.inspection.updateValueAndValidity()

    this.f.policy_recived_file.setValidators(null);
    this.f.policy_recived_file.updateValueAndValidity()

    this.f.cashback_amount.setValidators(null);
    this.f.cashback_amount.updateValueAndValidity()
  }

  dataSetter(success) {
    let detailsData = success.data[0].leadDetails
    let planData = success.data[0].Ptype
    if (this.currentRole == 2) {
      if (!success.data[0].isCloserAvailable) {
        this.f.assigne_closer.disable();
      }
    }
    this.fileUploaded = success.data[0].uploadedFile;
    this.assign_closer_data = [];
    this.teamDataDetails = success.data[0].teamData;
    success.data[0].teamData.forEach(element => {
      this.assign_closer_data.push({ item_id: element.mobile, item_text: element.first_name + " " + element.last_name + " " + element.count + "--" + element.roleWork })
      if (element.currentUserMobile == element.mobile) {
        this.f.assigne_closer.setValue([{ item_id: element.mobile, item_text: element.first_name + " " + element.last_name + " " + element.count + "--" + element.roleWork }]);
      }
    });

    let finalCurrentNcb = []
    success.data[0].ncbDrop.forEach(element => {
      finalCurrentNcb.push({ item_id: element.id, item_text: element.current_ncb });
      if (detailsData.length && element.id == detailsData[0].current_ncb) {
        this.f.current_ncb.setValue([{ item_id: element.id, item_text: element.current_ncb }])
        this.quoteReqObj.ncb = element.current_ncb
      }
    });

    this.current_ncb = finalCurrentNcb;


    let finalCurrentReason = [];
    let tempReason = []

    success.data[0].reason.forEach(element => {
      finalCurrentReason.push({ item_id: element.id, item_text: element.name });
      console.log("REASON SELETED", (success.data[0].selectedReason.filter(e => e.item_id == element.id)).length);
      if (detailsData.length && (success.data[0].selectedReason.filter(e => e.item_id == element.id)).length > 0) {
        tempReason.push({ item_id: element.id, item_text: element.name });
      }
    });

    this.f.reason.setValue(tempReason)

    this.reasonArray = finalCurrentReason;


    let finalSourceChannel = []
    success.data[0].sourceChannelDrop.forEach(element => {
      finalSourceChannel.push({ item_id: element.id, item_text: element.name });
      if (detailsData.length && element.id == detailsData[0].source_channel_id) {
        this.f.source_channel.setValue([{ item_id: element.id, item_text: element.name }])
      }
    });

    this.source_channel = finalSourceChannel;

    let finalLanguage = []
    success.data[0].languageDrop.forEach(element => {
      finalLanguage.push({ item_id: element.id, item_text: element.language });

      if (detailsData.length && element.id == detailsData[0].language) {
        this.f.language_barrier_drop.setValue([{ item_id: element.id, item_text: element.language }])
      }
    });

    this.language = finalLanguage;

    let finalPolicyType = []
    let planTypeArray = []
    planData.forEach(ele => {
      planTypeArray.push(ele.plan_type)
    });
    console.log(planTypeArray, "aaaaa")
    let setArray = [];
    success.data[0].policyTypeDrop.forEach(element => {
      finalPolicyType.push({ item_id: element.id, item_text: element.policy_type });
      //console.log(key);
      //console.log(finalPolicyType );  
      console.log(planTypeArray.includes(element.id), "aaaaa")

      if (detailsData.length && planTypeArray.includes(element.id)) {
        console.log("insideeeeeee")
        if (this.currentSelectedStatus != 4 && this.currentSelectedStatus != 36 && this.currentSelectedStatus != 8 && this.currentSelectedStatus != 9) {
          setArray.push({ item_id: element.id, item_text: element.policy_type });
          this.policyTypeArry.push(element.id)
          this.policyTypeEvent({ item_id: element.id, item_text: element.policy_type })
        }
      }

    });
    console.log(setArray, "setarray@@@@@@@@@@@@@@@@")
    this.f.policy_type.setValue(setArray);
    this.policy_type = finalPolicyType;
    console.log(this.policy_type);

    // for (let i = 0; i < setArray.length; i++) {
    //   if (i == 0) {
    //     this.quoteReqObj.plan += setArray[i].item_text
    //   } else {
    //     this.quoteReqObj.plan += ", " + setArray[i].item_text
    //   }
    //   console.log(setArray);

    // }

    let finalBusinessSource = []
    success.data[0].policyIssuedSourceDrop.forEach(element => {
      finalBusinessSource.push({ item_id: element.id, item_text: element.name });
      if (detailsData.length && detailsData[0].policy_issued_source == element.id) {
        this.f.business_source.setValue([{ item_id: element.id, item_text: element.name }])
      }
    });

    this.business_source_data = finalBusinessSource;

    let finalPolicyIssuedStatus = []
    success.data[0].policyIssuedStatusDrop.forEach(element => {
      finalPolicyIssuedStatus.push({ item_id: element.id, item_text: element.name });

      if (detailsData.length && element.id == detailsData[0].policy_issued_status) {
        this.f.policy_issued_status.setValue([{ item_id: element.id, item_text: element.name }])
      }
    });

    this.policy_issued_data = finalPolicyIssuedStatus;


    let finalMonthDrop = []
    success.data[0].monthDrop.forEach(element => {
      finalMonthDrop.push({ item_id: element.id, item_text: element.month });
      if (detailsData.length && element.id == detailsData[0].expiring_month) {
        this.f.monthDrop.setValue([{ item_id: element.id, item_text: element.month }])
      }
    });

    this.monthDropData = finalMonthDrop;

    let finalInspection = []
    success.data[0].inspectionDrop.forEach(element => {
      finalInspection.push({ item_id: element.id, item_text: element.name });
      if (detailsData.length && element.id == detailsData[0].inspection) {
        this.f.inspection.setValue([{ item_id: element.id, item_text: element.name }]);
        this.policyIssuesEvent({ item_id: element.id, item_text: element.name })
      }
    });

    this.inspectionData = finalInspection;
    this.f.current_insurer.setValue(success.data[0].previousInsurer)
    this.quoteReqObj.prevInsurer = success.data[0].previousInsurer[0].item_text
    // alert(success.data[0].previousInsurer.previousIns)
    console.log(success.data[0].previousInsurer);
    console.log("hiieeeeeeeeeeeeeeee");


    // this.inspectionData = finalInspection;
    if (this.currentSelectedStatus != 4 && this.currentSelectedStatus != 36 && this.currentSelectedStatus != 8 && this.currentSelectedStatus != 9 && this.currentSelectedStatus != 27 && this.currentSelectedStatus != 11 && this.currentSelectedStatus != 37 && this.currentSelectedStatus != 3) {
      this.f.insuranceCompany.setValue(success.data[0].selectedInsurance);
      //   this.insSelection(success.data[0].selectedInsurance[0])
    }
    console.log("555555");
    console.log(success.data[0].selectedInsurance);
    let tempArry = success.data[0].selectedInsurance
    for (let i = 0; i < tempArry.length; i++) {
      this.selectedInsArry.push(tempArry[i].item_text)
    }
    for (let i = 0; i < tempArry.length; i++) {
      if (i == 0) {
        this.quoteReqObj.insurer += (i + 1) + ". " + tempArry[i].item_text
      } else {
        this.quoteReqObj.insurer += "\n" + (i + 1) + ". " + tempArry[i].item_text + "\r"
      }
    }

    this.f.addon.setValue(success.data[0].selectedAddOn);
    let tempArry2 = success.data[0].selectedAddOn
    for (let i = 0; i < tempArry2.length; i++) {
      this.selectedAddOnsArry.push(tempArry2[i].item_text)
    }
    for (let i = 0; i < tempArry2.length; i++) {
      if (i == 0) {
        this.quoteReqObj.addOns += (i + 1) + ". " + tempArry2[i].item_text
      } else {
        this.quoteReqObj.addOns += "\n" + (i + 1) + ". " + tempArry2[i].item_text + "\r"
      }
    }

    if (detailsData.length) {

      if (detailsData[0].follow_up_datetime != '0000-00-00 00:00:00' && this.currentSelectedStatus == this.sucessStatus)
        this.f.follow_up_datetime.setValue(formatDate((detailsData[0].follow_up_datetime), 'yyyy-MM-ddTHH:mm', 'en'));
      this.f.idv.setValue(detailsData[0].idv);
      this.f.claim_taken.setValue(detailsData[0].claim_taken);
      if (detailsData[0].payment_datetime_promised != '0000-00-00 00:00:00')
        this.f.payment_date_promised.setValue(formatDate((detailsData[0].payment_datetime_promised), 'yyyy-MM-ddTHH:mm', 'en'));
      if (this.currentSelectedStatus != 9 && this.currentSelectedStatus != 8) {
        this.f.payment_link.setValue(detailsData[0].payment_link);
      }
      if (detailsData[0].policy_expiry_date != '0000-00-00 00:00:00')
        this.f.policy_expiry_date.setValue(formatDate((detailsData[0].policy_expiry_date), 'yyyy-MM-dd', 'en'));

      this.f.policy_number.setValue(detailsData[0].policy_number);
      this.f.insurer_name.setValue(detailsData[0].insurer_name);
      // this.f.cashback_required.setValue(detailsData[0].cashback_required);
      if (success.data[0].leadDetails[0].cashback_required == "yes") {
        this.cashbackEvent(true);
        console.log(success.data[0].leadDetails[0].cashback_required)
      }
      else {
        this.cashbackEvent(false);
        console.log(success.data[0].leadDetails[0].cashback_required)
      }
      this.f.cashback_amount.setValue(detailsData[0].cashback_amount);
      this.f.remarks.setValue(detailsData[0].remarks);
      this.latestPolicyCopy = detailsData[0].policy_copy_file_upload;
      this.f.whatsapp.setValue(detailsData[0].whatsapp);

      this.f.claim_taken.setValue(detailsData[0].claim_taken);
      this.claimTakenEvent(detailsData[0].claim_taken == "yes" ? true : false);

      this.f.current_policy.setValue(detailsData[0].current_policy);
      this.quoteReqObj.currentPolicy = detailsData[0].current_policy
      console.log(detailsData[0].current_policy);
      console.log("ffffffffggggggggg");


      this.currentPolicyEvent(detailsData[0].current_policy == "yes" ? true : false)

      this.f.competitive_quote.setValue(detailsData[0].competitive_quote);
      this.competativeEvent(detailsData[0].competitive_quote == "yes" ? true : false)

      this.f.new_car_taken.setValue(detailsData[0].new_car_taken);
      // this.newCarEvent(detailsData[0].new_car_taken == "yes" ? true : false)
      if (success.data[0].leadDetails[0].new_car_taken == "yes") {
        this.newCarEvent(true);
        console.log(success.data[0].leadDetails[0].new_car_taken)
      }
      else {
        this.newCarEvent(false);
        console.log(success.data[0].leadDetails[0].new_car_taken)
      }

      this.f.policy_recieved.setValue(detailsData[0].policy_received);
      this.policyRecievedEvent(detailsData[0].policy_received == "yes" ? true : false)


    }
  }

  backBtn() {
    this.router.navigate(['connect/Disposition'], { queryParams: { despositionType: this.despositionType, leadID: this.leadId, type: this.type } })
  }

  closePlanBtn(id) {
    // this.insPolicyArry = null
    if (this.enteredInsPolArry.length > 0) {
      for (let i = 0; i < this.insPolicyArry.length; i++) {
        if (this.insPolicyArry[i].insptId == id) {
          this.insPolicyArry.splice(i, 1)
        }
      }
    }

    for (let i = 0; i < this.enteredInsPolArry.length; i++) {
      if (this.enteredInsPolArry[i].insptId == id) {
        this.enteredInsPolArry.splice(i, 1)
      }
    }
  }

  // NOTE POPUP

  openNote() {
    // alert("note")
    this.addNote.show();
  }

  clearimg() {
    alert("note")
  }

  // ADD WAPP


  showTxtBox() {
    this.addNum.show()

    // }
  }

  toggleNotes() {
    this.isAddNote = !this.isAddNote
    if (!this.isAddNote) {
      this.backendCall.getNotes(this.leadId).subscribe(Response => {
        this.isLoading = false;
        if (Response) {
          console.log(Response);
          let rnotes = []
          rnotes = Response.data
          console.log(rnotes.length);
          let ndata = []
          for (let i = (rnotes.length - 1); i > -1; i--) {


            ndata.push(rnotes[i]);

          }
          // this.notesData = Response.data;
          console.log(ndata);

          this.notesData = ndata
        }
      },
        error => {
          alert(error.error.error_msg);
        });
    }
  }
  // leadData(leadData: any) {
  //   throw new Error('Method not implemented.');
  // }

  notePadHide() {
    this.addNote.hide();
    this.isAddNote = true;
  }

  submitNote() {
    // alert("dd" + this.noteData)
    this.backendCall.addNotes(this.leadId, this.noteData).subscribe(Response => {
      this.isLoading = false;
      this.addNote.hide();
      if (Response) {
        console.log(Response);
        this.noteData = "";
      }
    },
      error => {
        alert(error.error.error_msg);
      });
  }
  // noteData(leadData: (leadData: any) => void, noteData: any) {
  //   throw new Error('Method not implemented.');
  // }

  ncbSelection(item) {
    this.quoteReqObj.ncb = item.item_text
  }

  deSelectNcb(item) {
    this.quoteReqObj.ncb = ""
  }

  checkbox() {

    this.ischecked = !this.ischecked
  }
  checkboxinsurer() {
    this.f.current_insurer.clearValidators()
    this.ischeckedinsurer = !this.ischeckedinsurer
  }

  submitWhatsappNum(length) {
    this.backendCall.addWhatsappNum(this.leadId, this.whatsappNum)
      .subscribe(success => {
        this.closebutton.nativeElement.click();
      },
        error => {
          this.message = 'Something went wrong while Updating entry';
        });
  }

  linkViewBtn(imgArry) {
    this.linkButton.show()
    this.imageArry = imgArry
  }


  openCalender() {
    const calender = document.getElementById("openInAppointment");
    const appointmentBtn = document.getElementById("appointment");
    appointmentBtn.style.display = "none"
    calender.style.display = "block"
    let LastAppointment = document.getElementById("LastAppointment");
    LastAppointment.hidden = false;
    // alert("helo")
  }

  selectedDate(date: any) {
    const calender = document.getElementById("openInAppointment");
    const appointmentBtn = document.getElementById("appointment");
    // STORING INPUT VALUE
    const selectedValue = date.target.value
    this.f.follow_up_datetime.setValue(selectedValue);
    appointmentBtn.style.display = "block"
    calender.style.display = "none"
    // TESTING
    // alert( "selected date is --> " + selectedValue)
  }


}
