import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [

  {
    id: 'navigation',
    title: 'Analytics',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'SALES OVERVIEW',
        type: 'item',
        url: '/dashboard/analytics',
        icon: 'feather icon-home'
      },
      {
        id: 'dashboard',
        title: 'TICKET OVERVIEW',
        type: 'item',
        url: '/ticket/ticket-overview',
        icon: 'feather icon-search'
      }
    ]
  },
  {
    id: 'Leads',
    title: 'Leads Management SYSTEM',
    type: 'group',
    icon: 'feather icon-layers',
    children: [
      {
        id: 'basic',
        title: 'ASSIGN MANAGEMENT',
        type: 'collapse',
        icon: 'feather icon-box',
        children: [
          {
            id: 'lead',
            title: 'BULK UPLOAD',
            type: 'item',
            url: '/lead/upload'
          },
          {
            id: 'lead',
            title: 'UPDATED LEADS',
            type: 'item',
            url: '/lead/list'
          },         
          {
            id: 'lead',
            title: 'PULL DATA',
            type: 'item',
            url: '/pull-data'
          },         
          {
            id: 'lead',
            title: 'MANUAL LEADS ENTRY',
            type: 'item',
            url: '/lead/create'
          }
        ]
      }
    ]
  },
  {
    id: 'User',
    title: 'DATA MANAGEMENT SYSTEM',
    type: 'group',
    icon: 'feather icon-users',
    children: [
      {
        id: 'basic',
        title: 'DATA MANAGEMENT',
        type: 'collapse',
        icon: 'feather icon-user',
        children: [
          {
            id: 'user',
            title: 'UPLOAD RECORD',
            type: 'item',
            url: '/record-management'
          },
          {
            id: 'user',
            title: 'RECORD LIST',
            type: 'item',
            url: '/record-list'
          },
          {
            id: 'user',
            title: 'DATA OVERVIEW',
            type: 'item',
            url: '/data-overview'
          },
        ]
      }
    ]
  },
  {
    id: 'User',
    title: 'SALES MANAGEMENT SYSTEM',
    type: 'group',
    icon: 'feather icon-users',
    children: [
      {
        id: 'basic',
        title: 'SALES OVERSIGHT',
        type: 'collapse',
        icon: 'feather icon-user',
        children: [
          {
            id: 'user',
            title: 'SALE UPLOAD',
            type: 'item',
            url: '/sale-upload'
          },
          {
            id: 'user',
            title: 'SALE ENTRY',
            type: 'item',
            url: '/sale-entry'
          },
        ]
      }
    ]
  },
  {
    id: 'Leads',
    title: 'recon Management SYSTEM',
    type: 'group',
    icon: 'feather icon-layers',
    children: [
      {
        id: 'basic',
        title: 'RECON MANAGEMENT',
        type: 'collapse',
        icon: 'feather icon-box',
        children: [
          {
            id: 'lead',
            title: 'DATA RECON',
            type: 'item',
            url: '/data-recon'
          },
          {
            id: 'lead',
            title: 'SALE RECON',
            type: 'item',
            url: '/sale-recon'
          },
          {
            id: 'lead',
            title: 'RAW RECON',
            type: 'item',
            url: '/raw-recon'
          }
        ]
      }
    ]
  },
  {
    id: 'User',
    title: 'User Management System',
    type: 'group',
    icon: 'feather icon-users',
    children: [
      {
        id: 'basic',
        title: 'USER MANAGEMENT',
        type: 'collapse',
        icon: 'feather icon-user',
        children: [
          {
            id: 'user',
            title: 'ACTIVE USER',
            type: 'item',
            url: '/user/list'
          },
          {
            id: 'user',
            title: 'CREATE USER',
            type: 'item',
            url: '/user/create'
          }
        ]
      }
    ]
  },
  {
    id: 'Tracking',
    title: 'TRACKING MANAGEMENT SYSTEM',
    type: 'group',
    icon: 'feather icon-users',
    children: [
      {
        id: 'basic',
        title: 'TRACKING ANALYST',
        type: 'collapse',
        icon: 'feather icon-user',
        children: [
          {
            id: 'tracking',
            title: 'AGENT TRACKING',
            type: 'item',
            url: '/tracking/agent/hourly'
          }
        ]
      }
    ]
  },
  {
    id: 'User',
    title: 'Search Engine',
    type: 'group',
    icon: 'feather icon-users',
    children: [
      {
        id: 'basic',
        title: 'SEARCH',
        type: 'collapse',
        icon: 'feather icon-search',
        children: [
          {
            id: 'user',
            title: 'QUICK SEARCH',
            type: 'item',
            url: '/quick-search'
          },
        ]
      }
    ]
  },

];

const NavigationItemsForAgents = [
  {
    id: 'navigation',
    title: 'Analytics',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'BUSINESS OVERVIEW',
        type: 'item',
        url: '/dashboard/analytics',
        icon: 'feather icon-home'
      },
      {
        id: 'dashboard',
        title: 'TICKET OVERVIEW',
        type: 'item',
        url: '/ticket/ticket-overview',
        icon: 'feather icon-search'
      }
    ]
  },
  {
    id: 'Leads',
    title: 'Insurance  Record',
    type: 'group',
    icon: 'feather icon-layers',
    children: [
      {
        id: 'basic',
        title: 'DATA ASSIGNED',
        type: 'collapse',
        icon: 'feather icon-box',
        children: [
          {
            id: 'UPDATED SALES',
            title: 'Leads Assigned',
            type: 'item',
            url: '/lead/list'
          },
          {
            id: 'lead',
            title: 'Manual Leads Entry',
            type: 'item',
            url: '/lead/create'
          }
        ]
      }
    ]
  },
  {
    id: 'User',
    title: 'Offline Quote',
    type: 'group',
    icon: 'feather icon-file-text',
    children: [
      {
        id: 'user',
        title: 'GET QUOTE',
        type: 'item',
        url: '/offline/offline-quote',
        target: '_blank',
        icon: 'feather icon-file-text',
      },
    ]
  },
  {
    id: 'Tracking',
    title: 'track agents',
    type: 'group',
    icon: 'feather icon-users',
    children: [
      {
        id: 'basic',
        title: 'TRACKING',
        type: 'collapse',
        icon: 'feather icon-user',
        children: [
          {
            id: 'tracking',
            title: 'Self Tracking',
            type: 'item',
            url: '/tracking/agent/hourly'
          }
        ]
      }
    ]
  },
  {
    id: 'User',
    title: 'Search Engine',
    type: 'group',
    icon: 'feather icon-users',
    children: [
      {
        id: 'basic',
        title: 'SEARCH',
        type: 'collapse',
        icon: 'feather icon-search',
        children: [
          {
            id: 'user',
            title: 'Quick Search',
            type: 'item',
            url: '/quick-search'
          },
        ]
      }
    ]
  }
];

@Injectable()
export class NavigationItem {
  public get() {
    //console.log(localStorage.getItem('role'))
    if (localStorage.getItem('role') === '1') {
      return NavigationItems;
    }
    else if (localStorage.getItem('role') === '2' || localStorage.getItem('role') === '3') {
      return NavigationItemsForAgents;
    }
  }
}
