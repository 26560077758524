import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { type } from 'os';
import { BackendCallService } from '../../services/backend-call.service';

@Component({
  selector: 'app-connect-tab',
  templateUrl: './connect-tab.component.html',
  styleUrls: ['./connect-tab.component.scss']
})
export class ConnectTabComponent implements OnInit {
  dispoCode: any;
  despositionType: any;
  statusAll=[];
  leadID = "";
  type;

  @ViewChild('exampleModalCenter', { static: true }) myModal;

  public constructor(private router: Router,private backendCall: BackendCallService, private route: ActivatedRoute) {

  }

  public ngOnInit() {
    this.despositionType = this.route.snapshot.queryParamMap.get('despositionType')
    console.log("typeComm", this.route.snapshot.queryParamMap.get('type')    )
    this.type = this.route.snapshot.queryParamMap.get('type');
    this.leadID = this.route.snapshot.queryParamMap.get('leadID');
    console.log(this.leadID);

    this.backendCall.getLeadAllDespositions(
      {
        id: this.leadID
      }
    )
      .subscribe(success => {
        if (success) {
          console.log(success.data);
          let data = success.data;
          for (let index = 0; index < data.length; index++) {
            this.statusAll.push(data[index].status_id)
          }
          console.log(this.statusAll);
        }
      },
        error => {
          //alert(error.error.error_msg);
        
        });

  }

  backButton() {
    console.log("hello");
    this.router.navigate(['pushTicket/ticket-management'], { queryParams: { leadData: this.leadID,type:this.type} })
  }

  showForm(dispositionStatusType, dispoCode) {
    this.dispoCode = dispoCode;
    this.router.navigate(['connect/quoteRequired'], { queryParams: { dispoCode: dispoCode, dispositionStatusType: dispositionStatusType, dispositionType: this.despositionType, leadData: this.leadID,type:this.type  } });
  }

}
