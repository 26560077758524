import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { data } from 'jquery';
import { Subscription } from 'rxjs';
import { NumberofdaysservicesService } from 'src/app/tracknumberofdays/numberofdaysservices.service';
import { BackendCallService } from 'src/app/services/backend-call.service';



@Component({
  selector: 'app-date-tracking',
  templateUrl: './date-tracking.component.html',
  styleUrls: ['./date-tracking.component.scss']
})
export class DateTrackingComponent implements OnInit {
  stage: any;
  state: any;
  activatedRoute: ActivatedRoute;
  position: any;
  insurerTab: boolean = false;
  dateTab: boolean = true;
  // NumberofDays : any
  currentMonthTotal: any;

  monthName: any;
  sub: Subscription
  failedAlert: boolean;
  getdaysInCurrentmonthArray: any;
  message: string;
  day: any;
  getInsurerdaysInCurrentmonthArray: any;
  // backendCall: any;

  currentRole: any;
  loggedInAgentName: any;
  myArray: any;

  constructor(private router: Router, private route: ActivatedRoute, private NumberofdaysservicesService: NumberofdaysservicesService, private backendCall: BackendCallService) {
    this.currentRole = localStorage.getItem('role');
    this.loggedInAgentName = localStorage.getItem('name');
    this.myArray = this.loggedInAgentName.split(' ')
  }

  ngOnInit() {

    this.daysInCurrentmonth();
    // this.NumberofdaysservicesService.currentMessage.subscribe(message => this.monthName = message)
    //   this.NumberofdaysservicesService.getData().subscribe( data =>{
    //     this.monthName=data;
    //      console.log(this.monthName);
    //  })


    // this.NumberofdaysservicesService.getData().subscribe
    //   (data => {
    //     // console.log(data);
    //     // let getmonthData = data
    //     // let tempadd = [];

    //     // getmonthData.forEach(ele => {
    //     //   tempadd.push({ item_id: Number(ele.item_id), item_text: ele.item_text })
    //     // });
    //     // this.monthName = tempadd
    //     this.monthName = data
    //     console.log(this.monthName);

    //   },
    //     error => {
    //       this.failedAlert = true;
    //       console.warn('failed to get data', error);
    //     })
    // this.ngAfterContentInit();
    // console.log(this.NumberofDays);
    // this.NumberofdaysservicesService.getData()
    //  .subscribe(result => {
    this.route.queryParams.subscribe((params: any) => {
      console.log(params);
      this.stage = params.stage
      this.position = params.location
      console.log("stage :", this.stage)
      console.log("position :", this.position)
    })
  }

  backBtnDate() {
    this.router.navigate(['tracking/agent/hourly'])
    //    console.log(result);
    //    let getmonthData = result
    //         let tempadd = [];

    //         getmonthData.forEach(ele => {
    //           tempadd.push({ item_id: Number(ele.item_id), item_text: ele.item_text })
    //         });
    //         this.monthName = tempadd
    //         console.log(this.monthName);

    //  let monthday = result.item_id
    //  this.monthName = result.item_text
    // console.log(monthday,this.monthName);

    // const options = { month: "long" };
    // function daysInMonth (month, year) { // Use 1 for January, 2 for February, etc.
    //   return new Date(year, month, 0).getDate();
    // }
    // console.log(daysInMonth(monthday,new Date().getFullYear()))
    //   let days  = (daysInMonth(monthday,new Date().getFullYear()))
    //  console.log(days);

    //  result.item_id= days
    //  console.log(result);


    //  },
    //   error => {
    //     console.warn('failed to get data', error);
    //   })

  }

  // ngAfterContentInit() {
  //   this.sub = this.NumberofdaysservicesService.getData().subscribe
  //     (data => {
  //       // console.log(data);
  //       // let getmonthData = data
  //       // let tempadd = [];

  //       // getmonthData.forEach(ele => {
  //       //   tempadd.push({ item_id: Number(ele.item_id), item_text: ele.item_text })
  //       // });
  //       // this.monthName = tempadd
  //       this.monthName = data
  //       console.log(this.monthName);

  //     },
  //       error => {
  //         this.failedAlert = true;
  //         console.warn('failed to get data', error);
  //       })
  //   //     data => {
  //   //       console.log(data)
  //   //       this.NumberofDays = data
  //   //     }
  //   //   )
  // }
  // ngOnDestroy() {
  //   this.sub.unsubscribe();
  // }
  // ngAfterContentInit() {
  //   this.sub = this.NumberofdaysservicesService.send_data.subscribe(
  //     data => {
  //       console.log(data)
  //       this.NumberofDays = data
  //     }
  //   )
  // }

  daysInCurrentmonth() {
    //   this.NumberofdaysservicesService.getData().subscribe( data =>{
    //     this.monthName=data;
    //      console.log(this.monthName);
    //  })
    let role = this.currentRole
    console.log(role);
    let name = []
    name = this.myArray
    console.log(name[0]);
    let firstName = name[0]
    let lastName = name[1]
    console.log(name[1]);


    this.backendCall.getdaysInCurrentmonth(role, firstName, lastName)
      .subscribe(success => {

        if (success) {

          if (success.data.length) {
            let getdaysInCurrentmonthArrayData = success.data[0].getdaysInCurrentmonthcount;
            this.currentMonthTotal = success.data[0].getCurrentCount[0].count;
            console.log(this.currentMonthTotal);

            let tempadd = [];

            getdaysInCurrentmonthArrayData.forEach(ele => {
              tempadd.push({ item_Count: Number(ele.Count), item_Year: Number(ele.Year), item_Month: ele.Month, item_Days: Number(ele.Days) })
            });
            this.getdaysInCurrentmonthArray = tempadd
            console.log(this.getdaysInCurrentmonthArray);


          }
        }
      },
        error => {
          //alert(error.error.error_msg);
          this.failedAlert = true;
          this.message = 'Something went wrong while Fetching entry for update';
        });


  }

  showDateToIns(item) {
    this.insurerTab = true;
    this.dateTab = false;
    this.day = item.item_Days
    let day = this.day

    let role = this.currentRole
    console.log(role);
    let name = []
    name = this.myArray
    console.log(name[0]);
    let firstName = name[0]
    let lastName = name[1]
    console.log(name[1]);

    this.backendCall.getInsurerdaysInCurrentmonth(day, role, firstName, lastName)
      .subscribe(success => {

        if (success) {

          if (success.data.length) {
            let getInsurerdaysInCurrentmonthArrayData = success.data[0].getInsurerdaysInCurrentmonthcount;
            let tempadd = [];

            getInsurerdaysInCurrentmonthArrayData.forEach(ele => {
              tempadd.push({ item_Count: Number(ele.count), item_insurer: ele.insurer })
            });
            this.getInsurerdaysInCurrentmonthArray = tempadd
            console.log(this.getInsurerdaysInCurrentmonthArray);


          }
        }
      },
        error => {
          //alert(error.error.error_msg);
          this.failedAlert = true;
          this.message = 'Something went wrong while Fetching entry for update';
        });

  }

  backBtnTwo() {
    this.insurerTab = false;
    this.dateTab = true;
  }

  closeNav() {
    document.getElementById("mySidebar").style.display = "none"
  }

  openNav() {
    document.getElementById("mySidebar").style.display = "block"
  }

  showCurrentMonthInsurertoDisposition(item) {
    console.log(item);
    let role = this.currentRole
    let insurerdisposition = item.item_insurer
    let day = this.day
    let insurer = 'monthInsurer'
    let stage = 'tracking'
    console.log(insurerdisposition, day);
    // this.router.navigate([`/lead/list?insurer=${insurerdisposition}&day=${day}&type=${insurer}`])
    this.router.navigate([`/lead/list`], { queryParams: { insurer: insurerdisposition, day: day, type: insurer, stage: stage } })

  }
}


