import { Component, OnInit, ViewChild } from '@angular/core';
import { BackendCallService } from '../services/backend-call.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { stringify } from 'querystring';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-quick-search ,ngbd-accordion-basic',
	templateUrl: './quick-search.component.html',
	styleUrls: ['./quick-search.component.scss']
})
export class QuickSearchComponent implements OnInit {

	@ViewChild('itemForm', { static: false }) form!: NgForm;
	constructor(private backendCall: BackendCallService, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder) {
	}


	createLeadForm: FormGroup;
	clientDetails = [
		{ name: "Phone", value: "mobile" },
		{ name: "Vehicle Number", value: "reg_number" },
		{ name: "Policy Number", value: "policy_number" },
		{ name: "Name", value: "policy_holder_name" },
		{ name: "Email ID", value: "email" },
	]
	productDropDown = []
	submitted: boolean;
	clientDetail = "";
	searchQuery = "";
	productName = "";
	currentData: any;
	isSearched = false;
	OpenAll: boolean = true;
	head: boolean = false;
	head1: boolean = false;
	head2: boolean = false;
	head3: boolean = false;
	head4: boolean = false;
	show: HTMLElement;
	show1: HTMLElement;
	show2: HTMLElement;
	show3: HTMLElement;
	show4: HTMLElement;
	dropdownList = false;


	get f() { return this.createLeadForm.controls; }

	ngOnInit() {
		// this.fetchProductsDropdown()
		this.createLeadForm = this.formBuilder.group({
			searchInput: ['', Validators.required],
		});
	}

	formValidation(formElement) {
		let _element = this.createLeadForm.controls[formElement];
		if (_element.touched && _element.errors) {
			return 'form-control is-invalid';

		} else if (_element.touched && _element.valid) {
			return 'form-control is-valid';

		} else if (_element.untouched && _element.invalid && this.submitted) {
			return 'form-control is-invalid';

		} else {
			return 'form-control';
		}
	}

	showList(idName) {
		// if (document.getElementById(idName).style.display === "none") {
		// 	document.getElementById(idName).style.display = "block";
		// } else if(document.getElementById(idName).style.display === "block") {
		// 	document.getElementById(idName).style.display = "none";
		// }else{
		// 	document.getElementById(idName).style.display = "block";
		// }
		console.log('quickSearch-----------------------------');
		this.dropdownList = !this.dropdownList ;
		console.log(this.dropdownList, '<<<<<<<<<<<<<<<<<<<<<<<');
		
	}

	showd(anything: string, idName, textId, value) {
		console.log('quickSearch-----------------------------');
		console.log(idName);
		document.getElementById(textId).innerHTML = anything;
		document.getElementById(idName).style.display = "none";
		if (textId == "selectedTransaction") {
			this.clientDetail = value
		} else {
			this.productName = anything
		}
	}

	public rows: Array<any> = [
	];

	public submitQuickSearch(form: NgForm) {
		if(this.clientDetail && form.value.searchInput){
		console.log(this.clientDetail);
		console.log(form.value);
		this.backendCall.fetchQuickSearch(this.clientDetail, form.value.searchInput).subscribe(Response => {
			if (Response) {
				console.log(Response);
				this.rows = Response.data
				if (Response == null){
					alert("$$$$$$$$$$$$$$$$$$$$$$$$")
				}
				if (this.rows.length == 0) {
					this.isSearched = false
					Swal.fire(
						'Oops!',
						'No Such Data Available...',
						'question'
					)
				}
			}
		},
			error => {
				alert(error.error.error_msg);
			});
		}
		else{
			Swal.fire(
				'Alert!',
				'<b>Please Fill All Data in Column ...</b>',
				'error'
		)}
	}

	public fetchQuickSearch(): any {

	}

	public onCellClick(data: any): any {
		console.log("I Am Working", data)
		if (data.column != 'action') {
			//this.mydiv.show();
			this.currentData = [data];
			console.log(this.currentData);
		}
	}

	openDropdown() {
		this.OpenAll = !this.OpenAll
		this.show = document.getElementById("head")
		this.show1 = document.getElementById("head1")
		this.show2 = document.getElementById("head2")
		this.show3 = document.getElementById("head3")
		this.show4 = document.getElementById("head4")
		console.log(this.head1,"####head1")
		console.log(this.head,"####head")
		console.log(this.head2,"####head2")
		console.log(this.head3,"####head3")
		console.log(this.head4,"####head4")


		if(this.head){
			this.show.click();
			this.show.click();
			this.head = !this.head;

		}else{
			this.show.click();
			this.head = !this.head;

		}

		if(this.head1){
			this.show1.click();
			this.show1.click();
			this.head1 = !this.head1;



		}else{
			this.show1.click();
			this.head1 = !this.head1;

		}

		if(this.head2){
			this.show2.click();
			this.show2.click();
			this.head2 = !this.head2;



		}else{
			this.show2.click();
			this.head2 = !this.head2;

		}

		if(this.head3){
			this.show3.click();
			this.show3.click();
			this.head3 = !this.head3;



		}else{
			this.show3.click();
			this.head3 = !this.head3;

		}

		if(this.head4){
			this.show4.click();
			this.show4.click();
			this.head4 = !this.head4;



		}else{
			this.show4.click();
			this.head4 = !this.head4;

		}

	}

	borderradius(type){
		console.log("insdie borderadius ################################################")
		console.log(this.head1,"####head1")
		console.log(this.head,"####head")
		console.log(this.head2,"####head2")
		console.log(this.head3,"####head3")
		console.log(this.head4,"####head4")
		console.log("insdie borderadius ################################################")

		if(type == "head"){
			this.head = !this.head;
		}
		else if (type == "head1"){
			this.head1 = !this.head1;

		}
		else if (type == "head4"){
			this.head4 = !this.head4;

		}
		else if (type == "head2"){
			this.head2 = !this.head2;

		}
		else if (type == "head3"){
			this.head3 = !this.head3;

		}

		console.log("en ding borderadius ################################################")


		console.log(this.head1,"####head1")
		console.log(this.head,"####head")
		console.log(this.head2,"####head2")
		console.log(this.head3,"####head3")
		console.log(this.head4,"####head4")
		console.log("en ding borderadius ################################################")

	}

	// public fetchProductsDropdown(): any {
	// 	this.backendCall.FetchProductDrops().subscribe(Response => {
	// 		if (Response) {
	// 			console.log(Response.data[0]["productname"]);
	// 			this.productDropDown = Response.data[0]["productname"]
	// 		}
	// 	},
	// 		error => {
	// 			alert(error.error.error_msg);
	// 		});
	// }

}

export class NgbdAccordionBasic {
}