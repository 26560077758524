import { YearTrackingComponent } from './track-year/year-tracking/year-tracking.component';
import { MonthTrackingComponent } from './track-month/month-tracking/month-tracking.component';
import { DateTrackingComponent } from './track-date/date-tracking/date-tracking.component';
import { TrackDispositonComponent } from './track-dispositon/track-dispositon.component';
import { InsurerTrackingComponent } from './track-insurer/insurer-tracking/insurer-tracking.component';
import { Component } from '@angular/core';

import { QuickSearchComponent } from './quick-search/quick-search.component';
import { TicketManagementComponent } from './pushTicket/ticket-management/ticket-management.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { AuthGuard } from './guards/auth-guard.service';
import { TicketOverviewComponent } from './ticket/ticket-overview/ticket-overview.component';
import { ConnectTabComponent } from './dispositon/connect-tab/connect-tab.component';
import { NotConnectComponent } from './dispo/not-connect/not-connect.component';
import { QuoteRequiredComponent } from './dispositionForm/quote-required/quote-required.component';
import { OfflineQuoteComponent } from './offline/offline-quote/offline-quote.component';
import { RecordManagementComponent } from './records/record-management/record-management.component';
import { RecordListComponent } from './records/record-list/record-list.component';
import { DataOverviewComponent } from './records/data-overview/data-overview.component';
import { DataReconComponent } from './recon/data-recon/data-recon.component';
import { SaleReconComponent } from './recon/sale-recon/sale-recon.component';
import { RawReconComponent } from './recon/raw-recon/raw-recon.component';
import { UserProfileManagementComponent } from './user-profile/user-profile-management/user-profile-management.component';
import { SaleManagementComponent } from './sales-management/sale-management/sale-management.component';
import { SaleUploadComponent } from './sales-management/sale-upload/sale-upload.component';
import { SaleEntryComponent } from './sales-management/sale-entry/sale-entry.component';
import { PullDataComponent } from './pull-management/pull-data/pull-data.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'auth/signin',
        pathMatch: 'full'
      },
      {
        path: 'auth',
        loadChildren: () => import('./demo/pages/authentication/authentication.module').then(module => module.AuthenticationModule)
      }
    ]
  },
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./demo/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'lead',
        canActivate: [AuthGuard],
        loadChildren: () => import('./leads/leads.module').then(module => module.LeadsModule)
      },
      {
        path: 'user',
        canActivate: [AuthGuard],
        loadChildren: () => import('./user/user.module').then(module => module.UserModule)
      },
      {
        path: 'tracking',
        canActivate: [AuthGuard],
        loadChildren: () => import('./leads/leads.module').then(module => module.LeadsModule)
      },
      {
        path: 'ticket/ticket-overview',
        component: TicketOverviewComponent
      },
      {
        path: 'pushTicket/ticket-management',
        component: TicketManagementComponent
      },
      {
        path: 'quick-search',
        component: QuickSearchComponent
      },
      {
        path: 'connect/Disposition',
        component: ConnectTabComponent
      },
      {
        path: 'notConnect/Disposition',
        component: NotConnectComponent
      },
      {
        path: 'connect/quoteRequired',
        component: QuoteRequiredComponent
      },
      {
        path: 'track/date',
        component: DateTrackingComponent
      },
      {
        path: 'track/month',
        component: MonthTrackingComponent
      },
      {
        path: 'track/disposition',
        component: TrackDispositonComponent
      },
      {
        path: 'track/year',
        component: YearTrackingComponent
      },
      {
        path: 'track/insurer',
        component: InsurerTrackingComponent
      },
      {
        path: 'offline/offline-quote',
        component: OfflineQuoteComponent
      },
      {
        path: 'record-management',
        component: RecordManagementComponent
      },
      {
        path: 'record-list',
        component: RecordListComponent
      },
      {
        path: 'data-overview',
        component: DataOverviewComponent
      },
      {
        path: 'data-recon',
        component: DataReconComponent
      },
      {
        path: 'sale-recon',
        component: SaleReconComponent
      },
      {
        path: 'raw-recon',
        component: RawReconComponent
      },
      {
        path: 'user-profile',
        component: UserProfileManagementComponent
      },
      {
        path: 'sale-management',
        component: SaleManagementComponent
      },
      {
        path: 'sale-upload',
        component: SaleUploadComponent
      },
      {
        path: 'sale-entry',
        component: SaleEntryComponent
      },      
      {
        path: 'sale-entry',
        component: SaleEntryComponent
      },      
      {
        path: 'pull-data',
        component: PullDataComponent
      },      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
