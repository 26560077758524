import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable, throwError, from } from 'rxjs';
import { catchError, map, mapTo, tap, timeout } from 'rxjs/operators';
import { BASE_URL } from '../env';
import { Options } from 'selenium-webdriver';

@Injectable({
	providedIn: 'root'
})
export class BackendCallService {
	constructor(private http: HttpClient) { }

	uploadFile(data): Observable<any> {
		//console.log("rouete", BASE_URL)
		return this.http.post<any>(`${BASE_URL}/leads/upload`, data)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	masluploadFile(data): Observable<any> {
		//console.log("rouete", BASE_URL)
		return this.http.post<any>(`${BASE_URL}/leads/masupload`, data)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getmasterLeadhistory(): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getmasterLeadhistory`, {})
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getmasterLeadCount(): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getmasterLeadCount`, {})
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getduplicateLeadInfo(regno, year): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getduplicateLeadInfo?regno=${regno}&year=${year}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	masldeleteFile(data): Observable<any> {
		//console.log("rouete", BASE_URL)
		return this.http.post<any>(`${BASE_URL}/leads/masldeleteFile`, data)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	updateduplicatemasterleads(data): Observable<any> {
		//console.log("rouete", BASE_URL)
		return this.http.post<any>(`${BASE_URL}/leads/updateduplicatemasterleads`, data)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getmasterleadData(uphisid, type, fromdate, todate, page): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getmasterleadData?uphisid=${uphisid}&type=${type}&fromdate=${fromdate}&todate=${todate}&page=${page}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getcounttotalduplicateunique(uphisid): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getcounttotalduplicateunique?uphisid=${uphisid}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getLeadList(type: any, filterObject): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/list?type=${type}`, { params: filterObject })
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getActivityHistory(leadID): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getActivityHistory?leadID=${leadID}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getDespoDropdowns(type): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getalldisposition?type=${type}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getFilteredList(filterObj): Observable<any> {
		console.log(filterObj)
		return this.http.post<any>(`${BASE_URL}/leads/filter`, { params: filterObj })
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getLeadCount(fromDate, todate): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/count?firstDate=${fromDate}&lastDate=${todate}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getLeadListData(leadId): Observable<any> {
		return this.http.post<any>(`${BASE_URL}/leads/getLeadData`, { leadID: leadId })
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getQuote(leadId, statusType): Observable<any> {
		return this.http.post<any>(`${BASE_URL}/leads/getqouteDetails`, { leadID: leadId, statusType: statusType })
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	// getQuote(id): Observable<any> {
	// 	return this.http.post<any>(`${BASE_URL}/leads/`, { leadID: id, })
	// 		.pipe(
	// 			map(res => {
	// 				return res;
	// 			}),
	// 			catchError(error => {
	// 				return throwError(error);
	// 			}));
	// }

	getListBYFilter(formValue, usersList, insCompList, despoItems): Observable<any> {
		return this.http.post<any>(`${BASE_URL}/leads/getFilteredList`, { formValue: formValue, usersList: usersList, insCompList: insCompList, despoItems: despoItems })
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getPaymentLinks(leadId, statusType1, statusType2): Observable<any> {
		return this.http.post<any>(`${BASE_URL}/leads/getPaymentLinks`, { leadID: leadId, statusType1: statusType1, statusType2: statusType2 })
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getEndrosmentLeadCount(): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/endrosmentCount`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getLead(UserData): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/details`, { params: { id: UserData.id } })
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getLeadDetails(UserData): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/details/status`, { params: { id: UserData.id, status: UserData.status } })
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getLeadAllDespositions(UserData): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/all/despositions`, { params: { id: UserData.id } })
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getDropDown(): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getDropDowns`, {})
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getInsPolAddDropDowns(): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getInsPolAddDropDowns`, {})
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getmonth(): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getmonth`, {})
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getEachMonthdays(Month): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getEachMonthdays?Month=${Month}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getEachMonthdaysinsurence(Month, Day): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getEachMonthdaysinsurence?Month=${Month}&Day=${Day}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getYearInsurancecompany(role, firstName, lastName): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getYearInsurancecompany?Role=${role}&Name1=${firstName}&Name2=${lastName}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getYearMonthInsurancecompany(insurer, role, firstName, lastName): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getYearMonthInsurancecompany?Insurer=${insurer}&Role=${role}&Name1=${firstName}&Name2=${lastName}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getYearMonthDaysInsurancecompany(month, insurer, role, firstName, lastName): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getYearMonthDaysInsurancecompany?Month=${month}&Insurer=${insurer}&Role=${role}&Name1=${firstName}&Name2=${lastName}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getdaysInCurrentmonth(role, firstName, lastName): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getdaysInCurrentmonth?Role=${role}&Name1=${firstName}&Name2=${lastName}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getInsurerdaysInCurrentmonth(day, role, firstName, lastName): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getInsurerdaysInCurrentmonth?day=${day}&Role=${role}&Name1=${firstName}&Name2=${lastName}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getHourlyData(filter): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/tracking/report`, { params: { userId: filter.userId, from_date: filter.from_date } })
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getUserList(): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/user/list`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	sendOtp({ name, email }): Observable<any> {
		return this.http.get<any>(
			`${BASE_URL}/user/send/otp`, {
			params: {
				name,
				email
			}
		}
		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	createUser(UserData): Observable<any> {
		return this.http.post<any>(
			`${BASE_URL}/user/create`, UserData

		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	createLead(UserData): Observable<any> {
		return this.http.post<any>(
			`${BASE_URL}/leads/create`, { LeadEntry: UserData }

		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	updateLead(UserData): Observable<any> {
		return this.http.put<any>(
			`${BASE_URL}/leads/update`, { data: UserData.updateLeadData, id: UserData.id }

		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	leadAssign(mNumber, id): Observable<any> {
		return this.http.post<any>(
			`${BASE_URL}/leads/leadAssign`, { mNum: mNumber, Id: id }

		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error)

				}));
	}

	updateLeadStatus(UserData): Observable<any> {
		return this.http.put<any>(
			`${BASE_URL}/leads/update/status`, { data: UserData.updateData, leadData: UserData.updateLeadData, id: UserData.id, policyTypeArray: UserData.policyTypeArray }

		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	removeFile(id): Observable<any> {
		return this.http.get<any>(
			`${BASE_URL}/leads/remove/file?id=${id}`

		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	fetchInsPol(id, statusId): Observable<any> {
		return this.http.get<any>(
			`${BASE_URL}/leads/getInsPolicy?leadId=${id}&statusId=${statusId}`

		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	addInsPol(insPolicyArry, status, leadsHistory): Observable<any> {
		return this.http.post<any>(
			`${BASE_URL}/leads/addInsPolicy`, { insPolicyArry: insPolicyArry, status: status, historyId: leadsHistory }

		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	openTicket(UserData): Observable<any> {
		return this.http.put<any>(
			`${BASE_URL}/leads/open/ticket`, { id: UserData.id }

		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	dupliMobile(UserData): Observable<any> {
		return this.http.get<any>(
			`${BASE_URL}/user/mob/exist`, {
			params: {
				mobile: UserData.mobile
			}
		}
		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getNotes(leadid): Observable<any> {
		return this.http.get<any>(
			`${BASE_URL}/leads/fetchNotes`, {
			params: {
				leadID: leadid
			}
		}
		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getfetchquotereq(id): Observable<any> {
		return this.http.get<any>(
			`${BASE_URL}/leads/fetchquotereq`, {
			params: {
				leadID: id,
			}
		}
		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	// getidv(id ): Observable<any> {
	// 	return this.http.get<any>(
	// 		`${BASE_URL}/leads/fetchidv?leadID=${id}`
	// 	)
	// 		.pipe(
	// 			map(res => {
	// 				return res;
	// 			}),
	// 			catchError(error => {
	// 				return throwError(error);
	// 			}));
	// }

	// getidv(id , statusType): Observable<any> {
	// 	return this.http.get<any>(`${BASE_URL}/leads/fetchidv`, { leadID: id, statusType:statusType })
	// 		.pipe(
	// 			map(res => {
	// 				return res;
	// 			}),
	// 			catchError(error => {
	// 				return throwError(error);
	// 			}));
	// }

	addNotes(leadid, noteData): Observable<any> {
		return this.http.post<any>(
			`${BASE_URL}/leads/addNotes`, { leadID: leadid, noteText: noteData }

		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	// addDate(leadid, selectedValue): Observable<any> {
	// 	return this.http.post<any>(
	// 		`${BASE_URL}/leads/addDate`, { leadID: leadid, DateTime: selectedValue }

	// 	)
	// 		.pipe(
	// 			map(res => {
	// 				return res;
	// 			}),
	// 			catchError(error => {
	// 				return throwError(error);
	// 			}));
	// }

	FetchMotorDropdowns(): Observable<any> {
		return this.http.get<any>(
			`${BASE_URL}/manualEntry/getMotorDropdowns`

		)
			.pipe(
				map(res => {
					// console.log(res)
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	FetchNonMotorDropdowns(): Observable<any> {
		return this.http.get<any>(
			`${BASE_URL}/manualEntry/getNonMotorDropdowns`

		)
			.pipe(
				map(res => {
					// console.log(res)
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	FetchProductDrops(): Observable<any> {
		return this.http.get<any>(
			`${BASE_URL}/manualEntry/getProductType`

		)
			.pipe(
				map(res => {
					// console.log(res)
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	fetchQuickSearch(clientDetail, searchQuery): Observable<any> {
		// console.log("Fetchinggg.........................................................")
		return this.http.post<any>(
			`${BASE_URL}/leads/quickSearch`, { clientDetails: clientDetail, searchQuery: searchQuery }

		)
			.pipe(
				map(res => {
					// console.log(res)
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getWhatsappNum(id): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/fetchWhatsappNum?leadID=${id}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	fetchCarNames(): Observable<any> {
		return this.http.get<any>(
			`https://api.bookmyinsurance.com/vehicle/car`
		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	fetchBikeNames(): Observable<any> {
		return this.http.get<any>(
			`https://api.bookmyinsurance.com/vehicle/bike`
		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	fetchBrandNames(brandID): Observable<any> {
		return this.http.get<any>(
			`https://api.bookmyinsurance.com/vehicle/brand/${brandID}/`
		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	submiteMotorEntryData(formEntryData, productName, selectedDpt): Observable<any> {
		return this.http.post<any>(
			`${BASE_URL}/manualEntry/submitMotorEntry`, { formEntryData: formEntryData, productName: productName, selectedDpt: selectedDpt }

		)
			.pipe(
				map(res => {
					// console.log(res)
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	addWhatsappNum(leadId, whatsappNumber): Observable<any> {
		return this.http.post<any>(
			`${BASE_URL}/leads/addWhatsappNum`, { whatsappNum: whatsappNumber, leadId: leadId }

		)
			.pipe(
				map(res => {
					// console.log(res)
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	pulldatafrommaster(fromDate, toDate): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/pulldatafrommaster?storeFromDate=${fromDate}&storeToDate=${toDate}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	pushintolead(data): Observable<any> {
		//console.log("rouete", BASE_URL)
		return this.http.post<any>(`${BASE_URL}/leads/pushintolead`, data)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getrenewals(regnumber): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getrenewals?regnumber=${regnumber}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	fetchCarDetails(regnumber): Observable<any> {
		return this.http.get<any>(
			`https:///api.bookmyinsurance.com/vehicle/detail/car/${regnumber}/`
		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	fetchBikeDetails(regnumber): Observable<any> {
		return this.http.get<any>(
			`https:///api.bookmyinsurance.com/vehicle/detail/bike/${regnumber}/`
		)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getClaimDetails(data): Observable<any> {
		return this.http.get<any>(`https://owts3d6jf7j3jivku7emtdhdae0bixva.lambda-url.ap-south-1.on.aws/?regno=${data}`)
			.pipe(
				timeout(22000),
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	//&&&&&&&&&&&&&&&&&&&&&&&& local api &&&&&&&&&&&&&&&&&&&&&&&&&&&//

	getnationalquote(getquotationdata): Observable<any> {
		return this.http.post<any>(`http://localhost:3001/getQuote/national`, getquotationdata)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	geticiciquote(getquotationdata): Observable<any> {
		return this.http.post<any>(`http://localhost:3001/getQuote/icici`, getquotationdata)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getroyalquote(getquotationdata): Observable<any> {
		return this.http.post<any>(`http://localhost:3001/getQuote/royal`, getquotationdata)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getrahejaQbequote(getquotationdata): Observable<any> {
		return this.http.get<any>(`http://localhost:3001/getQuote/rahejaQbe`, { params: getquotationdata })
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getsompoquote(getquotationdata): Observable<any> {
		return this.http.post<any>(`http://localhost:3001/getQuote/sompo`, getquotationdata)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getdigitquote(getquotationdata): Observable<any> {
		return this.http.post<any>(`http://localhost:3001/getQuote/digit`, getquotationdata)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	gettataaigquote(getquotationdata): Observable<any> {
		return this.http.post<any>(`http://localhost:3001/getQuote/tataaig`, getquotationdata)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getbajajquote(getquotationdata): Observable<any> {
		return this.http.post<any>(`http://localhost:3001/getQuote/bajaj`, getquotationdata)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getreliancequote(getquotationdata): Observable<any> {
		return this.http.post<any>(`http://localhost:3001/getQuote/reliance`, getquotationdata)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	gethdfcquote(getquotationdata): Observable<any> {
		return this.http.post<any>(`http://localhost:3001/getQuote/hdfc`, getquotationdata)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getunitedquote(getquotationdata): Observable<any> {
		return this.http.post<any>(`http://localhost:3001/getQuote/unitedindia`, getquotationdata)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getfuturequote(getquotationdata): Observable<any> {
		return this.http.post<any>(`http://localhost:3001/getQuote/futuregenerali`, getquotationdata)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	//&&&&&&&&&&&&&&&&&&&&&&&&&& lambda API &&&&&&&&&&&&&&&&&&&&&&&&&&//
	getnationalquotation(data): Observable<any> {
		return this.http.post<any>(`https://c6gr7bay3jel7x4bz5wjfxzrbu0vykbr.lambda-url.ap-south-1.on.aws/`, data)
			.pipe(
				timeout(300000),
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	geticiciquotation(data): Observable<any> {
		return this.http.post<any>(`https://7i5gosviiiytk52y47i75vaw2a0lqrlf.lambda-url.ap-south-1.on.aws/`, data)
			.pipe(
				timeout(300000),
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getroyalquotation(data): Observable<any> {
		return this.http.post<any>(`https://u5ftjhtpkh6wrb7dhkpfm6zsd40dftbv.lambda-url.ap-south-1.on.aws/`, data)
			.pipe(
				timeout(300000),
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getrahejaQbequotation(data): Observable<any> {
		return this.http.post<any>(`https://e2pkvepsezf6lxbuky6rwxiw7e0mktig.lambda-url.ap-south-1.on.aws/`, data)
			.pipe(
				timeout(300000),
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getsompoquotation(data): Observable<any> {
		return this.http.post<any>(`https://6lnsrh7qgcupydhaf7o277nemq0zzwbx.lambda-url.ap-south-1.on.aws/`, data)
			.pipe(
				timeout(300000),
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getdigitquotation(getquotationdata): Observable<any> {
		return this.http.post<any>(`https://bookmyinsurance.uk/getQuote/digit`, getquotationdata)
			// https://abns667t7vvamjys6lsqwfjlxy0qknpi.lambda-url.ap-south-1.on.aws/
			.pipe(
				timeout(300000),
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	gettataaigquotation(data): Observable<any> {
		return this.http.post<any>(`https://n2m5gb2vwh2fiixwibjtunfmni0ipket.lambda-url.ap-south-1.on.aws/`, data)
			.pipe(
				timeout(300000),
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getbajajquotation(data): Observable<any> {
		return this.http.post<any>(`https://64mwnhnbuvrpxxwyqr3s34o7x40voijw.lambda-url.ap-south-1.on.aws/`, data)
			.pipe(
				timeout(300000),
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getreliancequotation(data): Observable<any> {
		return this.http.post<any>(`https://rsm2hxzxgpfnb3wbsxo3dosdz40sxyyf.lambda-url.ap-south-1.on.aws/`, data)
			.pipe(
				timeout(300000),
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	gethdfcquotation(data): Observable<any> {
		return this.http.post<any>(`https://vzktzqezyfxqiagbzy5ok4j7pi0giipm.lambda-url.ap-south-1.on.aws/`, data)
			.pipe(
				timeout(300000),
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getunitedquotation(data): Observable<any> {
		return this.http.post<any>(`https://react-bootstrap-v4.netlify.app/getting-started/introduction/`, data)
			.pipe(
				timeout(300000),
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getfuturequotation(data): Observable<any> {
		return this.http.post<any>(`https://react-bootstrap-v4.netlify.app/getting-started/introduction/`, data)
			.pipe(
				timeout(300000),
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	//mmv api's

	getmake(): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getmake`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getmodel(make): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getmodel?make=${make}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getvariant(make, model): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/getvariant?make=${make}&model=${model}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}

	getcoreidandmmv(make, model,variant): Observable<any> {
		return this.http.get<any>(`${BASE_URL}/leads/allportalMMV?make=${make}&model=${model}&variant=${variant}`)
			.pipe(
				map(res => {
					return res;
				}),
				catchError(error => {
					return throwError(error);
				}));
	}
}